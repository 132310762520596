import React, { Component } from "react";

// Global
import Snackbar from "../Snackbar";
import EditorCurrentRegisterContainer from "../EditorCurrentRegisterContainer";
import ButtonEditorCurrentRegister from "../ButtonEditorCurrentRegister";

// Styles
import { materialStyle } from "../../styles";
import ThemeTextFildGreen from "../../styles/ThemeTextFildGreen";

// Material
import { TextField, Button, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Cancel as CancelIcon, Save as SaveIcon } from "@material-ui/icons";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as TeamActions } from "../../store/ducks/teams";
import { Actions as LocationActions } from "../../store/ducks/location";

import getOnlyNumbers from "../../services/getOnlyNumbers";

class TeamEdit extends Component {
  state = {
    currentTeam: null,
    errorCEP: false,
    editableField: false,
    emails: []
  };
  // load current team
  componentWillMount() {
    const parameters = JSON.parse(localStorage.getItem("auth")).currentTeam
      .parameters;

    this.setState({
      parameters
    });

    const { showTeamRequest } = this.props;

    showTeamRequest(parameters.idTeam);
  }
  // on receive current team update local state
  componentWillReceiveProps(nextProps) {
    const {
      teams: { currentTeam }
    } = nextProps;
    if (currentTeam !== this.state.currentTeam) {
      this.setState({
        currentTeam: {
          ...currentTeam,
          deliveryTimeoutOnCollect: currentTeam.deliveryTimeoutOnCollect / 60
        }
      });
    }
    if (!nextProps.teams.error) {
      window.scrollTo(0, 0);
    }
    if (nextProps.teams.updateSuccess) {
      this.setState({ editableField: false });
    }
  }
  // save changes
  handleSave = () => {
    const { updateTeamRequest } = this.props;
    const { currentTeam } = this.state;

    Object.assign(currentTeam, {
      deliveryTimeoutOnCollect: currentTeam.deliveryTimeoutOnCollect * 60
    });

    updateTeamRequest(currentTeam);
  };
  // on change current team
  handleChange = key => ({ target: { value } }) => {
    let result = value;

    if (key === "cnpj" || key === "zipCode") {
      result = getOnlyNumbers(value);
    }
    switch (key) {
      case "contactNumber":
        result = value.replace(/[^0-9.]/g, "");
        break;
      default:
        break;
    }
    this.setState({
      currentTeam: {
        ...this.state.currentTeam,
        [key]: result
      }
    });
  };

  renderEdit = () => {
    const { classes, teams, action } = this.props;
    const { currentTeam, editableField } = this.state;
    return (
      true && (
        <ThemeTextFildGreen>
          <EditorCurrentRegisterContainer
            disabled={editableField}
            flexDirection="row-reverse"
          >
            <ButtonEditorCurrentRegister
              label="Editar"
              onClick={() => this.setState({ editableField: true })}
            />
          </EditorCurrentRegisterContainer>

          <h5>Parâmetros de notifição</h5>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3} className="form-colaboradore-sm">
              <TextField
                value={currentTeam ? currentTeam.deliveryTimeoutOnCollect : ""}
                variant="outlined"
                className={classes.textField}
                disabled={
                  teams.loading || (!editableField && action === "edit")
                }
                label="Tempo limite para retirar produtos"
                margin="normal"
                onChange={this.handleChange("deliveryTimeoutOnCollect")}
                type="number"
                inputProps={{ min: "0" }}
                helperText="Tempo em horas"
              />
            </Grid>
            <Grid item xs={12} md={3} className="form-colaboradore-sm">
              <TextField
                value={
                  currentTeam
                    ? currentTeam.emailsOnDeliveryTimeoutOnCollect
                    : ""
                }
                variant="outlined"
                className={classes.textField}
                disabled={
                  teams.loading || (!editableField && action === "edit")
                }
                label="Email no gestor"
                margin="normal"
                onChange={this.handleChange("emailsOnDeliveryTimeoutOnCollect")}
                type="email"
                helperText="Notifica quando ultrapassa tempo de retirada"
              />
            </Grid>
            <Grid item xs={12} md={3} className="form-colaboradore-sm">
              <TextField
                value={currentTeam ? currentTeam.useCondominium : ""}
                variant="outlined"
                className={classes.textField}
                disabled={
                  teams.loading || (!editableField && action === "edit")
                }
                label="Email no gestor"
                margin="normal"
                onChange={this.handleChange("useCondominium")}
                type="email"
                helperText="Notifica quando ultrapassa tempo de retirada"
              />
            </Grid>
          </Grid>

          {editableField && (
            <>
              <Button
                variant="contained"
                onClick={this.handleSave}
                disabled={teams.loading}
                className={classes.button}
              >
                <SaveIcon className={classes.leftIcon} />
                {action === "edit" ? "Salvar" : "Criar"}
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  this.setState({ editableField: false });
                  window.scrollTo(0, 0);
                }}
                disabled={teams.loading}
                className={classes.button}
              >
                <CancelIcon className={classes.leftIcon} />
                Cancelar
              </Button>
            </>
          )}
        </ThemeTextFildGreen>
      )
    );
  };

  render() {
    const { teams, setSnackbarVisibleTeams } = this.props;

    return (
      <>
        {this.renderEdit()}
        <Snackbar
          message="Atualizado"
          variant="success"
          onClose={() => setSnackbarVisibleTeams(false)}
          open={teams.snackbarVisible}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  teams: state.teams,
  location: state.location
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...TeamActions,
      ...LocationActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(materialStyle)(TeamEdit));
