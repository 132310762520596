import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Launch from "@material-ui/icons/Launch";
import Business from "@material-ui/icons/Business";
import Grow from "@material-ui/core/Grow";
import Container from "../../components/Container";
import { Divider } from "@material-ui/core";
import history from "../../routes/history";
import api from "../../services/api";
import Spinner from "../../components/Spinner";
import constants from "../../config/constants";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
    // maxWidth: 752
  },
  demo: {
    backgroundColor: theme.palette.background.paper
  },
  title: {
    margin: theme.spacing(4, 0, 2)
  },
  container: {
    display: "flex"
  },
  paper: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    boxShadow: "3px 3px 15px 1px rgba(0,0,0,0.2)",
    height: "100%"
  },
  svg: {
    width: 100,
    height: 100
  },
  polygon: {
    fill: theme.palette.common.white,
    stroke: theme.palette.divider,
    strokeWidth: 1
  }
}));

function CustomListItem({ label, Labelicon, onClick, secondary }) {
  return (
    <>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <Labelicon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={label || "Single-line item"}
          secondary={secondary}
        />
        <ListItemSecondaryAction>
          <IconButton edge="end" aria-label="delete" onClick={onClick}>
            <Launch />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Divider />
    </>
  );
}

function Integrations() {
  const classes = useStyles();
  const [dense, setDense] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [data, setData] = useState([]);
  const [integrations, setIntegrations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [oldIntegration, setOldIntegration] = useState(false);
  const parameters = JSON.parse(localStorage.getItem("auth")).currentTeam
    .parameters;

  function getIntegrationName() {
    let name = "";
    try {
      for (let i = 0; i < constants.oldIntegrationsKeys.length; i++) {
        if (parseInt(parameters[constants.oldIntegrationsKeys[i]]) === 1) {
          name = constants.oldIntegrations.find(
            item => item.key === constants.oldIntegrationsKeys[i]
          ).name;
          break;
        }
      }

      return name;
    } catch (err) {
      return name;
    }
  }

  const fetch = useCallback(async () => {
    try {
      if (
        parseInt(parameters.usePartnerNepos) === 1 ||
        parseInt(parameters.usePartnerIModulo) === 1 ||
        parseInt(parameters.usePartnerMyCond) === 1 ||
        parseInt(parameters.usePartnerSeuCondominio) === 1 ||
        parseInt(parameters.usePartnerWinker) === 1 ||
        parseInt(parameters.usePartnerScond) === 1 ||
        parseInt(parameters.usePartnerOnePortaria) === 1 ||
        parseInt(parameters.usePartnerECondos) === 1
      ) {
        setOldIntegration(true);
      }

      const integrationsData = await api.get("/v1/adm/token/team/integration");

      setIntegrations(integrationsData.data);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetch();
  }, []);

  const handleChange = link => {
    history.push(link);
  };

  return (
    <Container title="tutoriais">
      <Grow in={!checked}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" className={classes.title}>
              {integrations.length || oldIntegration
                ? "Integração ativa"
                : "Módulos de integração para SmartLocker"}
            </Typography>

            {loading ? (
              <Spinner />
            ) : (
              <>
                {oldIntegration ? (
                  <p>Integração ativada {getIntegrationName()}</p>
                ) : integrations.length ? (
                  <List dense={dense}>
                    {integrations.map(item => (
                      <CustomListItem
                        label={"Integração ativa"}
                        Labelicon={Business}
                        onClick={() =>
                          handleChange(`/integrations/token/${item.id}`)
                        }
                        secondary={"Modelo customizado"}
                      />
                    ))}
                  </List>
                ) : (
                  <List dense={dense}>
                    <>
                      <CustomListItem
                        label={"Criar nova integração"}
                        Labelicon={Business}
                        onClick={() => handleChange("/integrations/create")}
                        secondary={"Modelo customizado"}
                      />
                      {/* {data.map(item => (
                        <CustomListItem
                          label={item.name}
                          Labelicon={Business}
                          onClick={() =>
                            handleChange(`/integrations/create/${item.id}`)
                          }
                          secondary={item.name}
                        />
                      ))} */}
                    </>
                  </List>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Grow>
    </Container>
  );
}

export default Integrations;
