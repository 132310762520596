import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import green from "@material-ui/core/colors/green";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import CloseIcon from "@material-ui/icons/Close";
import {
  Snackbar,
  InputAdornment,
  IconButton,
  TextField
} from "@material-ui/core";
import queryString from "query-string";
import ReCAPTCHA from "react-google-recaptcha";
// Assets
import Logo from "../../assets/Lockup_Handover.svg";
import ForgotPassword from "../../components/ForgotPassword";
// Styles
import {
  Container,
  Form,
  LogoStyle,
  TextInfoLogin,
  WrapperHandleLogin,
  LinkForgot,
  ButtonLogin,
  ModalRecover,
  Title
} from "./styled";
import { Actions as LoginActions } from "../../store/ducks/login";
import colors from "../../styles/colors";
import validateEmail from "../../utils/validateEmail";
const styles = theme => ({
  root: {
    color: green[600],
    "&$checked": {
      color: green[500]
    }
  },
  checked: {},
  input: {
    margin: theme.spacing(1),
    fontFamily: "Livvic, sans-serif",
    "& .MuiFormLabel-root": {
      fontFamily: "Livvic, sans-serif"
    }
  }
});

class Login extends Component {
  state = {
    email: "",
    password: "",
    loading: false,
    error: null,
    isShow: false,
    saveEmail: false,
    checkedG: false,
    showPassword: false,
    showModal: false,
    resetedPass: false,
    ReCAPTCHAIsValid: false,
    isEmailValid: true
  };

  componentWillMount() {
    const query = queryString.parse(this.props.location.search);

    if (query.action && query.action === "reseted_pass") {
      this.setState({ resetedPass: true });
    }
  }

  handleChange = e => {
    const { name, value } = e.target;
    const _data = {
      [name]: value
    };

    if (name === "email") {
      Object.assign(_data, {
        isEmailValid: validateEmail(value)
      });
    }
    this.setState(_data);
  };

  submitForm = async event => {
    event.preventDefault();

    const { loginRequest } = this.props;
    const { email, password, isEmailValid } = this.state;

    if (
      email === "" ||
      password === "" ||
      !this.state.ReCAPTCHAIsValid ||
      !isEmailValid
    ) {
      this.setState({ validation: true });
      return;
    }

    loginRequest(email, password);
  };

  handleChangeSaveEmail = name => event => {
    this.props.saveEmail();
    this.setState({ [name]: event.target.checked });
  };

  handleClickShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword
    });
  };

  handleMouseDownPassword = event => {
    event.preventDefault();
  };

  showModalforgotPassword = () => {
    this.setState({ showModal: true });
  };

  hideModalforgotPassword = () => {
    this.setState({ showModal: false });
  };

  render() {
    const {
      showPassword,
      email,
      password,
      resetedPass,
      isEmailValid
    } = this.state;
    const { classes, login } = this.props;

    return (
      <Container className="wrapper-login">
        <Snackbar
          open={resetedPass}
          autoHideDuration={3000}
          onClose={() => this.setState({ resetedPass: false })}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          message="Senha alterada com sucesso!"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              className={classes.close}
              onClick={() => this.setState({ resetedPass: false })}
            >
              <CloseIcon />
            </IconButton>
          }
        />
        <div class="row container-login">
          <div class="container-login-form">
            <LogoStyle src={Logo} alt="" height="35px" />
            <Form class="form-signin" onSubmit={this.submitForm}>
              <Title>Acesso para Empresas</Title>
              <TextField
                autocomplete="off"
                className={classes.input}
                variant="outlined"
                id="login-input"
                label="E-mail"
                value={email}
                name="email"
                type="email"
                onChange={this.handleChange}
                disabled={login.loading}
                fullWidth
                margin="dense"
                error={!isEmailValid} // Apply error styling if email is invalid
                helperText={!isEmailValid ? "email inválido" : ""}
                InputProps={{
                  style: {
                    fontFamily: "Livvic, sans-serif" // Apply font family to the input
                  }
                }}
              />
              <TextField
                autocomplete="off"
                disabled={login.loading}
                variant="outlined"
                color="primary"
                label="Senha"
                fullWidth
                margin="dense"
                id="outlined-adornment-password"
                className={classes.input}
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={this.handleChange}
                name="password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowPassword}
                        onMouseDown={this.handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              {login.error && (
                <div
                  style={{
                    width: "100%"
                  }}
                >
                  <TextInfoLogin color={colors.error}>
                    email ou senha inválidos
                  </TextInfoLogin>
                </div>
              )}
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA}
                onChange={() => this.setState({ ReCAPTCHAIsValid: true })}
                onExpired={() => this.setState({ ReCAPTCHAIsValid: false })}
                onErrored={() => this.setState({ ReCAPTCHAIsValid: false })}
                hl="pt-BR"
              />

              <WrapperHandleLogin>
                <ButtonLogin
                  disabled={
                    login.loading ||
                    !email ||
                    !password ||
                    !this.state.ReCAPTCHAIsValid ||
                    !this.state.isEmailValid
                  }
                  class="btn btn-lg btn-primary btn-block"
                  type="submit"
                >
                  Entrar
                </ButtonLogin>
                <LinkForgot
                  type="button"
                  onClick={this.showModalforgotPassword}
                  disabled={login.loading}
                >
                  Esqueceu a senha?
                </LinkForgot>
              </WrapperHandleLogin>
            </Form>
          </div>
        </div>

        <ModalRecover>
          <ForgotPassword
            showModal={this.state.showModal}
            hideModalforgotPassword={this.hideModalforgotPassword}
          />
        </ModalRecover>
      </Container>
    );
  }
}

const mapStateToPros = state => ({
  login: state.login
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...LoginActions }, dispatch);

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withStyles(styles)(Login));
