import React, { Component } from 'react';

// Global
import Container from '../../../components/Container';

class Permissions extends Component {
  render() {
    return (
      <Container title="Permissões"></Container>
    );
  }
}

export default Permissions;
