import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useSelector, useDispatch } from "react-redux";
import { Actions } from "../../../../store/ducks/subTeamSubUnits";
import LinearProgess from "../../../../components/LinearProgess";
import { MenuItem } from "@material-ui/core";
import { Actions as SubTeamUnitsActions } from "../../../../store/ducks/subTeamUnits";
import { Actions as SubTeamSubUnitsActions } from "../../../../store/ducks/subTeamSubUnits";
import { constants } from "../../../../config";

export default function FormDialog({ classes, subTeamUnitsQuery }) {
  const [idSubTeamUnits, setIdSubTeamUnits] = useState();
  const [name, setName] = useState();
  const subTeamUnits = useSelector(state => state.subTeamUnits);
  const subTeamSubUnits = useSelector(state => state.subTeamSubUnits);
  const subTeams = useSelector(state => state.subTeams);
  const dispatch = useDispatch();
  const [dashboardParams, setDashboardParams] = useState(null);

  useEffect(() => {
    dispatch(SubTeamUnitsActions.getSubTeamUnitsRequest(subTeamUnitsQuery));
  }, []);

  useEffect(() => {
    const storedParameters = JSON.parse(localStorage.getItem("auth"))
      .currentTeam.parameters;

    const data = { parameters: storedParameters };

    if (
      storedParameters.dashboardParams &&
      storedParameters.dashboardParams.length
    ) {
      Object.assign(data, {
        dashboardParams: JSON.parse(storedParameters.dashboardParams)
      });
    }

    setDashboardParams(data.dashboardParams);
  }, []);

  return (
    <div>
      <Dialog
        open={subTeamSubUnits.setDialogCreateVisibleSubTeamSubUnits}
        onClose={() =>
          dispatch(
            SubTeamSubUnitsActions.setDialogCreateVisibleSubTeamSubUnits(false)
          )
        }
        aria-labelledby="form-dialog-title"
      >
        {subTeamSubUnits.loading && <LinearProgess />}
        <DialogTitle id="form-dialog-title">
          Criar um{" "}
          {dashboardParams &&
          Object.keys(dashboardParams).includes("subTeamSubUnitsLabel")
            ? dashboardParams.subTeamSubUnitsLabel
            : constants.deliveryTableHeaders.subTeamUnitsCondominium}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Informe abaixo os dados do{" "}
            {dashboardParams &&
            Object.keys(dashboardParams).includes("subTeamSubUnitsLabel")
              ? dashboardParams.subTeamSubUnitsLabel
              : constants.deliveryTableHeaders.subTeamUnitsCondominium}
          </DialogContentText>

          <TextField
            id="custom-css-outlined-input"
            select
            label={
              dashboardParams &&
              Object.keys(dashboardParams).includes("subTeamUnitsLabel")
                ? dashboardParams.subTeamUnitsLabel
                : constants.deliveryTableHeaders.subTeamUnitsCondominium
            }
            className={classes.selectType}
            value={idSubTeamUnits}
            onChange={e => setIdSubTeamUnits(e.target.value)}
            SelectProps={{
              MenuProps: {
                className: classes.menu
              }
            }}
            margin="normal"
            variant="outlined"
          >
            {subTeamUnits.data.map(item => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            autoFocus
            margin="dense"
            id="block_name"
            label={`Nome ou identificador do ${
              dashboardParams &&
              Object.keys(dashboardParams).includes("subTeamSubUnitsLabel")
                ? dashboardParams.subTeamSubUnitsLabel
                : constants.deliveryTableHeaders.subTeamUnitsCondominium
            }`}
            fullWidth
            onChange={e => setName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() =>
              dispatch(Actions.setDialogCreateVisibleSubTeamSubUnits(false))
            }
            color="danger"
            disabled={subTeamSubUnits.loading}
          >
            Cancelar
          </Button>
          <Button
            onClick={() => {
              if (!name || !idSubTeamUnits) return;

              dispatch(
                Actions.createSubTeamSubUnitsRequest(
                  [
                    {
                      name,
                      idSubTeam: subTeams.currentSubTeam.id,
                      idTeam: JSON.parse(localStorage.getItem("auth"))
                        .currentTeam.id,
                      idSubTeamUnits
                    }
                  ],
                  true
                )
              );
            }}
            color="primary"
            disabled={subTeamSubUnits.loading}
          >
            Criar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
