import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Select from "react-select";
import { withStyles } from "@material-ui/core/styles";

import { connect } from "react-redux";
import { materialStyle } from "../../../../styles";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
    [theme.breakpoints.down("xs")]: {
      overflowX: "visible"
    }
  },
  table: {
    minWidth: 650
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: 2
  },
  noLabel: {
    marginTop: theme.spacing(3)
  }
}));

function SimpleTable({ roles, onChange, isDisabled, currentBusiness }) {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Table className={classes.table} id="team-table-businessedit-sm">
        <TableHead className="tableheader">
          <TableRow>
            <TableCell
              className="tablecell-header tablecell-header__initial-table"
              align="left"
            >
              empresa  
            </TableCell>
            <TableCell className="tablecell-header" align="center">
              regras
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentBusiness.teamJoins.map(row => (
            <TableRow key={row.id}>
              <TableCell className="tablecell tablecell-header__initial-table">
                {row.team.name}
              </TableCell>
              <TableCell className="tablecell" style={{ maxWidth: 100 }}>
                <Select
                  isMulti
                  options={roles.data}
                  value={row.roles || []}
                  getOptionLabel={item => item.name}
                  getOptionValue={item => item.id}
                  onChange={item => onChange(row.id, item)}
                  isDisabled={isDisabled}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}

const mapStateToProps = state => ({
  currentBusiness: state.business.currentBusiness,
  loading: state.business.loading,
  error: state.business.error,
  roles: state.roles
});

export default connect(mapStateToProps)(withStyles(materialStyle)(SimpleTable));
