import React, { Component } from "react";
import queryString from "query-string";

// Material
import { withStyles } from "@material-ui/core/styles";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as LockerActions } from "../../../../store/ducks/locker";
import { Actions as CompartmentActions } from "../../../../store/ducks/compartment";
import { Actions as CustomerLogActions } from "../../../../store/ducks/customerLogs";

// Global
import Container from "../../../../components/Container";
import CompartmentsTable from "../../../../components/CompartmentsTable";
import CustomerLogsTable from "../../../../components/CustomerLogsTable";
import Spinner from "../../../../components/Spinner";

// components
import Tabs from "./components/Tab";
import LockerInfo from "./components/LockerInfo";
import CameraView from "../../../../components/CameraView";
import AlarmTable from "../../../../components/AlarmTable";
import ThemeTextFildGreen from "../../../../styles/ThemeTextFildGreen";
import Snackbar from "../../../../components/Snackbar";

import VideocamIcon from "@material-ui/icons/Videocam";
import FavoriteIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import InfoIcon from "@material-ui/icons/Info";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import TimelineIcon from "@material-ui/icons/Timeline";
import AdmIcon from "@material-ui/icons/Notes";
import CustomDialog from "../../../../components/CustomDialog";

const styles = theme => ({
  button: {
    margin: theme.spacing(1)
  },
  textField: {
    margin: theme.spacing(1)
  },
  selectType: {
    margin: theme.spacing(1),
    minWidth: 150
  }
});

class LockerShow extends Component {
  state = {
    currentLocker: null,
    errorCEP: false,
    isChanged: true,
    value: 0,
    loading: false,
    edit: true,
    open: false,
    parameters: null,
    dashboardParams: null
  };

  async componentWillMount() {
    const {
      showLockerRequest,
      getCompartmentsRequest,
      customerLogsRequest
    } = this.props;
    const { id } = this.props.match.params;

    showLockerRequest(id);
    getCompartmentsRequest(`idLocker=${id}&perPage=1000`);

    const parameters = JSON.parse(localStorage.getItem("auth")).currentTeam
      .parameters;

    let dashboardParams = null;

    if (parameters.dashboardParams && parameters.dashboardParams.length) {
      dashboardParams = JSON.parse(parameters.dashboardParams);
    }

    this.setState({
      parameters,
      dashboardParams
    });

    if (Boolean(parameters.useCondominium)) {
      customerLogsRequest(
        queryString.stringify({
          idLocker: id,
          noLocker: true
        })
      );
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.lockers.currentLocker !== this.state.currentLocker) {
      this.setState({
        currentLocker: nextProps.lockers.currentLocker
      });
    }
  }

  handleChange = key => event => {
    this.setState({
      ...this.state,
      currentLocker: {
        ...this.state.currentLocker,
        [key]: event.target.value
      }
    });
  };

  renderCompartments = () => {
    const { currentLocker } = this.state;

    return (
      <>
        <CompartmentsTable
          source={{
            data: currentLocker.compartments,
            total: currentLocker.compartments.length,
            rowsPerPage: currentLocker.compartments.length,
            page: 0
          }}
          LockerShow
        />
      </>
    );
  };

  renderAlarm = () => {
    const { open } = this.state;

    return (
      <>
        <AlarmTable id={this.props.match.params.id} />
        <Snackbar
          variant="success"
          open={this.state}
          onClose={() => this.setState({ open: false })}
          message="Salvo com sucesso!"
        />
      </>
    );
  };

  render() {
    const { currentLocker, parameters, dashboardParams } = this.state;
    const { lockers } = this.props;

    return (
      <Container
        title={`Locker ${
          currentLocker
            ? currentLocker.lockerNumber + " - " + currentLocker.address
            : ""
        }`}
      >
        <ThemeTextFildGreen>
          {lockers.loading ? (
            <Spinner />
          ) : (
            <>
              {currentLocker && (
                <Tabs
                  data={[
                    {
                      show: true,
                      component: (
                        <LockerInfo idLocker={this.props.match.params.id} />
                      ),
                      label: "Informações do locker",
                      icon: <InfoIcon />
                    },
                    {
                      show: parameters && Boolean(currentLocker.useCamera),
                      component: <CameraView />,
                      label: "Câmera",
                      icon: <VideocamIcon />
                    },
                    {
                      show: true,
                      component: (
                        <CompartmentsTable
                          source={{
                            data: currentLocker.compartments,
                            total: currentLocker.compartments.length,
                            rowsPerPage: currentLocker.compartments.length,
                            page: 0
                          }}
                          LockerShow
                        />
                      ),
                      label: "Portas",
                      icon: <FavoriteIcon />
                    },

                    {
                      show: parameters && Boolean(currentLocker.useAlarm),
                      component: <AlarmTable id={this.props.match.params.id} />,
                      label: "Alarme",
                      icon: <NotificationsActiveIcon />
                    },
                    {
                      show: parameters && Boolean(parameters.useCondominium),
                      component: (
                        <CustomerLogsTable
                          noLocker={true}
                          history={this.props.history}
                        />
                      ),
                      label: "Logs correios",
                      icon: <TimelineIcon />
                    },
                    {
                      show:
                        dashboardParams &&
                        Object.keys(dashboardParams).includes(
                          "activeLockerFullOpen"
                        ) &&
                        dashboardParams.activeLockerFullOpen,
                      component: (
                        <CustomDialog
                          className={this.props.classes.button}
                          title="Atenção"
                          buttonLabel="Abrir Locker inteiro"
                          cancelButtonText="Cancelar"
                          confirmButtonText="Sim"
                          message={`Deseja realmente abrir todas as portas deste Locker?`}
                          onConfirm={() => {
                            this.props.openLockerAllCompartmentsRequest(
                              currentLocker.id
                            );
                          }}
                          disabled={false}
                        />
                      ),
                      label: "Administrar",
                      icon: <AdmIcon />
                    }
                  ]}
                  idLocker={currentLocker.id}
                  view1={<LockerInfo idLocker={this.props.match.params.id} />}
                  view2={<CameraView />}
                  view3={
                    <CompartmentsTable
                      source={{
                        data: currentLocker.compartments,
                        total: currentLocker.compartments.length,
                        rowsPerPage: currentLocker.compartments.length,
                        page: 0
                      }}
                      LockerShow
                    />
                  }
                  view4={this.renderAlarm()}
                  view5={
                    parameters &&
                    Boolean(parameters.useCondominium) && (
                      <CustomerLogsTable
                        noLocker={true}
                        history={this.props.history}
                      />
                    )
                  }
                />
              )}
            </>
          )}
        </ThemeTextFildGreen>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  lockers: state.locker,
  compartments: state.compartment
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...LockerActions,
      ...CompartmentActions,
      ...CustomerLogActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(LockerShow));
