import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import FormControl from "@material-ui/core/FormControl";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { green } from "@material-ui/core/colors";
import { Build as BuildIcon } from "@material-ui/icons";

import SnackBar from "../Snackbar";
import api from "../../services/api";

import { Actions as BusinessActions } from "../../store/ducks/business";

export default function FormDialog({ classes, disabled }) {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [open, setOpen] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [variant, setVariant] = useState("");
  const { currentBusiness } = useSelector(state => state.business);
  const dispatch = useDispatch();

  const [enable, setEnable] = useState(false);

  const theme = createMuiTheme({
    palette: {
      primary: green
    },
    typography: {
      fontSize: 12
    }
  });

  const clearState = () => {
    setPassword("");
    setConfirmPassword("");
    setEnable(false);
  };

  const handleChange = func => e => {
    func(e.target.value);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    clearState();
  };

  const handleCancel = () => {
    setOpen(false);
    clearState();
  };

  const handleCloseSnack = () => {
    setOpenSnack(false);
  };

  const handleUpdate = async () => {
    setLoading(true);

    try {
      await api.put(
        `/v1/adm/business/employee/password/change/${currentBusiness.id}`,
        {
          password,
          password_confirmation: confirmPassword
        }
      );
      handleClose();
      dispatch(BusinessActions.setSnackbarVisibleBusiness(true));
      setLoading(false);
    } catch (err) {
      setMessage("Erro ao atualiza senha!");
      setVariant("error");
      setOpenSnack(true);
      setLoading(false);
    }
  };

  function changePassword(e) {
    setPassword(e.target.value);
    setEnable(true);
  }

  return (
    <>
      <Button
        className={classes.button}
        disabled={disabled}
        variant="contained"
        onClick={handleOpen}
      >
        <BuildIcon className={classes.leftIcon} />
        Alterar a senha
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Alterar senha</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <TextField
              margin="dense"
              label="Nova senha"
              type="password"
              onChange={e => changePassword(e)}
              fullWidth
              value={password}
            />
            <TextField
              margin="dense"
              label="Confirmar senha"
              type="password"
              onChange={handleChange(setConfirmPassword)}
              fullWidth
              value={confirmPassword}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="secondary" disabled={loading}>
            Cancelar
          </Button>
          <MuiThemeProvider theme={theme}>
            <Button
              onClick={() => handleUpdate()}
              color="primary"
              disabled={loading || password !== confirmPassword || !enable}
            >
              {loading ? "Carregando..." : "Atualizar"}
            </Button>
          </MuiThemeProvider>
        </DialogActions>
        <SnackBar
          message={message}
          open={openSnack}
          onClose={handleCloseSnack}
          variant={variant}
        />
      </Dialog>
    </>
  );
}
