import React, { useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import ReCAPTCHA from "react-google-recaptcha";

import Logo from "../../assets/Lockup_Handover.svg";

import api from "../../services/api";
import constants from "../../config/constants";

import { BackgroundOpacity, Container, HeadModal, Form, Image } from "./styles";

function forgotPassword(props) {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [textButton, setTextButton] = useState("Enviar");
  const [success, setSuccess] = useState(false);
  const title = useState("Redefinir senha");
  const [subTitle, setSubtitle] = useState(
    "Insira seu e-mail corporativo para receber as instruções e gerar uma nova senha."
  );
  const [error, setError] = useState("");
  const [ReCAPTCHAIsValid, setReCAPTCHAIsValid] = useState(false);

  let url = "";

  if (process.env.REACT_APP_STAGING) {
    url = "http://sandbox.dashboard.oihandover.com";
  } else if (process.env.NODE_ENV === "development") {
    url = "http://localhost:3000";
  } else {
    url = "https://empresas.oihandover.com";
  }

  async function sendEmail(e) {
    try {
      e.preventDefault();

      if (!email) return;
      if (!ReCAPTCHAIsValid) {
        setError('Realize a validação: "Não sou um robô"');
        setReCAPTCHAIsValid(false);
        return;
      }

      setReCAPTCHAIsValid(true);
      setError("");
      setSubtitle(
        "Insira seu e-mail corporativo para receber as instruções e gerar uma nova senha."
      );
      setSuccess(false);
      setTextButton("Enviando...");

      if (!email) return;
      setLoading(true);

      await api.post("/v1/business/forgot_password", {
        email,
        redirect_url: `${url}/resetpassword`
      });

      setLoading(false);
      setSubtitle(`Email de recuperação enviado para ${email}`);
      setSuccess(true);
      setTextButton("Fechar");
    } catch (err) {
      setLoading(false);
      setError(`Desculpe, não foi possível enviar email para ${email}`);
      setTextButton("Enviar");
    }
  }

  function close() {
    props.hideModalforgotPassword();
    setLoading(false);
    setEmail("");
    setError("");
    setSuccess(false);
    setTextButton("Enviar");
  }

  return (
    <BackgroundOpacity show={props.showModal}>
      <Container>
        <Image src={Logo} alt="Logo" width="120" height="120" />
        <h3>EMPRESAS</h3>
        <CloseIcon onClick={close} />
        <Form
          action=""
          onSubmit={sendEmail}
          loading={loading}
          ReCAPTCHAIsValid={ReCAPTCHAIsValid}
          email={email}
        >
          <HeadModal>
            <h1>{title}</h1>
            <h2>{subTitle}</h2>
          </HeadModal>
          {!success && (
            <input
              type="text"
              placeholder="Digite o seu email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              disabled={loading}
            />
          )}

          {!success ? (
            <>
              <ReCAPTCHA
                style={{ margin: 10 }}
                sitekey={process.env.REACT_APP_RECAPTCHA}
                onChange={() => setReCAPTCHAIsValid(true)}
                onExpired={() => setReCAPTCHAIsValid(false)}
                onErrored={() => setReCAPTCHAIsValid(false)}
                hl="pt-BR"
              />
              <p>{!!error && error}</p>
              <button
                disabled={loading || !ReCAPTCHAIsValid || !email}
                type={"submit"}
              >
                {textButton}
              </button>
            </>
          ) : (
            <button
              style={{
                marginTop: 30
              }}
              type={"button"}
              onClick={close}
            >
              {textButton}
            </button>
          )}
        </Form>
      </Container>
    </BackgroundOpacity>
  );
}

export default forgotPassword;
