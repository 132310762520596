import React, { Component } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  KeyboardArrowRight as FirstPageIcon,
  LastPage as LastPageIcon,
  KeyboardArrowRight,
  KeyboardArrowLeft
} from "@material-ui/icons";
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TablePagination,
  TableRow,
  Paper,
  IconButton,
  TableHead
} from "@material-ui/core";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as CompartmentActions } from "../../store/ducks/compartment";
import { Actions as CustomerLogAbbreviationActions } from "../../store/ducks/customerLogAbbreviation";

// Components
import SimpleActionButton from "../SimpleActionButton";
import AlertDialog from "../AlertDialog";
import ModalChangeStatus from "./components/ModalChangeStatus";

import history from "../../routes/history";
// import checkHandoverEmail from "../../services/checkHandoverEmail";

import { constants } from "../../config";

const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5)
  }
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  function handleFirstPageButtonClick(event) {
    onChangePage(event, 0);
  }

  function handleBackButtonClick(event) {
    onChangePage(event, page - 1);
  }

  function handleNextButtonClick(event) {
    onChangePage(event, page + 1);
  }

  function handleLastPageButtonClick(event) {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  }

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
};

class CompartmentsTable extends Component {
  state = {
    emptyRows: 0,
    page: 1,
    rowsPerPage: 5,
    modalVisible: false,
    currentCompartment: null,
    showDialog: false,
    idCompartment: null
  };

  static defaultProps = {
    actions: [() => {}],

    options: ["DEFAULT"]
  };

  handleChangePage = (event, newPage) => {
    const { getCompartmentsRequest, currentLocker } = this.props;
    const { rowsPerPage } = this.state;

    getCompartmentsRequest(
      `idLocker=${currentLocker.id}&page=${newPage + 1}&perPage=${rowsPerPage}`
    );
  };

  handleChangeRowsPerPage = event => {
    const rowsPerPage = parseInt(event.target.value);

    this.setState({ rowsPerPage });

    const { getCompartmentsRequest, currentLocker } = this.props;

    getCompartmentsRequest(
      `perPage=${rowsPerPage}&idLocker=${currentLocker.id}`
    );
  };

  useStyles2 = () =>
    makeStyles(theme => ({
      root: {
        width: "100%",
        marginTop: theme.spacing(3)
      },
      table: {
        minWidth: 500,
        fontSize: 50
      },
      tableWrapper: {
        overflowX: "auto"
      }
    }));

  handleOpenAlertDialog = id => {
    this.setState({
      showDialog: !this.state.showDialog,
      idCompartment: id
    });
  };

  handleOpenCompartment = () => {
    const { idCompartment } = this.state;
    const { openCompartmentRequest } = this.props;
    openCompartmentRequest(idCompartment);
  };

  renderButtonItems = id => {
    let actions = [];
    let tmp = ["Abrir porta", "Editar"];
    let options = [];

    const parameters = JSON.parse(localStorage.getItem("auth")).currentTeam
      .parameters;

    // if (checkHandoverEmail()) {
    //   tmp.push("Editar");
    // }

    for (let i = 0; i < tmp.length; i++) {
      switch (i) {
        case 0:
          options[i] = tmp[i];
          actions[i] = () => this.handleOpenAlertDialog(id);
          break;
        case 1:
          // if (!checkHandoverEmail()) break;
          options[i] = tmp[i];
          actions[i] = () => history.push(`/compartments/edit/${id}`);
          break;
        case 2:
          if (Boolean(parameters.useCondominium)) {
            options[i] = tmp[i];
            actions[i] = () => {
              this.props.setModalChangeStatusVisible(true);
              this.props.setCompartment({
                id
              });
              this.props.customerLogAbbreviationRequest();
            };
          }

          break;
        default:
          options[i] = tmp[i];
          actions[i] = () => {};
      }
    }

    return {
      actions,
      options
    };
  };

  render() {
    const classes = this.useStyles2();
    const { compartments } = this.props;

    const { emptyRows } = this.state;

    return (
      <Paper className={classes.root}>
        <ModalChangeStatus />
        <div className={classes.tableWrapper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell
                  className="tablecell-header tablecell-header__initial-table"
                  align="center"
                >
                  <p style={{ color: "#006660", fontSize: "18px", margin: 0 }}>
                    lid
                  </p>
                </TableCell>
                <TableCell
                  className="tablecell-header"
                  id="lockeredit-head-table-sm__spacing"
                  align="center"
                >
                  <p style={{ color: "#006660", fontSize: "18px", margin: 0 }}>
                    porta
                  </p>
                </TableCell>
                <TableCell
                  className="tablecell-header"
                  id="lockeredit-head-table-sm__spacing"
                  align="center"
                >
                  <p style={{ color: "#006660", fontSize: "18px", margin: 0 }}>
                    status
                  </p>
                </TableCell>
                <TableCell
                  className="tablecell-header th-delivery"
                  align="center"
                >
                  <p style={{ color: "#006660", fontSize: "18px", margin: 0 }}>
                    dimensões
                  </p>
                </TableCell>
                <TableCell className="tablecell-header" align="center">
                  <p style={{ color: "#006660", fontSize: "18px", margin: 0 }}>
                    ações
                  </p>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {compartments.data &&
                compartments.data.map(row => (
                  <TableRow key={row.id}>
                    <TableCell
                      className="tablecell tablecell-header__initial-table"
                      align="center"
                    >
                      {row.locker.lockerNumber}
                    </TableCell>
                    <TableCell className="tablecell" align="center">
                      {row.compartmentNumber}
                    </TableCell>
                    <TableCell className="tablecell" align="center">
                      {row.compartmentAvailability.description}
                    </TableCell>
                    <TableCell className="tablecell td-delivery" align="center">
                      {row.compartmentIdentifier
                        ? row.compartmentIdentifier.description
                        : ""}
                    </TableCell>
                    <TableCell
                      id="table-sm__svg"
                      className="tablecell"
                      align="center"
                    >
                      <SimpleActionButton
                        label={this.renderButtonItems(row.id).options[0]}
                        id={Math.random()}
                        actions={this.renderButtonItems(row.id).actions}
                        options={this.renderButtonItems(row.id).options}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 48 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter className="table-footer">
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={constants.optionPerPage}
                  colSpan={3}
                  count={compartments.total}
                  rowsPerPage={compartments.perPage}
                  page={compartments.page - 1}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true
                  }}
                  labelRowsPerPage="Linhas por página"
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to === -1 ? count : to} de ${count}`
                  }
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
        <AlertDialog
          open={this.state.showDialog}
          className={classes.button}
          title="Atenção"
          buttonLabel="Abrir porta"
          cancelButtonText="Cancelar"
          confirmButtonText="Abrir"
          message={`Deseja realmente abrir a porta?`}
          onConfirm={this.handleOpenCompartment}
          disabled={false}
          handleClose={this.handleOpenAlertDialog}
        />
      </Paper>
    );
  }
}
const mapStateToProps = state => ({
  compartments: state.compartment,
  currentLocker: state.locker.currentLocker
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...CompartmentActions,
      ...CustomerLogAbbreviationActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompartmentsTable);
