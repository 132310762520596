import React, { Component } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import TableHead from "@material-ui/core/TableHead";

import { constants } from "../../config";

// functions
import formatDate from "../../functions/formatDate";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as DeliveryActions } from "../../store/ducks/delivery";

const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5)
  }
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  function handleFirstPageButtonClick(event) {
    onChangePage(event, 0);
  }

  function handleBackButtonClick(event) {
    onChangePage(event, page - 1);
  }

  function handleNextButtonClick(event) {
    onChangePage(event, page + 1);
  }

  function handleLastPageButtonClick(event) {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  }

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
};

class DeliveryPaginationTable extends Component {
  state = {
    emptyRows: 0,
    page: 1,
    rowsPerPage: 5
  };

  handleChangePage = (event, newPage) => {
    const { getDeliveriesRequest } = this.props;
    const { rowsPerPage } = this.state;

    getDeliveriesRequest(`page=${newPage + 1}&perPage=${rowsPerPage}`);
  };

  handleChangeRowsPerPage = event => {
    const rowsPerPage = parseInt(event.target.value);

    this.setState({ rowsPerPage });

    const { getDeliveriesRequest } = this.props;

    getDeliveriesRequest(`perPage=${rowsPerPage}`);
  };

  useStyles2 = () =>
    makeStyles(theme => ({
      root: {
        width: "100%",
        marginTop: theme.spacing(3)
      },
      table: {
        minWidth: 500
      },
      tableWrapper: {
        overflowX: "auto"
      }
    }));

  handleDate = date => {
    if (!date) return "?";

    const splited = date.split(" ")[0].split("-");

    return `${splited[2]}/${splited[1]}/${splited[0]}`;
  };

  handleHours = date => {
    if (!date) return "?";

    const splited = date.split(" ")[1].split(":");

    return `${splited[0]}:${splited[1]}`;
  };

  render() {
    const classes = this.useStyles2();
    const { currentDelivery } = this.props;

    const { emptyRows } = this.state;

    return (
      <Paper className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table className={classes.table}>
            <TableHead className="tableheader">
              <TableRow align="center">
                <TableCell className="tablecell-header" align="center">
                  USUÁRIO
                </TableCell>
                <TableCell className="tablecell-header" align="center">
                  DATA
                </TableCell>
                <TableCell className="tablecell-header" align="center">
                  TIPO
                </TableCell>
                <TableCell className="tablecell-header" align="center">
                  motivo
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow>
                <TableCell className="tablecell" align="center">
                  {currentDelivery.business && currentDelivery.business.name}
                </TableCell>
                <TableCell className="tablecell" align="center">
                  {formatDate(currentDelivery.created_at)}
                </TableCell>
                <TableCell className="tablecell" align="center">
                  {constants.deliveryStatus.created.label}
                </TableCell>
                <TableCell className="tablecell" align="center">
                  -
                </TableCell>
              </TableRow>
              {currentDelivery &&
                currentDelivery.updateInfo.map(row => (
                  <TableRow key={row.id}>
                    <TableCell className="tablecell" align="center">
                      {row.business && row.business.name}
                    </TableCell>
                    <TableCell className="tablecell" align="center">
                      {formatDate(row.createdAt)}
                    </TableCell>
                    <TableCell className="tablecell" align="center">
                      {row.type.description}
                    </TableCell>
                    <TableCell className="tablecell" align="center">
                      {row.subType ? row.subType.description : "-"}
                    </TableCell>
                  </TableRow>
                ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 48 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </Paper>
    );
  }
}
const mapStateToProps = state => ({
  currentDelivery: state.delivery.currentDelivery
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...DeliveryActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeliveryPaginationTable);
