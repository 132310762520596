import React, { Component } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import TableHead from "@material-ui/core/TableHead";

import SimpleActionButton from "../SimpleActionButton";
import defaultConfig from "../../config/default";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as RolesActions } from "../../store/ducks/roles";

const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5)
  }
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  function handleFirstPageButtonClick(event) {
    onChangePage(event, 0);
  }

  function handleBackButtonClick(event) {
    onChangePage(event, page - 1);
  }

  function handleNextButtonClick(event) {
    onChangePage(event, page + 1);
  }

  function handleLastPageButtonClick(event) {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  }

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
};

class RolesPaginationTable extends Component {
  state = {
    emptyRows: 0,
    page: 1,
    rowsPerPage: 5
  };

  handleChangePage = (event, newPage) => {
    const { getRolesRequest } = this.props;

    const query = `page=${newPage + 1}&perPage=${this.state.rowsPerPage}`;

    getRolesRequest(query);
  };

  handleChangeRowsPerPage = event => {
    const { getRolesRequest } = this.props;

    getRolesRequest(`perPage=${parseInt(event.target.value)}`);

    this.setState({ rowsPerPage: parseInt(event.target.value) });
  };

  useStyles2 = () =>
    makeStyles(theme => ({
      root: {
        width: "100%",
        marginTop: theme.spacing(3)
      },
      table: {
        minWidth: 500
      },
      tableWrapper: {
        overflowX: "auto"
      }
    }));

  render() {
    const classes = this.useStyles2();
    const { roles, history } = this.props;

    const { emptyRows } = this.state;

    return (
      <Paper className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table className={classes.table} id="teams-sm__table">
            <TableHead className="tableheader">
              <TableRow>
                <TableCell
                  className="tablecell-header tablecell-header__initial-table"
                  align="center"
                >
                  NOME
                </TableCell>
                <TableCell
                  className="tablecell-header th-delivery"
                  align="center"
                >
                  SLUG
                </TableCell>
                <TableCell
                  className="tablecell-header th-delivery"
                  align="center"
                >
                  DESCRIÇÃO
                </TableCell>
                <TableCell className="tablecell-header" align="center">
                  AÇÕES
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {roles.data &&
                roles.data.map(row => (
                  <TableRow key={1}>
                    <TableCell
                      className="tablecell tablecell-header__initial-table"
                      align="center"
                    >
                      {row.name}
                    </TableCell>
                    <TableCell className="tablecell td-delivery" align="center">
                      {row.slug}
                    </TableCell>
                    <TableCell className="tablecell td-delivery" align="center">
                      {row.description}
                    </TableCell>
                    <TableCell
                      className="tablecell"
                      id="table-sm__svg"
                      align="center"
                    >
                      <SimpleActionButton
                        id={Math.random()}
                        actions={[
                          () =>
                            history.push(`/administrator/roles/edit/${row.id}`)
                        ]}
                        options={["Visualizar"]}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 48 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter className="table-footer">
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={defaultConfig.rowsPerPageOptions}
                  colSpan={3}
                  count={roles.total}
                  rowsPerPage={roles.perPage}
                  page={roles.page - 1}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true
                  }}
                  labelRowsPerPage="Linhas por página"
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      </Paper>
    );
  }
}
const mapStateToProps = state => ({
  roles: state.roles
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...RolesActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RolesPaginationTable);
