import { call, put } from "redux-saga/effects";
import api from "../../services/api";
import { Actions as subTeamSubUnitsActions } from "../ducks/subTeamSubUnits";
import { Actions as ConfigsActions } from "../ducks/configs";
import constants from "../../config/constants";
import sortArray from "../../functions/sortArray";

export function* getSubTeamSubUnits(action) {
  try {
    const response = yield call(
      api.get,
      `/v1/adm/subTeamSubUnits?${action.payload.data}`
    );

    const temp = response.data;
    temp.data = sortArray(temp.data);

    yield put(
      subTeamSubUnitsActions.getSubTeamSubUnitsSuccess({
        ...temp,
        data: temp.data
      })
    );
  } catch (err) {
    yield put(subTeamSubUnitsActions.getSubTeamSubUnitsError());
  }
}

export function* createSubTeamSubUnits(action) {
  try {
    const data = action.payload;

    const response = yield call(api.post, `/v1/adm/subTeamSubUnits`, {
      data
    });

    yield put(
      subTeamSubUnitsActions.createSubTeamSubUnitsSuccess(response.data)
    );

    yield put(
      ConfigsActions.setToastVisible({
        toastMessage: `Apartamento${data.length > 1 &&
          "s"} criado com sucesso!`,
        toastVariant: constants.toastVariants.success,
        toastVisible: true
      })
    );

    yield put(
      subTeamSubUnitsActions.setDialogCreateVisibleSubTeamSubUnits(false)
    );

    window.location.reload();
  } catch (err) {
    yield put(subTeamSubUnitsActions.createSubTeamSubUnitsError());
    yield put(
      ConfigsActions.setToastVisible({
        toastMessage: "Erro geral",
        toastVariant: constants.toastVariants.error,
        toastVisible: true
      })
    );
  }
}

export function* showSubTeamSubUnits(action) {
  try {
    const { id } = action.payload;

    const response = yield call(api.get, `/v1/adm/subTeamSubUnits/${id}`);

    yield put(subTeamSubUnitsActions.showSubTeamSubUnitsSuccess(response.data));
  } catch (err) {
    yield put(subTeamSubUnitsActions.showSubTeamSubUnitsError());
  }
}

export function* updateSubTeamSubUnits(action) {
  try {
    const { id, name, idTeam, idSubTeam, idSubTeamUnits } = action.payload;

    const response = yield call(api.put, `/v1/adm/subTeamSubUnits/${id}`, {
      name,
      idTeam,
      idSubTeam,
      idSubTeamUnits
    });

    yield put(
      subTeamSubUnitsActions.updateSubTeamSubUnitsSucess(response.data)
    );
    yield put(
      ConfigsActions.setToastVisible({
        toastMessage: "Atualizado!",
        toastVariant: constants.toastVariants.success,
        toastVisible: true
      })
    );
  } catch (err) {
    yield put(subTeamSubUnitsActions.updateSubTeamSubUnitsError(err));
    yield put(
      ConfigsActions.setToastVisible({
        toastMessage: "Erro geral",
        toastVariant: constants.toastVariants.error,
        toastVisible: true
      })
    );
  }
}

export function* changeStatusSubTeamSubUnitsRequest(action) {
  try {
    const { status, id } = action.payload;

    yield call(api.put, `/v1/adm/subTeamSubUnits/${id}/status`, {
      status
    });

    yield put(
      subTeamSubUnitsActions.changeStatusSubTeamSubUnitsSuccess(status)
    );
    yield put(
      ConfigsActions.setToastVisible({
        toastMessage: "Atualizado!",
        toastVariant: constants.toastVariants.success,
        toastVisible: true
      })
    );
  } catch (err) {
    yield put(subTeamSubUnitsActions.changeStatusSubTeamSubUnitsError(err));
    yield put(
      ConfigsActions.setToastVisible({
        toastMessage: "Erro geral",
        toastVariant: constants.toastVariants.error,
        toastVisible: true
      })
    );
  }
}

export function* getSubTeamSubUnitsByCompartmentRequest(action) {
  try {
    const { idCompartment } = action.payload;

    const result = yield call(
      api.get,
      `/v1/adm/subTeamSubUnitsCompartment/${idCompartment}?perPage=1000`
    );

    yield put(
      subTeamSubUnitsActions.getSubTeamSubUnitsByCompartmentSuccess(
        result.data.data
      )
    );

    // obtem apenas um subteamSubUnits
    const response = yield call(
      api.get,
      `/v1/adm/subTeamSubUnits/to/${idCompartment}`
    );

    const temp = response.data;
    temp.data = sortArray(temp.data);

    yield put(
      subTeamSubUnitsActions.getSubTeamSubUnitsSuccess({
        ...temp,
        data: temp.data
      })
    );
  } catch (err) {
    yield put(
      subTeamSubUnitsActions.getSubTeamSubUnitsByCompartmenttsError(err)
    );
  }
}
