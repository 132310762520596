import React, { Component } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";

import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { DoneAll, Error, HourglassEmpty } from "@material-ui/icons";
import TableHead from "@material-ui/core/TableHead";
import { constants } from "../../config";

class businessPaginationTable extends Component {
  state = {
    emptyRows: 0,
    page: 1,
    rowsPerPage: 5,
    parameters: null,
    dashboardParams: null
  };

  componentWillMount() {
    const parameters = JSON.parse(localStorage.getItem("auth")).currentTeam
      .parameters;

    const data = { parameters };

    if (parameters.dashboardParams && parameters.dashboardParams.length) {
      Object.assign(data, {
        dashboardParams: JSON.parse(parameters.dashboardParams)
      });
    }

    this.setState(data);
  }

  useStyles2 = () =>
    makeStyles(theme => ({
      root: {
        width: "100%",
        marginTop: theme.spacing(3)
      },
      table: {
        minWidth: 500
      },
      tableWrapper: {
        overflowX: "auto"
      }
    }));

  renderIcon = status => {
    let Icon = HourglassEmpty;

    if (status === 1) {
      Icon = DoneAll;
    } else if (status === -1) {
      Icon = Error;
    }

    return <Icon />;
  };

  render() {
    const classes = this.useStyles2();
    const { data } = this.props;

    const { emptyRows, dashboardParams } = this.state;

    return (
      <Paper className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table className={classes.table}>
            <TableHead className="tableheader">
              <TableRow align="center">
                <TableCell
                  className="tablecell-header tablecell-header__initial-table"
                  align="center"
                >
                  {dashboardParams &&
                  Object.keys(dashboardParams).includes("subTeamUnitsLabel")
                    ? dashboardParams.subTeamUnitsLabel
                    : constants.deliveryTableHeaders.subTeamUnitsCondominium}
                </TableCell>
                <TableCell
                  className="tablecell-header td-business-simple-table"
                  align="center"
                >
                  {dashboardParams &&
                  Object.keys(dashboardParams).includes("subTeamSubUnitsLabel")
                    ? dashboardParams.subTeamSubUnitsLabel
                    : constants.deliveryTableHeaders.subTeamSubUnitsCondominium}
                </TableCell>
                <TableCell
                  className="tablecell-header td-business-simple-table"
                  align="center"
                >
                  {dashboardParams &&
                  Object.keys(dashboardParams).includes(
                    "subTeamSubUnitsContactLabel"
                  )
                    ? dashboardParams.subTeamSubUnitsContactLabel
                    : constants.deliveryTableHeaders
                        .subTeamSubUnitsContactCondominium}
                </TableCell>

                <TableCell
                  className="tablecell-header td-business-simple-table"
                  align="center"
                >
                  EMAIL
                </TableCell>

                <TableCell
                  className="tablecell-header tablecell-header__team-status"
                  align="center"
                >
                  Celular (Opcional)
                </TableCell>

                <TableCell
                  className="tablecell-header tablecell-header__team-status"
                  align="center"
                >
                  ID Slack
                </TableCell>

                <TableCell
                  className="tablecell-header tablecell-header__team-status"
                  align="center"
                >
                  STATUS
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="tbody-business-simple-table">
              {data &&
                data.map(row => (
                  <TableRow key={row.id}>
                    <TableCell
                      className="tablecell tablecell-header__initial-table"
                      align="center"
                    >
                      {row.subTeamUnits}
                    </TableCell>
                    <TableCell
                      className="tablecell td-business-simple-table"
                      align="center"
                    >
                      {row.subTeamSubUnits}
                    </TableCell>
                    <TableCell
                      className="tablecell td-business-simple-table"
                      align="center"
                    >
                      {row.subTeamSubUnitsContactName}
                    </TableCell>

                    <TableCell
                      className="tablecell td-business-simple-table"
                      align="center"
                    >
                      {row.subTeamSubUnitsContactEmail}
                    </TableCell>

                    <TableCell className="tablecell" align="center">
                      {row.subTeamSubUnitsContactPhone}
                    </TableCell>

                    <TableCell className="tablecell" align="center">
                      {row.subTeamSubUnitsContactSlackChannelID}
                    </TableCell>

                    <TableCell className="tablecell" align="center">
                      {this.renderIcon(row.status)}
                    </TableCell>
                  </TableRow>
                ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 48 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </Paper>
    );
  }
}

export default businessPaginationTable;
