import React, { Component } from "react";

// Material
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as CompartmentActions } from "../../../../store/ducks/compartment";
import { Actions as LockerActions } from "../../../../store/ducks/locker";

// Components
import Container from "../../../../components/Container";
import Snackbar from "../../../../components/Snackbar";
import { materialStyle } from "../../../../styles";

class CompartmentEdit extends Component {
  state = {
    compartmentNumber: "",
    portNumber: "",
    idCompartmentAvailability: "",
    idCompartmentDimensions: "",
    idLocker: "",
    isEmpty: false
  };

  componentWillMount() {
    const { getCompartmentsInfoRequest, lockersRequest } = this.props;

    lockersRequest("");
    getCompartmentsInfoRequest();
  }

  handleChange = key => event => this.setState({ [key]: event.target.value });

  handleSave = () => {
    const {
      compartmentNumber,
      portNumber,
      idCompartmentAvailability,
      idCompartmentDimensions,
      idLocker
    } = this.state;

    if (
      !compartmentNumber ||
      !portNumber ||
      !idCompartmentAvailability ||
      !idCompartmentDimensions ||
      !idLocker
    ) {
      this.setState({ isEmpty: true });
      return;
    } else {
      this.setState({ isEmpty: false });
    }

    const { createCompartmentRequest } = this.props;

    createCompartmentRequest({
      portNumber,
      compartmentNumber,
      idCompartmentAvailability,
      idCompartmentDimensions,
      idLocker
    });
  };

  render() {
    const {
      compartmentNumber,
      portNumber,
      idCompartmentAvailability,
      idCompartmentDimensions,
      idLocker,
      isEmpty
    } = this.state;
    const {
      classes,
      compartments,
      lockers,
      setSnackbarVisibleCompartment
    } = this.props;

    return (
      <Container title="Criar porta">
        <Snackbar
          open={isEmpty}
          message="Preencha todos os campos"
          variant="warning"
          onClose={() => this.setState({ isEmpty: false })}
        />
        <Snackbar
          open={compartments.error}
          message={compartments.error && compartments.error.error.message}
          variant="error"
          onClose={() => setSnackbarVisibleCompartment(false)}
        />
        <TextField
          disabled={compartments.loading}
          id="outlined-input"
          label="Número da porta"
          className={classes.textField}
          value={portNumber}
          onChange={this.handleChange("portNumber")}
          margin="normal"
          variant="outlined"
          type="number"
          inputProps={{ min: "1" }}
        />
        <TextField
          disabled={compartments.loading}
          id="outlined-input"
          label="Número da porta"
          className={classes.textField}
          value={compartmentNumber}
          onChange={this.handleChange("compartmentNumber")}
          margin="normal"
          variant="outlined"
          type="number"
          inputProps={{ min: "1" }}
        />
        <TextField
          disabled={compartments.loading}
          id="custom-css-outlined-input"
          select
          label="Status"
          className={classes.textFieldLocker}
          value={idCompartmentDimensions}
          onChange={this.handleChange("idCompartmentDimensions")}
          SelectProps={{
            MenuProps: {
              className: classes.menu
            }
          }}
          margin="normal"
          variant="outlined"
        >
          {compartments.info &&
            compartments.info.dimensions.map(item => (
              <MenuItem key={item.id} value={item.id}>
                {item.description}
              </MenuItem>
            ))}
        </TextField>
        <TextField
          disabled={compartments.loading}
          id="custom-css-outlined-input"
          select
          label="Dimensões"
          className={classes.textFieldLocker}
          value={idCompartmentAvailability}
          onChange={this.handleChange("idCompartmentAvailability")}
          SelectProps={{
            MenuProps: {
              className: classes.menu
            }
          }}
          // helperText="Número do locker"
          margin="normal"
          variant="outlined"
        >
          {compartments.info &&
            compartments.info.availability.map(item => (
              <MenuItem key={item.id} value={item.id}>
                {item.description}
              </MenuItem>
            ))}
        </TextField>
        <TextField
          disabled={compartments.loading}
          id="custom-css-outlined-input"
          select
          label="Locker"
          className={classes.textFieldLocker}
          value={idLocker}
          onChange={this.handleChange("idLocker")}
          SelectProps={{
            MenuProps: {
              className: classes.menu
            }
          }}
          // helperText="Número do locker"
          margin="normal"
          variant="outlined"
        >
          {lockers.data &&
            lockers.data.map(item => (
              <MenuItem key={item.id} value={item.id}>
                {item.lockerNumber} - {item.address}
              </MenuItem>
            ))}
        </TextField>
        <br />
        <Button
          disabled={compartments.loading}
          className={classes.button}
          onClick={this.handleSave}
          variant="contained"
        >
          Criar
        </Button>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  compartments: state.compartment,
  lockers: state.locker
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...CompartmentActions,
      ...LockerActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(materialStyle)(CompartmentEdit));
