import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

// Icons
import Business from "@material-ui/icons/Business";
import Group from "@material-ui/icons/Group";
import BarChartIcon from "@material-ui/icons/BarChart";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import LockIcon from "@material-ui/icons/Lock";
import CompareIcon from "@material-ui/icons/CompareArrows";
import ConfigurationIcon from "@material-ui/icons/Ballot";
import BuildIcon from "@material-ui/icons/Build";
import ExtensionIcon from "@material-ui/icons/ExtensionOutlined";
import EmailIcon from "@material-ui/icons/Email";
import HistoryIcon from "@material-ui/icons/History";
import LocalActivity from "@material-ui/icons/LocalActivity";
import FeedbackIcon from "@material-ui/icons/Feedback";
import Receipt from "@material-ui/icons/Receipt";
import ApartmentIcon from "@material-ui/icons/Apartment";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import QuestionAnswer from "@material-ui/icons/QuestionAnswer";
import { DataUsage, LocalMall } from "@material-ui/icons";
import ListAltIcon from "@material-ui/icons/ListAlt";
import LocalLaundryServiceIcon from "@material-ui/icons/LocalLaundryService";
import { Person } from "@material-ui/icons";

import { DefaultLink, StyledLink } from "./styled";
import NestedList from "./nestedList";

import { checkRoles } from "../../services/permissions";
import checkHandoverEmail from "../../services/checkHandoverEmail";

// Roles
import menuRoles from "../../config/roles/menus";
import constants from "../../config/constants";
import MenuTypography from "./components/MenuTypography";

const style = {
  color: "white"
};

const configuration = [
  {
    id: Math.random(),
    to: "/configuration",
    label: "empresa",
    icon: <Business />,
    allowedRoles: menuRoles.configurationBusiness,
    forLicensed: true,
    onlyUsers: true
  },
  {
    id: Math.random(),
    to: "/contributors",
    label: "colaboradores",
    icon: <Group />,
    forLicensed: true
  },
  {
    id: Math.random(),
    to: "/integrations",
    label: "integrações",
    allowedRoles: menuRoles.lockerSurvey,
    icon: <ExtensionIcon />,
    forLicensed: true,
    keyParameters: "useCondominium"
  }
];

const tools = [
  // {
  //   id: Math.random(),
  //   to: "/tools/calcroutes",
  //   label: "posicionador de locker",
  //   icon: <LocationOn style={style} />,
  //   forLicensed: false
  // },
  // {
  //   id: Math.random(),
  //   to: "/tools",
  //   label: "otimizador de rotas",
  //   icon: <LocalShippingIcon style={style} />,
  //   forLicensed: false
  // },
  // {
  //   id: Math.random(),
  //   to: "/tools/heatMap",
  //   label: "mapa de calor",
  //   icon: <MapIcon style={style} />,
  //   forLicensed: false
  // },
  {
    id: Math.random(),
    to: "/internal/emails",
    label: "envio de emails",
    icon: <EmailIcon style={style} />,
    forLicensed: false
  }
];

const listOptions = [
  {
    id: Math.random(), // random ID para identificar component
    to: "/delivery", // url do menu
    label: "gerenciar entregas", // label mostrada no menu
    allowedRoles: menuRoles.menuDeliveryRoles, // regras permitidas para renderizar o menu
    icon: <CompareIcon />, // icone
    forLicensed: false, // somente para clientes licenciados?
    keyParametersReject: "useRentalOrder"
  },
  {
    id: Math.random(), // random ID para identificar component
    to: "/laundry/locker/items", // url do menu
    label: "itens lavanderia", // label mostrada no menu
    allowedRoles: menuRoles.menuDeliveryRoles, // regras permitidas para renderizar o menu
    icon: <ListAltIcon />, // icone
    forLicensed: false,
    keyParameters: "useLaundry"
  },
  {
    id: Math.random(), // random ID para identificar component
    to: "/laundry/users", // url do menu
    label: "clientes da lavanderia", // label mostrada no menu
    allowedRoles: menuRoles.menuDeliveryRoles, // regras permitidas para renderizar o menu
    icon: <LocalLaundryServiceIcon />, // icone
    forLicensed: false,
    keyParameters: "useLaundry"
  },
  {
    id: Math.random(), // random ID para identificar component
    to: "/rental", // url do menu
    label: "relatórios", // label mostrada no menu
    allowedRoles: menuRoles.menuLockerRoles, // regras permitidas para renderizar o menu
    icon: <LocalMall />, // icone
    forLicensed: false,
    isPrivate: false,
    keyParameters: "useRentalOrder"
  },
  {
    id: Math.random(), // random ID para identificar component
    to: "/rental/users", // url do menu
    label: "usuários guarda-volumes", // label mostrada no menu
    allowedRoles: menuRoles.menuDeliveryRoles, // regras permitidas para renderizar o menu
    icon: <Person />, // icone
    forLicensed: false,
    isPrivate: true
  },
  {
    id: Math.random(), // random ID para identificar component
    to: "/orders", // url do menu
    label: "b2c", // label mostrada no menu
    allowedRoles: menuRoles.menuDeliveryRoles, // regras permitidas para renderizar o menu
    icon: <CompareIcon />, // icone
    forLicensed: false,
    isPrivate: true
  },
  {
    id: Math.random(),
    to: "/reports",
    label: "relatórios",
    allowedRoles: menuRoles.menuReportsRoles,
    icon: <BarChartIcon />,
    forLicensed: false,
    keyParametersReject: "useRentalOrder"
  },

  // {
  //   id: Math.random(),
  //   to: "/deploymentStatus",
  //   label: "status de implantação",
  //   allowedRoles: menuRoles.lockerSurvey,
  //   icon: <DescriptionIcon />
  // },
  {
    id: Math.random(),
    to: "/spendinghistory",
    label: "extrato de receita",
    allowedRoles: menuRoles.menuReportsRoles,
    icon: <HistoryIcon />,
    forLicensed: true,
    onlyLicensed: true,
    keyParameters: "activeOrderFee"
  },
  {
    id: Math.random(),
    to: "/userCreditTickets",
    label: "uso de cupons",
    allowedRoles: menuRoles.menuReportsRoles,
    icon: <LocalActivity />,
    forLicensed: true,
    onlyLicensed: true,
    keyParameters: "activeOrderFee"
  },
  {
    id: Math.random(),
    to: "/lockers",
    label: "lockers",
    allowedRoles: menuRoles.menuLockerRoles,
    icon: <LockIcon />,
    forLicensed: true
  },
  // {
  //   id: Math.random(),
  //   to: "/groups",
  //   label: "Grupos",
  //   allowedRoles: menuRoles.menuLockerRoles,
  //   icon: <GroupIcon />,
  //   forLicensed: true,
  //   keyParameters: "useThings"
  // },
  // {
  //   id: Math.random(),
  //   to: "/externalProfile",
  //   label: "perfil externo",
  //   allowedRoles: menuRoles.menuLockerRoles,
  //   icon: <SupervisedUserCircle />,
  //   forLicensed: true,
  //   keyParameters: "useThings"
  // },
  // {
  //   id: Math.random(),
  //   to: "/thing/types",
  //   label: "thing types",
  //   allowedRoles: menuRoles.menuLockerRoles,
  //   icon: <LockIcon />,
  //   forLicensed: true,
  //   keyParameters: "useThings"
  // },
  // {
  //   id: Math.random(),
  //   to: "/things",
  //   label: "thing",
  //   allowedRoles: menuRoles.menuLockerRoles,
  //   icon: <LockIcon />,
  //   forLicensed: true,
  //   keyParameters: "useThings"
  // },
  {
    id: Math.random(),
    to: "/internal/support/lockers",
    label: "gestão de lockers interno",
    allowedRoles: menuRoles.menuLockerRoles,
    icon: <LockIcon />,
    forLicensed: false,
    isPrivate: true
  },
  {
    id: Math.random(),
    to: "/internal/delivery/usage",
    label: "uso zap",
    allowedRoles: menuRoles.menuLockerRoles,
    icon: <DataUsage />,
    forLicensed: false,
    isPrivate: true
  },
  {
    id: Math.random(),
    to: "/customer/feedback",
    label: "feedback dos clientes",
    allowedRoles: menuRoles.menuLockerRoles,
    icon: <FeedbackIcon />,
    forLicensed: true,
    keyParameters: "activeCustomerFeedback"
  },
  {
    id: Math.random(),
    to: "/partners/nepos/tickets",
    label: "validação de tickets",
    allowedRoles: menuRoles.partnerNeposTickts,
    icon: <Receipt />,
    forLicensed: true,
    keyParameters: "usePartnerNepos"
  },
  {
    id: Math.random(),
    to: "/configuration",
    label: "configurações",
    allowedRoles: menuRoles.menuConfigurationRoles,
    icon: <ConfigurationIcon />,
    handleClick: true, // usado para menus com subMenu
    list: configuration,
    forLicensed: true
  },
  {
    id: Math.random(),
    to: "/subTeam/condominium",
    label: "unidades",
    allowedRoles: menuRoles.apartment,
    icon: <ApartmentIcon style={style} />,
    keyParameters: "useCondominium"
  },
  // {
  //   id: Math.random(),
  //   to: "/administrator/roles",
  //   label: "Administrativo",
  //   allowedRoles: menuRoles.administrativeRole,
  //   icon: <ConfigurationIcon />,
  //   forLicensed: false
  // },
  {
    id: Math.random(),
    to: "/tools",
    label: "ferramentas",
    allowedRoles: menuRoles.menuDeliveryRoles,
    handleClick: true, // usado para menus com subMenu
    icon: <BuildIcon />,
    isChecked: true,
    list: tools,
    forLicensed: false,
    keyParameters: "activeTools" // key usada para verificar se a mesma está ativada nos parametros do cliente
  },

  {
    id: Math.random(),
    to: constants.baseConhecimento,
    label: "base de conhecimento",
    allowedRoles: ["*"],
    icon: <QuestionAnswer />,
    blank: true
  },
  {
    id: Math.random(),
    to: constants.ticketURL,
    label: "suporte via chat",
    allowedRoles: ["*"],
    icon: <ContactSupportIcon />,
    blank: true
  }
];

export const ListOptions = () => {
  const user = localStorage.getItem("auth");
  const { roles, currentTeam } = JSON.parse(user);
  const parameters = JSON.parse(localStorage.getItem("auth")).currentTeam
    .parameters;
  const { email, isPrivate } = JSON.parse(localStorage.getItem("auth")).user;
  let list = [];
  for (let i = 0; i < listOptions.length; i++) {
    if (checkHandoverEmail(email)) {
      list[i] = listOptions[i];
      continue;
    }

    // verificar se isPrivate
    if (!isPrivate && listOptions[i].isPrivate) {
      continue;
    }

    // verifica se possui permissão
    if (
      checkRoles(listOptions[i].allowedRoles, roles, listOptions[i].isChecked)
    ) {
      // verifica se menu possui subMenu através da prop handleClick
      if (roles.includes("masterAdministrator") && listOptions[i].handleClick) {
        /**
         * dentro do menu, tem uma prop list com todos os subMenus
         * Se usuário atual for master e onlyUsers for true, não add pra usuário master
         * mas somente para usuários comuns
         */
        const list = listOptions[i].list.filter(i => !i.onlyUsers);
        listOptions[i].list = list;
      }

      // não é email handover
      if (!checkHandoverEmail(email)) {
        // é somente para cliente licenciado
        if (parseInt(currentTeam.isLicensed)) {
          if (
            !(listOptions[i].forLicensed || listOptions[i]) &&
            !roles.includes("masterAdministrator")
          )
            continue;
        } else {
          if (listOptions[i].onlyLicensed) {
            continue;
          }
        }
      }

      /**
       * Verifica se existe a prop keyParametersReject
       * e verifica se nos parametros do time o keyParametersReject está ativado
       */
      if (
        listOptions[i].keyParametersReject &&
        parameters[listOptions[i].keyParametersReject]
      ) {
        // se email não for handover, remove menu
        if (!checkHandoverEmail(email)) {
          continue;
        }
      }

      /**
       * Verifica se existe a prop keyParamenters
       * e verifica se nos parametros do time o keyParamters está ativo
       */
      if (
        listOptions[i].keyParameters &&
        !parameters[listOptions[i].keyParameters]
      ) {
        // se email não for handover, remove menu
        if (!checkHandoverEmail(email)) {
          continue;
        }
      }
      list[i] = listOptions[i];
    }
  }
  // ------- Recebendo do Local Storage o botão que foi clicado ----------------
  const selectedBtn = localStorage.getItem("selectedButton");
  // --------------------------------------------------------------------------

  return (
    <React.Fragment>
      {list.length
        ? list.map(
            menu =>
              menu.handleClick ? (
                <NestedList
                  list={menu.list}
                  text={menu.label}
                  icon={menu.icon}
                  style={style}
                  key={menu.id}
                  useCondominium={parameters.useCondominium}
                  newItem={menu.label === "Ferramentas"}
                />
              ) : menu.blank ? (
                <DefaultLink
                  key={menu.id}
                  onClick={() => {
                    window.open(menu.to, "_blank", "noopener,noreferrer");
                  }}
                >
                  <ListItem
                    button
                    // Verificando se o botão clicado é o mesmo e trocando a cor
                    style={
                      selectedBtn === menu.to
                        ? {
                            backgroundColor: "rgba(20,20,20, 0.6)",
                            borderRight: "4px solid #2fe076"
                          }
                        : {}
                    }
                  >
                    <ListItemIcon
                      style={
                        selectedBtn === menu.to
                          ? { color: "#2fe076" }
                          : { color: "white" }
                      }
                    >
                      {menu.icon}
                    </ListItemIcon>
                    <ListItemText
                      id="list-item-text-menu"
                      style={style}
                      primary={<MenuTypography>{menu.label}</MenuTypography>}
                    />
                  </ListItem>
                </DefaultLink>
              ) : (
                // Links do menu
                <StyledLink
                  key={menu.id}
                  to={menu.to}
                  onClick={() => {
                    localStorage.setItem("selectedButton", menu.to);
                  }}
                >
                  <ListItem
                    button
                    // Verificando se o botão clicado é o mesmo e trocando a cor
                    style={
                      selectedBtn === menu.to
                        ? {
                            backgroundColor: "rgba(20,20,20, 0.6)",
                            borderRight: "4px solid #2fe076"
                          }
                        : {}
                    }
                  >
                    <ListItemIcon
                      style={{
                        color: selectedBtn === menu.to ? "#2fe076" : "white"
                      }}
                    >
                      {menu.icon}
                    </ListItemIcon>
                    <ListItemText
                      id="list-item-text-menu"
                      style={style}
                      primary={<MenuTypography>{menu.label}</MenuTypography>}
                    />
                  </ListItem>
                </StyledLink>
              )
          )
        : null}
    </React.Fragment>
  );
};
