import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as LocationActions } from "../../../../store/ducks/location";

import "./style.css";

// Services
import checkEmail from "../../../../services/checkEmail.js";
import api from "../../../../services/api";

// Componets
import Stepper from "../stepper";
import Password from "./components/password";

// Utils
import { maskCPF, maskCEP, maskCell } from "../../../../utils/masks";
import { validationCPF } from "../../../../utils/validation";

// Materials
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import { TextField, Button, Grid, FormHelperText } from "@material-ui/core";

import ThemeTextFildGreen from "../../../../styles/ThemeTextFildGreen";
import getOnlyNumbers from "../../../../services/getOnlyNumbers";
import TextMaskCustomCNPJ from "../../../../components/TextMaskCustomCNPJ";

class containerStepper extends React.Component {
  componentDidMount() {
    const date = new Date();
    const day =
      date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;
    const month =
      date.getMonth() + 1 < 10
        ? `0${date.getMonth() + 1}`
        : `${date.getMonth() + 1}`;
    const today = `${date.getFullYear()}-${month}-${day}`;
    this.setState({ age: today });
  }

  state = {
    loading: false,
    position: 0,
    humanConfirmation: false,
    age: "",

    cnpj: "",
    name: "",
    businessName: "",
    email: "",
    zipCode: "",
    uf: "",
    city: "",
    neighborhood: "",
    streetName: "",
    streetNumber: "",
    unitNumber: "",

    managerName: "",
    managerCPF: "",
    managerBirthdayDate: "",
    managerPhoneNumber: "",
    managerEmail: "",

    password: "",
    passwordConfirmation: "",

    errorGeral: false,

    erro: {
      businessName: false,
      cnpj: false,
      email: false,
      zipCode: false,
      uf: false,
      city: false,
      neighborhood: false,
      streetName: false,
      streetNumber: false,

      managerName: false,
      managerCPF: false,
      managerBirthdayDate: false,
      managerPhoneNumber: false,
      managerEmail: false,

      password: false,
      passwordConfirmation: false,
      older: false,

      humanConfirmation: false
    },
    disabled: {
      uf: false,
      city: false,
      neighborhood: false,
      streetName: false
    }
  };

  searchCEP = async par => {
    const result = await axios({
      url: `https://viacep.com.br/ws/${par}/json/`
    });
    return result;
  };

  getCEP = async zipCode => {
    const { getLocationRequest } = this.props;
    const response = await axios.get(
      `https://viacep.com.br/ws/${zipCode}/json/`
    );

    const { ...data } = response.data;

    getLocationRequest({
      cityName: data.localidade,
      zipCode: zipCode,
      streetName: response.data.logradouro
    });

    return response;
  };

  verificationOfFieldsPage = async () => {
    const state = this.state;
    if (state.position === 2) {
      state.erro.managerEmail = checkEmail(state.managerEmail);
      state.erro.password = state.password.length > 7 ? false : true;
      state.erro.passwordConfirmation = state.passwordConfirmation
        ? false
        : true;
      state.erro.passwordConfirmation =
        state.passwordConfirmation === state.password ? false : true;
      state.erro.humanConfirmation = state.humanConfirmation ? false : true;
      if (
        !state.erro.managerEmail &&
        !state.erro.password &&
        !state.erro.passwordConfirmation &&
        !state.erro.humanConfirmation
      ) {
        const { location } = this.props;
        try {
          this.setState({ loading: true });
          await api.post(
            "/signupBusiness",
            {
              name: this.state.name,
              email: this.state.email,
              cpf: this.state.managerCPF,
              password: this.state.password,
              cnpj: this.state.cnpj,
              businessName: this.state.businessName,
              streetName: this.state.streetName,
              streetNumber: this.state.streetNumber,
              unitNumber: this.state.unitNumber,
              zipCode: this.state.zipCode,
              idCountry: location.data.country.id,
              idCity: location.data.city.id,
              idState: location.data.state.id,
              contactNumber: this.state.managerPhoneNumber,
              contactName: this.state.managerName,
              contactEmail: this.state.managerEmail,
              offlineLocker: 1,
              useCondominium: 1
            },
            {
              headers: {
                public: true
              }
            }
          );

          state.position =
            state.position === 2 ? state.position + 1 : state.position;
        } catch (error) {
          this.setState({
            errorGeral: true
          });
          return;
        } finally {
          this.setState({ loading: false });
        }
      }
    }
    if (state.position === 1) {
      // condição para tela 1
      state.erro.managerName = state.managerName.split(" ")[1] ? false : true;
      state.erro.managerCPF =
        state.managerCPF.length === 14 && validationCPF(state.managerCPF)
          ? false
          : true;
      state.erro.managerPhoneNumber =
        state.managerPhoneNumber.length === 15 ? false : true;
      let niver = parseInt(
        `${state.managerBirthdayDate.split("-")[0]}${
          state.managerBirthdayDate.split("-")[1]
        }${state.managerBirthdayDate.split("-")[2]}`
      );
      let older =
        parseInt(
          `${this.state.age.split("-")[0]}${this.state.age.split("-")[1]}${
            this.state.age.split("-")[2]
          }`
        ) - 180000;
      if (niver > older) {
        state.erro.older = true;
      } else if (niver <= older) {
        state.erro.older = false;
      }
      // )
      if (
        // condição para tela 1
        !state.erro.managerName &&
        !state.erro.managerCPF &&
        !state.erro.managerPhoneNumber &&
        !state.erro.older
      ) {
        state.position =
          state.position === 1 ? state.position + 1 : state.position;
      }
    }
    if (state.position === 0) {
      // condição para tela 0

      state.erro.email = checkEmail(state.email);
      // state.erro.cnpj =
      //   `${getOnlyNumbers(state.cnpj)}`.length === 14 &&
      //   validarCNPJ(getOnlyNumbers(state.cnpj))
      //     ? false
      //     : true;
      state.erro.businessName = state.businessName ? false : true;
      state.erro.zipCode = state.zipCode.length === 9 ? false : true;
      state.erro.city = state.city ? false : true;
      state.erro.uf = state.uf ? false : true;
      state.erro.neighborhood = state.neighborhood ? false : true;
      state.erro.streetName = state.streetName ? false : true;
      state.erro.streetNumber = state.streetNumber ? false : true;
      console.log("erro", state.erro);
      if (
        !state.erro.cnpj &&
        !state.erro.email &&
        !state.erro.businessName &&
        !state.erro.zipCode &&
        !state.erro.city &&
        !state.erro.uf &&
        !state.erro.neighborhood &&
        !state.erro.streetName &&
        !state.erro.streetNumber
      ) {
        state.position =
          state.position === 0 ? state.position + 1 : state.position;
        console.log("esta é a posição atual", state.position);
      }
    }
    this.setState(state);
  };

  handleChange = async (name, value) => {
    const { erro } = this.state;
    if (name === "cnpj") {
      erro.cnpj = !value ? true : false;
      this.setState({ erro: erro });

      value = getOnlyNumbers(value);
    }
    if (name === "managerCPF") {
      value = maskCPF(value);
    }
    if (name === "zipCode") {
      value = maskCEP(value, this.state.zipCode);
      if (value.length === 9) {
        const zipCode = `${value.slice(0, 5)}${value.slice(6, 9)}`;
        const result = await this.getCEP(zipCode);
        const { data } = result;
        const state = this.state;
        state.uf = data.uf;
        state.city = data.localidade;
        state.neighborhood = data.bairro;
        state.streetName = data.logradouro;
        state.disabled.neighborhood = data.bairro ? true : false;
        state.disabled.streetName = data.logradouro ? true : false;
        state.disabled.uf = data.uf ? true : false;
        state.disabled.city = data.localidade ? true : false;
        this.setState(state);
      }
    }
    if (name === "streetNumber") {
      value = value
        .normalize("NFD")
        .replace(/[^a-zA-Zs0-9]/g, "")
        .toUpperCase();
    }
    if (name === "managerPhoneNumber") {
      value = maskCell(value, this.state.managerPhoneNumber);
    }
    if (name === "password") {
      if (value.length < 8) {
        erro.password = true;
      } else {
        erro.password = false;
      }
    }
    if (name === "passwordConfirmation") {
      if (value === this.state.password) {
        erro.passwordConfirmation = false;
      } else {
        erro.passwordConfirmation = true;
      }
    }
    this.setState({ [name]: value, erro: erro });
  };

  render() {
    const styleButton = {
      green: {
        color: "rgb(255, 255, 255)",
        backgroundColor: "#006660"
      },
      grey: {
        color: "rgb(255, 255, 255)",
        backgroundColor: "rgb(200,200,200)"
      }
    };
    return (
      <>
        <ThemeTextFildGreen>
          {this.state.position !== 3 && (
            <Stepper position={this.state.position} />
          )}
          {this.state.position === 0 && ( // Pagina 0
            <>
              <h4 id="label_cadastro">Insira alguns dados da sua empresa</h4>
              <TextField
                name="cnpj"
                placeholder="Digite o número do CNPJ"
                value={this.state.cnpj}
                onChange={e => this.handleChange(e.target.name, e.target.value)}
                error={this.state.erro.cnpj}
                size="small"
                label="CNPJ"
                variant="outlined"
                margin="dense"
                fullWidth
                InputProps={{
                  inputComponent: TextMaskCustomCNPJ
                }}
              />
              <TextField
                name="businessName"
                placeholder="Nome da sua empresa"
                margin="dense"
                value={this.state.businessName}
                onChange={e => this.handleChange(e.target.name, e.target.value)}
                error={this.state.erro.businessName}
                size="small"
                label="Razão Social"
                variant="outlined"
                fullWidth
              />
              <TextField
                name="name"
                placeholder="Nome fantasia"
                margin="dense"
                value={this.state.name}
                onChange={e => this.handleChange(e.target.name, e.target.value)}
                error={this.state.erro.name}
                size="small"
                label="Nome Fantasia"
                variant="outlined"
                fullWidth
              />
              <TextField
                name="email"
                placeholder="Endereço de e-mail corporativo"
                margin="dense"
                value={this.state.email}
                onChange={e => this.handleChange(e.target.name, e.target.value)}
                error={this.state.erro.email}
                size="small"
                label="Email corporativo de contato"
                variant="outlined"
                fullWidth
              />
              <FormHelperText
                error={this.state.erro.email}
                style={{ marginBottom: 10 }}
              >
                O e-mail deve ser preferencialmente corporativo, e-mail pessoal
                não deve ser utilizado.
              </FormHelperText>
              <TextField
                name="zipCode"
                placeholder="CEP da cidade"
                margin="dense"
                value={this.state.zipCode}
                onChange={e => this.handleChange(e.target.name, e.target.value)}
                error={this.state.erro.zipCode}
                size="small"
                label="CEP"
                variant="outlined"
                fullWidth
              />
              <Grid container spacing={2}>
                <Grid item md={6} xs={6}>
                  <TextField
                    name="city"
                    margin="dense"
                    placeholder="Nome da cidade"
                    value={this.state.city}
                    onChange={e =>
                      this.handleChange(e.target.name, e.target.value)
                    }
                    error={this.state.erro.city}
                    disabled={this.state.disabled.city}
                    size="small"
                    label="Cidade"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item md={6} xs={6}>
                  <TextField
                    name="uf"
                    margin="dense"
                    placeholder="Estado"
                    value={this.state.uf}
                    onChange={e =>
                      this.handleChange(e.target.name, e.target.value)
                    }
                    error={this.state.erro.uf}
                    disabled={this.state.disabled.uf}
                    size="small"
                    label="Estado"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={6} xs={6}>
                  <TextField
                    name="neighborhood"
                    value={this.state.neighborhood}
                    onChange={e =>
                      this.handleChange(e.target.name, e.target.value)
                    }
                    error={this.state.erro.neighborhood}
                    size="small"
                    label="Bairro"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item md={6} xs={6}>
                  <TextField
                    name="streetName"
                    placeholder="Rua, avenida..."
                    value={this.state.streetName}
                    onChange={e =>
                      this.handleChange(e.target.name, e.target.value)
                    }
                    error={this.state.erro.streetName}
                    disabled={this.state.disabled.streetName}
                    size="small"
                    label="Logradouro"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ marginBottom: 10 }}>
                <Grid item md={6} xs={6}>
                  <TextField
                    name="streetNumber"
                    placeholder="Número da casa, prédio..."
                    value={this.state.streetNumber}
                    onChange={e =>
                      this.handleChange(e.target.name, e.target.value)
                    }
                    error={this.state.erro.streetNumber}
                    size="small"
                    label="Número"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item md={6} xs={6}>
                  <TextField
                    name="unitNumber"
                    placeholder="Proximo de..., observação..."
                    value={this.state.unitNumber}
                    onChange={e =>
                      this.handleChange(e.target.name, e.target.value)
                    }
                    error={this.state.erro.unitNumber}
                    size="small"
                    label="Complemento"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </>
          )}
          {this.state.position === 1 && ( // Pagina 1
            <>
              <h4 id="label_cadastro">
                Insira seus dados de gestor da operação
              </h4>
              <TextField
                name="managerName"
                value={this.state.managerName}
                onChange={e => this.handleChange(e.target.name, e.target.value)}
                error={this.state.erro.managerName}
                size="small"
                margin="dense"
                label="Nome completo"
                variant="outlined"
                fullWidth
              />
              <FormHelperText error={this.state.erro.managerName}>
                &nbsp;Nome completo do gestor da operação.
              </FormHelperText>
              <TextField
                name="managerCPF"
                placeholder="CPF do gestor da operação"
                value={this.state.managerCPF}
                onChange={e => this.handleChange(e.target.name, e.target.value)}
                error={this.state.erro.managerCPF}
                size="small"
                margin="dense"
                label="CPF"
                variant="outlined"
                fullWidth
              />

              <TextField
                name="managerPhoneNumber"
                placeholder="Número do Celular"
                value={this.state.managerPhoneNumber}
                onChange={e => this.handleChange(e.target.name, e.target.value)}
                error={this.state.erro.managerPhoneNumber}
                size="small"
                margin="dense"
                label="Celular"
                variant="outlined"
                fullWidth
              />
              <TextField
                name="managerBirthdayDate"
                type="date"
                // value={this.state.managerBirthdayDate}
                onChange={e => this.handleChange(e.target.name, e.target.value)}
                error={this.state.erro.older}
                size="small"
                margin="dense"
                label="Data de nascimento"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                fullWidth
              />
              {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  // className={classes.textField}
                  disableToolbar
                  format="dd/MM/yyyy"
                  margin="normal"
                  name="managerBirthdayDate"
                  inputVariant="outlined"
                  id="date-picker-inline"
                  label="Data inicial"
                  value={this.state.managerBirthdayDate}
                  onChange={e => this.handleChange('managerBirthdayDate', e)}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                  inputProps={{
                    margin: "normal"
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                  placeholder="Data inicial das locações"
                />
              </MuiPickersUtilsProvider> */}
              <FormHelperText
                style={{ marginBottom: "20px" }}
                error={this.state.erro.older}
              >
                &nbsp;Você precisa ser maior de 18 anos.
              </FormHelperText>
            </>
          )}
          {this.state.position === 2 && ( // Pagina 2
            <>
              <h4 id="label_cadastro">
                &nbsp;&nbsp;&nbsp;dados de acesso a conta&nbsp;&nbsp;&nbsp;
              </h4>

              <TextField
                name="managerEmail"
                placeholder="Email do gestor da operação"
                value={this.state.managerEmail}
                onChange={e => this.handleChange(e.target.name, e.target.value)}
                error={this.state.erro.managerEmail}
                size="small"
                label="E-mail"
                variant="outlined"
                fullWidth
              />
              <FormHelperText
                error={this.state.erro.managerName}
                style={{ marginBottom: 10 }}
              >
                &nbsp; Digite o e-mail para acesso a conta.
              </FormHelperText>
              <Password
                name="password"
                label="Crie uma senha"
                state={this.state}
                erro={this.state.erro.password}
                handleChange={(name, value) => {
                  this.handleChange(name, value);
                }}
              />
              <FormHelperText
                error={this.state.erro.password}
                style={{ marginBottom: 10 }}
              >
                &nbsp;Sua senha deve ter ao menos 8 dígitos.
              </FormHelperText>
              <Password
                name="passwordConfirmation"
                label="Confirme sua senha"
                state={this.state}
                erro={this.state.erro.passwordConfirmation}
                handleChange={(name, value) => {
                  this.handleChange(name, value);
                }}
              />
              {this.state.errorGeral && (
                <p
                  style={{
                    fontSize: "0.75rem",
                    color: "#f44336"
                  }}
                >
                  Verifique se todos os campos estão corretos!
                </p>
              )}
              {!this.state.errorGeral && (
                <>
                  <br />
                  <br />
                </>
              )}
              <ReCAPTCHA
                style={{ marginBottom: "20px" }}
                sitekey="6Le_TtoZAAAAAErVeGhCWistFzXEU8-a7wqQF_YC"
                onChange={() => this.handleChange("humanConfirmation", true)}
              />
              {this.state.erro.humanConfirmation && (
                <FormHelperText error={this.state.erro.humanConfirmation}>
                  &nbsp;Não se esqueça de comfirmar a sua humanidade.
                </FormHelperText>
              )}
            </>
          )}
          {this.state.position === 3 && ( // Pagina 3 ultima pagina
            <>
              <br />
              <br />
              <br />
              <p
                style={{ color: "#1b301b", fontWeight: 400, fontSize: "25px" }}
              >
                Sua empresa foi cadastrada com sucesso.
              </p>
              <p
                style={{
                  color: "#1b301b",
                  fontSize: "20px",
                  marginBottom: "500px",
                  fontWeight: 400
                }}
              >
                Em até 48 horas você receberá um e-mail informando que o seu
                acesso foi liberado.
              </p>
            </>
          )}
          {this.state.position !== 3 && (
            <Grid container spacing={0}>
              {this.state.position > 0 && (
                <>
                  <Button
                    variant="contained"
                    style={{
                      ...(this.state.loading
                        ? styleButton.grey
                        : styleButton.green),
                      textTransform: "lowercase",
                      fontSize: 16,
                      fontWeight: 400
                    }}
                    onClick={() => {
                      if (this.state.position === 0) return;
                      this.setState({
                        position: this.state.position - 1,
                        humanConfirmation: false
                      });
                    }}
                    startIcon={<ArrowBackIos>send</ArrowBackIos>}
                    disabled={this.state.loading}
                  >
                    Anterior
                  </Button>
                  &nbsp;&nbsp;
                </>
              )}
              <Button
                variant="contained"
                style={{
                  ...(this.state.loading
                    ? styleButton.gray
                    : this.state.humanConfirmation || this.state.position !== 2
                      ? styleButton.green
                      : styleButton.grey),

                  textTransform: "lowercase",
                  fontSize: 16,
                  fontWeight: 400
                }}
                disabled={
                  (!this.state.humanConfirmation &&
                    this.state.position === 2) ||
                  this.state.loading
                }
                onClick={() => {
                  this.verificationOfFieldsPage(this.state.position);
                }}
                endIcon={<ArrowForwardIos>send</ArrowForwardIos>}
              >
                {this.state.position === 2
                  ? this.state.loading
                    ? "carregando..."
                    : "concluir"
                  : "próximo"}
              </Button>
            </Grid>
          )}
          <br />
          <br />
        </ThemeTextFildGreen>
      </>
    );
  }
}

const mapStateToProps = state => ({
  location: state.location
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...LocationActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(containerStepper);
