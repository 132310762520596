import React, { Component } from "react";

// Material
import { TextField, Grid, Button, MenuItem } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Autorenew, DeleteForever } from "@material-ui/icons";

// Global Components
import Container from "../../components/Container";
import Spinner from "../../components/Spinner";
import EditorCurrentRegisterContainer from "../../components/EditorCurrentRegisterContainer";
import ButtonEditorCurrentRegister from "../../components/ButtonEditorCurrentRegister";
import ModalEdit from "../../components/ModalEdit";
import AlertDialog from "../../components/AlertDialog";

// Global Style
import { materialStyle } from "../../styles";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as SubTeamSubUnitsActions } from "../../store/ducks/subTeamSubUnits";
import { Actions as SubTeamSubUnitsContactActions } from "../../store/ducks/subTeamSubUnitsContact";

// Table
import SubTeamSubUnitsContactTable from "../../components/SubTeamSubUnitsContactTable";

// history
import history from "../../routes/history";

class BusinessEdit extends Component {
  state = {
    dashboardParams: null,
    editableField: false,
    currentSubTeamUnits: null,
    statusModalVisible: false
  };

  componentWillMount() {
    const {
      showSubTeamSubUnitsRequest,
      getSubTeamSubUnitsContactRequest
    } = this.props;
    const { id } = this.props.match.params;

    showSubTeamSubUnitsRequest(id);
    getSubTeamSubUnitsContactRequest(`perPage=1000&idSubTeamSubUnits=${id}`);

    try {
      const parameters = JSON.parse(localStorage.getItem("auth")).currentTeam
        .parameters;

      if (parameters.dashboardParams && parameters.dashboardParams.length) {
        this.setState({
          dashboardParams: JSON.parse(parameters.dashboardParams)
        });
      }
    } catch (err) {}
  }

  // on receive current team update local state
  componentWillReceiveProps(nextProps) {
    const {
      subTeamSubUnits: { currentSubTeamSubUnits }
    } = nextProps;

    this.setState({
      currentSubTeamSubUnits
    });
  }

  render() {
    const {
      classes,
      subTeamSubUnits,
      changeStatusSubTeamSubUnitsRequest,
      updateSubTeamSubUnitsRequest
    } = this.props;
    const { id } = this.props.match.params;
    const {
      dashboardParams,
      editableField,
      currentSubTeamSubUnits,
      statusModalVisible
    } = this.state;
    const subTeamSubUnitsLabel =
      dashboardParams &&
      Object.keys(dashboardParams).includes("subTeamSubUnitsLabel")
        ? String(dashboardParams.subTeamSubUnitsLabel)
        : "Apartamento";

    return (
      <Container title={`Editar ${subTeamSubUnitsLabel.toLowerCase()}`}>
        {subTeamSubUnits.loading ? (
          <Spinner />
        ) : (
          <>
            <EditorCurrentRegisterContainer
              disabled={editableField}
              flexDirection="row-reverse"
            >
              <ButtonEditorCurrentRegister
                label="Editar"
                onClick={() =>
                  this.setState({
                    editableField: true
                  })
                }
              />
            </EditorCurrentRegisterContainer>
            <h3>Informações</h3>
            <ModalEdit
              open={editableField}
              onClose={() => this.setState({ editableField: false })}
              onConfirm={() => {
                updateSubTeamSubUnitsRequest(currentSubTeamSubUnits);
                this.setState({ editableField: false });
              }}
              classes={classes}
              title={`Editar ${
                dashboardParams
                  ? dashboardParams.subTeamSubUnitsLabel
                  : "apartamento"
              }`}
              subTitle="Informe abaixo os dados atualizados"
              loading={subTeamSubUnits.loading}
              disableConfirmButton={
                currentSubTeamSubUnits &&
                parseInt(currentSubTeamSubUnits.status) === 0
              }
            >
              <TextField
                label="Nome"
                id="outlined-name"
                className={classes.textField}
                value={currentSubTeamSubUnits && currentSubTeamSubUnits.name}
                margin="normal"
                variant="outlined"
                disabled={
                  subTeamSubUnits.loading ||
                  (currentSubTeamSubUnits &&
                    parseInt(currentSubTeamSubUnits.status) === 0)
                }
                onChange={e =>
                  this.setState({
                    currentSubTeamSubUnits: {
                      ...currentSubTeamSubUnits,
                      name: e.target.value
                    }
                  })
                }
              />
              <TextField
                select
                label="Status"
                id="outlined-name"
                className={classes.selectType}
                value={currentSubTeamSubUnits && currentSubTeamSubUnits.status}
                margin="normal"
                variant="outlined"
                disabled={true}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu
                  }
                }}
                onChange={e =>
                  this.setState({
                    currentSubTeamSubUnits: {
                      ...currentSubTeamSubUnits,
                      status: e.target.value
                    }
                  })
                }
              >
                {["1", "0"].map(item => (
                  <MenuItem key={item} value={item}>
                    {parseInt(item) ? "Ativado" : "Desativado"}
                  </MenuItem>
                ))}
              </TextField>
              {currentSubTeamSubUnits && (
                <>
                  <Button
                    variant="contained"
                    onClick={() => {
                      this.setState({
                        statusModalVisible: true,
                        editableField: false
                      });
                    }}
                    disabled={subTeamSubUnits.loading}
                    className={classes.button}
                  >
                    {parseInt(currentSubTeamSubUnits.status) ? (
                      <DeleteForever className={classes.leftIcon} />
                    ) : (
                      <Autorenew className={classes.leftIcon} />
                    )}
                    {parseInt(currentSubTeamSubUnits.status)
                      ? "Desativar"
                      : "Ativar"}
                  </Button>
                </>
              )}
            </ModalEdit>
            {currentSubTeamSubUnits && (
              <AlertDialog
                open={statusModalVisible}
                className={classes.button}
                title="Atenção"
                cancelButtonText="Cancelar"
                confirmButtonText="Sim"
                message={`Deseja realmente ${
                  parseInt(currentSubTeamSubUnits.status)
                    ? "desativar"
                    : "ativar"
                } ${subTeamSubUnitsLabel.toLowerCase()} com nome: ${
                  currentSubTeamSubUnits.name
                } ?`}
                onConfirm={() => {
                  changeStatusSubTeamSubUnitsRequest(
                    id,
                    parseInt(currentSubTeamSubUnits.status) === 1 ? 0 : 1
                  );
                }}
                disabled={false}
                handleClose={() => this.setState({ statusModalVisible: false })}
              />
            )}
            <Grid container spacing={2}>
              <Grid item xs={12} md={3} className="form-colaboradore-sm">
                <TextField
                  label="Nome"
                  id="outlined-name"
                  className={classes.textField}
                  value={
                    subTeamSubUnits.currentSubTeamSubUnits &&
                    subTeamSubUnits.currentSubTeamSubUnits.name
                  }
                  margin="normal"
                  variant="outlined"
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} md={3} className="form-colaboradore-sm">
                <TextField
                  id="outlined-name"
                  className={classes.textField}
                  label="Status"
                  value={
                    subTeamSubUnits.currentSubTeamSubUnits &&
                    parseInt(subTeamSubUnits.currentSubTeamSubUnits.status)
                      ? "Ativo"
                      : "Desativado"
                  }
                  margin="normal"
                  variant="outlined"
                  disabled={true}
                />
              </Grid>
            </Grid>

            <SubTeamSubUnitsContactTable
              history={history}
              idSubTeamSubUnits={id}
            />
          </>
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  subTeamSubUnits: state.subTeamSubUnits,
  subTeamSubUnitsContact: state.subTeamSubUnitsContact
});

const mapDispathToProps = dispatch =>
  bindActionCreators(
    {
      ...SubTeamSubUnitsActions,
      ...SubTeamSubUnitsContactActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispathToProps
)(withStyles(materialStyle)(BusinessEdit));
