import React, { createContext, useCallback, useEffect, useState } from "react";
import queryString from "query-string";
import api from "../../../../../services/api";
import DateFnsUtils from "@date-io/date-fns";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, TextField, Button, MenuItem } from "@material-ui/core";

import Container from "../../../../../components/Container";

// Redux

import Spinner from "../../../../../components/Spinner";
import ReportTeamTable from "../../../../../components/ReportTeamTable";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
const filterTypes = [
  { id: Math.random(), slug: "month", text: "Por mês" },
  { id: Math.random(), slug: "period", text: "Por período" }
];

const periods = [
  { id: 1, text: "Janeiro" },
  { id: 2, text: "Fevereiro" },
  { id: 3, text: "Março" },
  { id: 4, text: "Abril" },
  { id: 5, text: "Maio" },
  { id: 6, text: "Junho" },
  { id: 7, text: "Julho" },
  { id: 8, text: "Agosto" },
  { id: 9, text: "Setembro" },
  { id: 10, text: "Outubro" },
  { id: 11, text: "Novembro" },
  { id: 12, text: "Dezembro" }
];

const useStyles = makeStyles(theme => ({
  textFieldLocker1: {
    margin: theme.spacing(1),
    marginLeft: 0,
    width: "100%"
  },
  textField: {
    width: "100%",
    margin: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1)
  }
}));

const INITAL_DATE = new Date().setDate(1); // new Date().getUTCDate()
const FINAL_DATE = new Date(
  new Date().getFullYear(),
  new Date().getMonth() + 1,
  0
);

function ReportTeam() {
  const [data, setData] = useState();
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const [initialDate, setInitialDate] = useState(INITAL_DATE);
  const [finalDate, setFinalDate] = useState(FINAL_DATE);
  const dataContext = createContext(data);
  const [period, setPeriod] = useState(new Date().getUTCMonth() + 1);
  const [type, setType] = useState(filterTypes[0].id);
  const classes = useStyles();

  function handleDate(date) {
    return `${new Date(date).getUTCFullYear()}-${new Date(date).getUTCMonth() +
      1}-${new Date(date).getUTCDate()}`;
  }

  const fetch = useCallback(
    async () => {
      try {
        setLoading(true);
        setError(true);
        let query = "";

        if (type === filterTypes[0].id) {
          query = queryString.stringify({
            initialDate: period,
            year: new Date().getFullYear(),
            filterType: type.slug
          });
        } else if (type === filterTypes[1].id) {
          query = queryString.stringify({
            initialDate: handleDate(initialDate),
            finalDate: handleDate(finalDate)
          });
        }

        const queryData = await api.get(`/v1/adm/report/team?${query}`);

        setData(queryData.data);
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    },
    [type, period, initialDate, finalDate]
  );

  useEffect(() => {
    fetch();
  }, []);

  return (
    <Container title="Uso dos clientes ma plataforma handover">
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Grid container spacing={2} className="first-grid">
            <Grid item xs={12} md={2} className="form-colaboradore-sm">
              <TextField
                id="custom-css-outlined-input"
                select
                label="Tipo de filtro"
                className={classes.textFieldLocker1}
                value={type}
                onChange={({ target: { value } }) => {
                  setType(value);
                }}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu
                  }
                }}
                margin="normal"
                variant="outlined"
              >
                {filterTypes.map(item => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.text}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
            <Grid item xs={12} md={2} className="form-colaboradore-sm">
              <TextField
                disabled={type !== filterTypes[0].id}
                id="custom-css-outlined-input"
                select
                label="Período"
                className={classes.textFieldLocker1}
                value={period}
                onChange={({ target: { value } }) => {
                  setPeriod(value);
                }}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu
                  }
                }}
                margin="normal"
                variant="outlined"
              >
                {periods.map(item => {
                  return (
                    <MenuItem
                      key={item.id}
                      value={item.id}
                      disabled={
                        periods.findIndex(p => p.id === item.id) >
                        new Date().getMonth()
                      }
                    >
                      {item.text}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid item xs={12} md={3} className="form-colaboradore-sm">
                <KeyboardDatePicker
                  className={classes.textField}
                  disableToolbar
                  format="dd/MM/yyyy"
                  margin="normal"
                  inputVariant="outlined"
                  id="date-picker-inline"
                  label="Data inicial"
                  value={initialDate}
                  onChange={data => setInitialDate(data)}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                  inputProps={{
                    margin: "normal"
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                  placeholder="Data inicial das locações"
                  disabled={type !== filterTypes[1].id}
                />
              </Grid>

              <Grid item xs={12} md={3} className="form-colaboradore-sm">
                <KeyboardDatePicker
                  className={classes.textField}
                  disableToolbar
                  format="dd/MM/yyyy"
                  margin="normal"
                  inputVariant="outlined"
                  id="date-picker-inline"
                  label="Data Final"
                  value={finalDate}
                  onChange={data => setFinalDate(data)}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                  inputProps={{
                    margin: "normal"
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                  placeholder="Data final das locações"
                  disabled={type !== filterTypes[1].id}
                />
              </Grid>
            </MuiPickersUtilsProvider>
            <Grid container spacing={2}>
              <Button
                onClick={() => fetch()}
                variant="contained"
                className={classes.button}
              >
                Pesquisar
              </Button>
              <Button
                variant="contained"
                // onClick={this.handleClear}
                className={classes.button}
              >
                Limpar
              </Button>
            </Grid>
          </Grid>
          <ReportTeamTable dataContext={dataContext} />
        </>
      )}
    </Container>
  );
}

export default ReportTeam;
