import React from "react";

function FilterTitle() {
  return (
    <>
      <h6
        style={{
          marginLeft: 5,
          fontFamily: "'Livvic', sans-serif",
          color: "#1b301b"
        }}
      >
        Utilize uma das opções abaixo para realizar a pesquisa
      </h6>
      <br />
    </>
  );
}

export default FilterTitle;
