import React, { useState, useEffect, forwardRef, useCallback } from "react";

import MaterialTable from "material-table";
import queryString from "query-string";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Check, AddBoxRounded } from "@material-ui/icons";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

// Global
import Container from "../../../components/Container";

// Material
import { withStyles } from "@material-ui/core/styles";
import { Button, FormControl, MenuItem, Select } from "@material-ui/core";

// Redux
import api from "../../../services/api";
import Spinner from "../../../components/Spinner";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const styles = theme => ({
  button: {
    margin: theme.spacing(1)
  },
  textField: {
    margin: theme.spacing(1)
  },
  selectType: {
    margin: theme.spacing(1),
    minWidth: 150
  },
  tabContainer: {
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "column",
      padding: "0"
    }
  }
});

function SubTeamUnitsCreate({ classes, location }) {
  const { idLocker } = queryString.parse(location.search);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [locker, setLocker] = useState(null);
  const [identifiers, setIdentifiers] = useState([]);
  const [info, setInfo] = useState({
    availability: [],
    dimensions: [],
    status: []
  });

  useEffect(() => {
    async function fetch() {
      try {
        setLoading(true);
        setError(false);
        const dataLocker = await api.get(`/v1/adm/lockers/${idLocker}`);
        const dataIdentifiers = await api.get(
          "/v1/adm/compartment/identifiers"
        );
        const dataInfo = await api.get("/v1/adm/compartments/info");

        setIdentifiers(dataIdentifiers.data);
        setInfo(dataInfo.data);
        setLocker(dataLocker.data);
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    fetch();
  }, []);

  const fetch = useCallback(
    async () => {
      const _customData = data.map(item => {
        item.idCompartmentIdentifier = identifiers.find(
          i => i.description === item.idCompartmentIdentifier
        ).id;
        item.idCompartmentDimensions = info.dimensions.find(
          i => i.description === item.idCompartmentDimensions
        ).id;
        item.idCompartmentAvailability = 1;

        return item;
      });

      for (let i = 0; i < _customData.length; i++) {
        try {
          await api.post("/v1/adm/compartments", _customData[i]);

          setData(
            data.map(item => {
              if (item._id === _customData[i]._id) {
                item._status = "criado com sucesso";
              }
              return item;
            })
          );
        } catch (err) {
          setData(
            data.map(item => {
              if (item._id === _customData[i]._id) {
                item._status = "erro ao criar dados";
              }
              return item;
            })
          );
        }
      }
    },
    [data]
  );

  return (
    <Container
      title={`Criar portas para locker ${locker ? locker.address : ""}`}
    >
      {loading ? (
        <Spinner />
      ) : error ? (
        <h1>Erro no sistema</h1>
      ) : (
        <>
          <Alert severity="info" style={{ width: "100%", marginBottom: 20 }}>
            <AlertTitle>{locker.team.name}</AlertTitle>

            <p style={{ fontSize: "14px" }}>{locker.address}</p>
            <AlertTitle>Como utilizar?</AlertTitle>

            <ol style={{ marginTop: 10 }}>
              <li style={{ fontSize: "14px", fontFamily: "Work Sans" }}>
                Para inserir manualmente, clique no botão <AddBoxRounded />,
                insira os valores e depois clique no botão <Check /> na mesma
                linha
              </li>
              <li style={{ fontSize: "14px", fontFamily: "Work Sans" }}>
                Após validar seu dados na tabela, clique no botão{" "}
                <em>Criar lista</em>
              </li>
            </ol>
          </Alert>
          <MaterialTable
            icons={tableIcons}
            disabled={loading}
            title="Portas"
            columns={[
              {
                title: "_status",
                field: "_status",
                initialEditValue: "em espera",
                editable: false
              },
              {
                title: "numero da porta",
                field: "compartmentNumber",
                emptyValue: false,
                type: "numeric",
                initialEditValue: data.length + 1
              },
              {
                title: "Numero da porta na automacao",
                field: "portNumber",
                emptyValue: false,
                type: "numeric",
                initialEditValue: data.length + 1
              },
              {
                title: "Identificador do tamanho",
                field: "idCompartmentIdentifier",
                editComponent: props => (
                  <FormControl>
                    <Select
                      value={props.value || ""}
                      onChange={e => props.onChange(e.target.value)}
                    >
                      {identifiers.map(item => (
                        <MenuItem key={item.id} value={item.description}>
                          {item.description}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )
              },
              {
                title: "Dimensões",
                field: "idCompartmentDimensions",

                editComponent: props => (
                  <FormControl>
                    <Select
                      value={props.value || ""}
                      onChange={e => props.onChange(e.target.value)}
                    >
                      {info.dimensions.map(item => (
                        <MenuItem key={item.id} value={item.description}>
                          {item.description}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )
              }
            ]}
            data={data}
            localization={{
              body: {
                editRow: {
                  deleteText: "Deseja excluir este endereço?",
                  cancelTooltip: "Cancelar",
                  saveTooltip: "Salvar"
                },
                addTooltip: "Adicionar",
                deleteTooltip: "Deletar",
                editTooltip: "Editar",
                emptyDataSourceMessage: "Nenhum apartamento adicionado"
              },
              header: {
                actions: ""
              }
            }}
            editable={{
              onRowDelete: oldData =>
                new Promise(resolve => {
                  setTimeout(() => {
                    setData(data.filter(item => item._id !== oldData._id));
                    resolve();
                  }, 1);
                }),
              onRowAdd: newData =>
                new Promise(resolve => {
                  newData._id = Math.random();
                  newData.idLocker = idLocker;
                  newData._status = "em espera";

                  setData([...data, newData]);

                  resolve();
                }, 1),
              onRowUpdate: (newData, oldData) =>
                new Promise(resolve => {
                  setTimeout(() => {
                    setData(
                      data.filter(
                        item =>
                          item._id === oldData._id
                            ? (item.name = newData.name)
                            : item
                      )
                    );
                    resolve();
                  }, 1);
                })
            }}
            options={{
              paging: false,
              search: false,
              actionsColumnIndex: -1,
              toolbarButtonAlignment: "left"
            }}
          />
          <Button
            variant="contained"
            className={classes.button}
            onClick={fetch}
            disabled={loading || !data.length}
          >
            Criar lista de dados
          </Button>
        </>
      )}
    </Container>
  );
}

export default withStyles(styles)(SubTeamUnitsCreate);
