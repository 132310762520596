import React, { Component } from "react";

// Material
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as CompartmentActions } from "../../store/ducks/compartment";

// Components
import Container from "../../components/Container";
import { materialStyle } from "../../styles";
import CompartmentsTable from "../../components/CompartmentsTable";

class Compartments extends Component {
  state = {
    compartmentNumber: ""
  };

  componentWillMount() {
    const { getCompartmentsRequest } = this.props;

    getCompartmentsRequest({});
  }

  handleChange = key => event => this.setState({ [key]: event.target.value });

  handleSearch = () => {
    const { compartmentNumber } = this.state;
    const { getCompartmentsRequest } = this.props;

    if (!compartmentNumber) return;

    getCompartmentsRequest(`compartmentNumber=${compartmentNumber}`);
  };

  handleClear = () => {
    const { getCompartmentsRequest } = this.props;

    this.setState({ compartmentNumber: "" });
    getCompartmentsRequest("");
  };

  render() {
    const { compartments, classes, history } = this.props;
    const { compartmentNumber } = this.state;

    return (
      <Container title="">
        {compartments.loading ? (
          <CircularProgress className={classes.progress} />
        ) : (
          <>
            <h4>Criar porta</h4>
            <Button
              variant="contained"
              className={classes.button}
              onClick={() => history.push("/compartments/create")}
            >
              Criar
            </Button>

            <TextField
              label="CID"
              variant="outlined"
              id="outlined-input"
              className={classes.textField}
              margin="normal"
              value={compartmentNumber}
              type="number"
              inputProps={{ min: "1" }}
              onChange={this.handleChange("compartmentNumber")}
            />
            <br />
            <Button
              variant="contained"
              className={classes.button}
              onClick={this.handleSearch}
            >
              Pesquisar
            </Button>
            <Button
              variant="contained"
              className={classes.button}
              onClick={this.handleClear}
            >
              Limpar
            </Button>
            <br />
            <CompartmentsTable history={history} />
          </>
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  compartments: state.compartment
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(CompartmentActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(materialStyle)(Compartments));
