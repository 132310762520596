import React, { Component } from "react";

// Material
import { Button } from "@material-ui/core";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as RolesActions } from "../../../store/ducks/roles";

// Global
import Container from "../../../components/Container";
import Spinner from "../../../components/Spinner";
import NotAuthorized from "../../../components/NotAuthorized";

// imports
import history from "../../../routes/history";
import RolesPaginationTable from "../../../components/RolesPaginationTable";

class Roles extends Component {
  componentWillMount() {
    const { getRolesRequest } = this.props;
    getRolesRequest("");
  }

  renderComponent() {
    const { roles } = this.props;

    if (roles.error && roles.error.status === 403) {
      return <NotAuthorized />;
    }

    return (
      <React.Fragment>
        <h4>Regras atuais</h4>
        <br />
        <RolesPaginationTable history={history} />
        <br />
        <br />
        <h4>Nova regra</h4>
        <Button
          variant="contained"
          onClick={() => history.push("/administrator/roles/create")}
          style={{ background: "#4AC151", color: "#fff" }}
        >
          Adicionar
        </Button>
      </React.Fragment>
    );
  }

  render() {
    const { roles } = this.props;

    return (
      <Container title="Regras">
        {roles.loading ? <Spinner /> : this.renderComponent()}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  roles: state.roles
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(RolesActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Roles);
