import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Material
import { withStyles } from "@material-ui/core/styles";

// Global Components
import Container from "../../components/Container";
import Spinner from "../../components/Spinner";
import SubTeamUnitsTable from "../../components/SubTeamsUnitsTable";

// Global Style
import { materialStyle } from "../../styles";

// Redux
import { Actions as SubTeamUnits } from "../../store/ducks/subTeamUnits";
import { Actions as SubTeamActions } from "../../store/ducks/subTeam";

// history
import history from "../../routes/history";

function SubTeamUnitsPage({
  match: {
    params: { idSubTeam }
  },
  classes
}) {
  const dispatch = useDispatch();
  const subTeamUnits = useSelector(state => state.subTeamUnits);
  const subTeams = useSelector(state => state.subTeams);
  const [dashboardParams, setDashboardParams] = useState(null);
  const subTeamUnitsLabel =
    dashboardParams &&
    Object.keys(dashboardParams).includes("subTeamUnitsLabel")
      ? String(dashboardParams.subTeamUnitsLabel)
      : "Bloco";

  useEffect(() => {
    try {
      const parameters = JSON.parse(localStorage.getItem("auth")).currentTeam
        .parameters;

      if (parameters.dashboardParams && parameters.dashboardParams.length) {
        setDashboardParams(JSON.parse(parameters.dashboardParams));
      }
    } catch (err) {}
    dispatch(SubTeamUnits.getSubTeamUnitsRequest(`idSubTeam=${idSubTeam}`));
    dispatch(SubTeamActions.showSubTeamRequest(idSubTeam));
  }, []);

  return (
    <Container title={`${subTeamUnitsLabel}s`}>
      {subTeamUnits.loading ? (
        <Spinner />
      ) : (
        <>
          <h3>{subTeams.currentSubTeam ? subTeams.currentSubTeam.name : ""}</h3>

          <SubTeamUnitsTable
            history={history}
            dashboardParams={dashboardParams}
          />
        </>
      )}
    </Container>
  );
}

export default withStyles(materialStyle)(SubTeamUnitsPage);
