import React, { Component } from "react";
import queryString from "query-string";

// Material
import { TextField, MenuItem, Grid, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

// Global Components
import Container from "../../components/Container";
import SimpleActionButton from "../../components/SimpleActionButton";
import Spinner from "../../components/Spinner";

// Global Style
import { materialStyle } from "../../styles";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as SubTeamActions } from "../../store/ducks/subTeam";
import { Actions as ConfigsActions } from "../../store/ducks/configs";
import { Actions as SubTeamUnitsActions } from "../../store/ducks/subTeamUnits";
import { Actions as SubTeamSubUnitsActions } from "../../store/ducks/subTeamSubUnits";
import { Actions as SubTeamSubUnitsContactActions } from "../../store/ducks/subTeamSubUnitsContact";
import { Actions as LocationActions } from "../../store/ducks/location";

// import Modal from "./components/ModalCreate";
import ModalContactCreate from "./components/ModalContactCreate";
import ModalCreate from "./components/ModalCreate";
import ModalCreateSubTeamSubUnits from "../subTeamUnitsEdit/components/ModalCreate";

import SubTeamSubUnitsContactTable from "../../components/SubTeamSubUnitsContactTable";

// history
import history from "../../routes/history";
import { constants } from "../../config";

class BusinessEdit extends Component {
  state = {
    data: [],
    columns: [{ title: "Bloco", field: "name" }],
    disabled: true,
    currentSubTeam: null,

    parameters: null,
    dashboardParams: null,
    name: "",
    email: "",
    status: "all",
    idSubTeamUnits: "all",
    idSubTeamSubUnits: "all"
  };

  componentWillMount() {
    const {
      showSubTeamRequest,
      setToastVisible,
      getSubTeamSubUnitsContactRequest,
      getSubTeamUnitsRequest
    } = this.props;
    const { id } = this.props.match.params;

    try {
      const parameters = JSON.parse(localStorage.getItem("auth")).currentTeam
        .parameters;

      if (parameters.dashboardParams && parameters.dashboardParams.length) {
        this.setState({
          dashboardParams: JSON.parse(parameters.dashboardParams)
        });
      }
    } catch (err) {}
    setToastVisible({ toastVisible: false });
    showSubTeamRequest(id);
    getSubTeamSubUnitsContactRequest(`idSubTeam=${id}`);
    getSubTeamUnitsRequest(`idSubTeam=${id}&perPage=1000`);
  }

  componentWillReceiveProps(nextProps) {
    const { subTeams, location } = nextProps;

    if (subTeams.currentSubTeam !== this.state.currentSubTeam) {
      this.setState({
        currentSubTeam: subTeams.currentSubTeam,
        snackbarVisible: subTeams.snackbarVisible
      });
    }

    if (location.data && location.data.country) {
      this.setState({
        currentSubTeam: {
          ...this.state.currentSubTeam,
          idCountry: location.data.country.id,
          idState: location.data.state.id,
          idCity: location.data.city.id,
          streetName: location.data.streetName
        }
      });
    }
  }

  handleChange = key => event => {
    const { getSubTeamSubUnitsRequest, resetSubTeamSubUnits } = this.props;
    let value = event.target.value;

    if (key === "idSubTeamUnits" && value === "all") {
      value = "all";
      resetSubTeamSubUnits();
    } else if (key === "idSubTeamUnits") {
      getSubTeamSubUnitsRequest(`idSubTeamUnits=${value}&perPage=1000`);
    }
    this.setState({
      [key]: value
    });
  };

  handleSearch = () => {
    const { getSubTeamSubUnitsContactRequest, subTeams } = this.props;
    const {
      name,
      email,
      status,
      idSubTeamUnits,
      idSubTeamSubUnits
    } = this.state;
    const query = queryString.stringify({
      name,
      email,
      idSubTeam: subTeams.currentSubTeam.id,
      idSubTeamUnits: idSubTeamUnits === "all" ? "" : idSubTeamUnits,
      idSubTeamSubUnits: idSubTeamSubUnits === "all" ? "" : idSubTeamSubUnits,
      status: status === "all" ? "" : status
    });

    getSubTeamSubUnitsContactRequest(query);
  };

  handleClear = () => {
    const { getSubTeamSubUnitsContactRequest, subTeams } = this.props;

    this.setState({
      email: "",
      name: "",
      idSubTeamUnits: "all",
      idSubTeamSubUnits: "all",
      status: "all"
    });

    getSubTeamSubUnitsContactRequest(
      queryString.stringify({
        idSubTeam: subTeams.currentSubTeam.id
      })
    );
  };

  renderContent = () => {
    const { classes, subTeams, subTeamSubUnits, subTeamUnits } = this.props;
    const {
      status,
      dashboardParams,
      name,
      email,
      idSubTeamUnits,
      idSubTeamSubUnits
    } = this.state;

    return (
      <>
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} className="form-colaboradore-sm">
            <TextField
              id="outlined-name"
              label="Nome"
              className={classes.textField}
              value={name}
              onChange={this.handleChange("name")}
              margin="normal"
              variant="outlined"
              disabled={subTeams.loading}
            />
          </Grid>
          <Grid item xs={12} md={3} className="form-colaboradore-sm">
            <TextField
              id="outlined-name"
              label="E-mail"
              className={classes.textField}
              value={email}
              onChange={this.handleChange("email")}
              margin="normal"
              variant="outlined"
              disabled={subTeams.loading}
            />
          </Grid>

          <Grid item xs={12} md={3} className="form-colaboradore-sm">
            <TextField
              disabled={subTeams.loading}
              id="custom-css-outlined-input"
              select
              label="Status"
              className={classes.selectType}
              value={status}
              onChange={this.handleChange("status")}
              SelectProps={{
                MenuProps: {
                  className: classes.menu
                }
              }}
              margin="normal"
              variant="outlined"
            >
              <MenuItem key={"all"} value={"all"}>
                Todos
              </MenuItem>
              {[1, 0].map(item => (
                <MenuItem key={item} value={item}>
                  {item ? "Ativo" : "Desativado"}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} md={3} className="form-colaboradore-sm">
            <TextField
              disabled={subTeams.loading}
              id="custom-css-outlined-input"
              select
              label={
                dashboardParams &&
                Object.keys(dashboardParams).includes("subTeamUnitsLabel")
                  ? dashboardParams.subTeamUnitsLabel
                  : constants.deliveryTableHeaders.subTeamUnitsCondominium
              }
              className={classes.selectType}
              value={idSubTeamUnits}
              onChange={this.handleChange("idSubTeamUnits")}
              SelectProps={{
                MenuProps: {
                  className: classes.menu
                }
              }}
              margin="normal"
              variant="outlined"
            >
              <MenuItem value={"all"}>Todos</MenuItem>
              {subTeamUnits.data.map(item => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} md={3} className="form-colaboradore-sm">
            <TextField
              disabled={subTeamSubUnits.loading || !subTeamSubUnits.data.length}
              id="custom-css-outlined-input"
              select
              label={
                dashboardParams &&
                Object.keys(dashboardParams).includes("subTeamSubUnitsLabel")
                  ? dashboardParams.subTeamSubUnitsLabel
                  : constants.deliveryTableHeaders.subTeamUnitsCondominium
              }
              className={classes.selectType}
              value={idSubTeamSubUnits}
              onChange={this.handleChange("idSubTeamSubUnits")}
              SelectProps={{
                MenuProps: {
                  className: classes.menu
                }
              }}
              margin="normal"
              variant="outlined"
            >
              <MenuItem value={"all"}>Todos</MenuItem>
              {subTeamSubUnits.data.map(item => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>

        <ModalContactCreate
          classes={classes}
          open={this.props.setDialogCreateVisibleSubTeamSubUnitsContact}
          handleClose={() => {
            this.props.setDialogCreateVisibleSubTeamSubUnitsContact(false);
            this.handleClear();
          }}
          subTeamUnitsQuery={`idSubTeam=${
            this.props.match.params.id
          }&perPage=1000`}
        />
        <ModalCreate />
        <ModalCreateSubTeamSubUnits
          subTeamUnitsQuery={`idSubTeam=${
            this.props.match.params.id
          }&perPage=1000`}
          classes={classes}
        />
        <Grid container spacing={1}>
          <Button
            onClick={this.handleSearch}
            variant="contained"
            className={classes.button}
            disabled={subTeams.loading}
            style={constants.buttonDefaultStyle}
          >
            Buscar
          </Button>
          <Button
            onClick={this.handleClear}
            variant="contained"
            className={classes.button}
            disabled={subTeams.loading}
            style={constants.buttonDefaultStyle}
          >
            Limpar
          </Button>
          <SimpleActionButton
            variant="contained"
            options={[
              `Criar ${
                dashboardParams &&
                Object.keys(dashboardParams).includes(
                  "subTeamSubUnitsContactLabel"
                )
                  ? dashboardParams.subTeamSubUnitsContactLabel
                  : "morador"
              }`,
              `Criar ${
                dashboardParams &&
                Object.keys(dashboardParams).includes("subTeamUnitsLabel")
                  ? dashboardParams.subTeamUnitsLabel
                  : "bloco"
              }`,
              `Criar ${
                dashboardParams &&
                Object.keys(dashboardParams).includes("subTeamSubUnitsLabel")
                  ? dashboardParams.subTeamSubUnitsLabel
                  : "apartamento"
              }`,
              "Criar dados em massa",
              `Ver ${
                dashboardParams &&
                Object.keys(dashboardParams).includes("subTeamUnitsLabel")
                  ? dashboardParams.subTeamUnitsLabel
                  : "bloco"
              }s`
            ]}
            actions={[
              () =>
                this.props.setDialogCreateVisibleSubTeamSubUnitsContact(true),

              () => {
                this.props.setDialogCreateVisible(true);
              },
              () => {
                this.props.setDialogCreateVisibleSubTeamSubUnits(true);
              },
              () => {
                history.push(
                  `/subTeam/wizard?idSubTeam=${this.props.match.params.id}`
                );
              },
              () => {
                history.push(`/subTeamUnits/${this.props.match.params.id}`);
              }
            ]}
            label={`Criar um ${
              dashboardParams &&
              Object.keys(dashboardParams).includes(
                "subTeamSubUnitsContactLabel"
              )
                ? dashboardParams.subTeamSubUnitsContactLabel
                : "morador"
            }`}
          />
        </Grid>

        <SubTeamSubUnitsContactTable history={history} />
      </>
    );
  };

  render() {
    const { subTeamSubUnitsContact } = this.props;
    const { currentSubTeam } = this.state;

    return (
      <Container title="">
        {subTeamSubUnitsContact.loading ? (
          <Spinner />
        ) : (
          <>{currentSubTeam && this.renderContent()}</>
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  ...state
});

const mapDispathToProps = dispatch =>
  bindActionCreators(
    {
      ...SubTeamActions,
      ...ConfigsActions,
      ...LocationActions,
      ...SubTeamUnitsActions,
      ...SubTeamSubUnitsActions,
      ...SubTeamSubUnitsContactActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispathToProps
)(withStyles(materialStyle)(BusinessEdit));
