import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { MenuItem, TextField, withStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { colors, materialStyle } from "../../styles";

const ButtonStyle = {
  // para os Buttons
  backgroundColor: colors.primary,
  fontSize: 14,
  color: "#fff",
  textTransform: "lowercase",
  height: 35
};

const ButtonStyleDisabled = {
  // para os Buttons
  backgroundColor: colors.dark1,
  fontSize: 14,
  color: "#fff",
  textTransform: "lowercase",
  height: 35
};

function CustomDialogCancelDelivery({
  disabled,
  className,
  buttonLabel,
  title,
  message,
  subMessage,
  finalMessage,
  cancelButtonText,
  confirmButtonText,
  onConfirm,
  noButton,
  customOpen,
  handleCustomOpenCancel,
  useCustomOpen,
  classes,
  onChangePageDeliveryUpdateInfoSubType,
  onOpenPort
}) {
  const [open, setOpen] = React.useState(false);
  const deliveryUpdateInfoSubType = useSelector(
    state => state.deliveryUpdateInfoSubType
  );
  const [
    idDeliveryUpdateInfoSubType,
    setidDeliveryUpdateInfoSubType
  ] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (useCustomOpen) {
      handleCustomOpenCancel();
    } else {
      setOpen(false);
    }
  };

  return (
    <>
      {!noButton && (
        <Button
          style={ButtonStyle}
          disabled={disabled}
          className={className || null}
          variant="contained"
          onClick={handleClickOpen}
        >
          {buttonLabel || "DEFAULT"}
        </Button>
      )}
      <Dialog
        open={useCustomOpen ? customOpen : open}
        onClose={useCustomOpen ? handleCustomOpenCancel : handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message || "DEFAULT"}
          </DialogContentText>
        </DialogContent>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {subMessage || ""}
          </DialogContentText>
        </DialogContent>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {finalMessage || ""}
          </DialogContentText>

          <TextField
            id="custom-css-outlined-input"
            select
            label="Selecione um motivo"
            className={classes.textFieldLocker1}
            value={idDeliveryUpdateInfoSubType}
            onChange={e => {
              onChangePageDeliveryUpdateInfoSubType(e.target.value);
              setidDeliveryUpdateInfoSubType(e.target.value);
            }}
            SelectProps={{
              MenuProps: {
                className: classes.menu
              }
            }}
            margin="normal"
            variant="outlined"
          >
            {deliveryUpdateInfoSubType.data.map(item => (
              <MenuItem key={item.id} value={item.id}>
                {item.description}
              </MenuItem>
            ))}
          </TextField>
        </DialogContent>

        <DialogActions>
          <Button onClick={onOpenPort} color="primary" style={ButtonStyle}>
            Abrir porta
          </Button>
          <Button onClick={handleClose} color="primary" style={ButtonStyle}>
            {cancelButtonText || "não"}
          </Button>

          <Button
            disabled={!idDeliveryUpdateInfoSubType}
            onClick={() => {
              handleClose();

              if (typeof onConfirm !== "undefined") {
                onConfirm();
              }
            }}
            color="primary"
            style={
              !idDeliveryUpdateInfoSubType ? ButtonStyleDisabled : ButtonStyle
            }
          >
            {confirmButtonText || "sim"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default withStyles(materialStyle)(CustomDialogCancelDelivery);
