export default function formatMobile(cpf) {
  // Remove todos os caracteres não numéricos
  let numericCPF = cpf.replace(/\D/g, "");

  if (String(numericCPF).startsWith("55")) {
    numericCPF = String(numericCPF).substring(2);
  }

  // Aplica a formatação para os dígitos já inseridos
  return numericCPF.replace(
    /(\d{1,2})(\d{1,5})?(\d{1,4})?/,
    (_, p1, p2, p3) => {
      let formatted = p1;

      if (p2) formatted += ` ${p2}`;
      if (p3) formatted += `-${p3}`;

      return formatted;
    }
  );
}
