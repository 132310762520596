import React, { Component } from "react";

// Material
import { TextField, Button, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Cancel as CancelIcon, Save as SaveIcon } from "@material-ui/icons";

import TextMaskCustomMobile from "../../components/TextMaskCustomMobile";

// Global Components
import Container from "../../components/Container";

// Global Style
import { materialStyle } from "../../styles";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as SubTeamSubUnitsContactActions } from "../../store/ducks/subTeamSubUnitsContact";

import getOnlyNumbers from "../../services/getOnlyNumbers";

import history from "../../routes/history";

class subTeamSubUnitsContactCreate extends Component {
  state = {
    currentSubTeamSubUnitsContact: {
      name: "",
      email: "",
      phone: ""
    },
    edit: false
  };

  handleChange = key => event => {
    let value = event.target.value;

    if (key === "phone") {
      value = getOnlyNumbers(event.target.value);
    }

    this.setState({
      currentSubTeamSubUnitsContact: {
        ...this.state.currentSubTeamSubUnitsContact,
        [key]: value
      }
    });
  };

  handleSave = () => {
    const { createSubTeamSubUnitsContactRequest } = this.props;
    const { currentSubTeamSubUnitsContact } = this.state;
    const { idSubTeamSubUnits } = this.props.location.state;

    createSubTeamSubUnitsContactRequest({
      ...currentSubTeamSubUnitsContact,
      idSubTeamSubUnits
    });
  };

  render() {
    const { subTeamSubUnitsContact, classes } = this.props;
    const { currentSubTeamSubUnitsContact } = this.state;

    return (
      <Container title="">
        <h3>Criar um morador</h3>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} className="form-colaboradore-sm">
            <TextField
              id="outlined-name"
              label="Nome do morador"
              className={classes.textField}
              value={currentSubTeamSubUnitsContact.name}
              onChange={this.handleChange("name")}
              margin="normal"
              variant="outlined"
              disabled={subTeamSubUnitsContact.loading}
            />
          </Grid>
          <Grid item xs={12} md={3} className="form-colaboradore-sm">
            <TextField
              id="outlined-name"
              label="E-mail do morador"
              type="email"
              className={classes.textField}
              value={currentSubTeamSubUnitsContact.email}
              onChange={this.handleChange("email")}
              margin="normal"
              variant="outlined"
              disabled={subTeamSubUnitsContact.loading}
            />
          </Grid>
          <Grid item xs={12} md={3} className="form-colaboradore-sm">
            <TextField
              id="outlined-name"
              label="Celular do morador"
              className={classes.textField}
              value={currentSubTeamSubUnitsContact.phone}
              onChange={this.handleChange("phone")}
              margin="normal"
              variant="outlined"
              disabled={subTeamSubUnitsContact.loading}
              InputProps={{
                inputComponent: TextMaskCustomMobile
              }}
            />
          </Grid>
        </Grid>
        <Button
          variant="contained"
          onClick={this.handleSave}
          disabled={subTeamSubUnitsContact.loading}
          className={classes.button}
        >
          <SaveIcon className={classes.leftIcon} />
          Criar
        </Button>
        <Button
          variant="contained"
          onClick={() => history.goBack()}
          disabled={subTeamSubUnitsContact.loading}
          className={classes.button}
        >
          <CancelIcon className={classes.leftIcon} />
          Cancelar
        </Button>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  subTeamSubUnitsContact: state.subTeamSubUnitsContact
});

const mapDispathToProps = dispatch =>
  bindActionCreators(
    {
      ...SubTeamSubUnitsContactActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispathToProps
)(withStyles(materialStyle)(subTeamSubUnitsContactCreate));
