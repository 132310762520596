import React, { Component } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import TableHead from "@material-ui/core/TableHead";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as OrderActions } from "../../store/ducks/orders";

// services
import formatPrice from "../../utils/formatPrice";
import formatDate from "../../functions/formatDate";
import defaultConfig from "../../config/default";

const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5)
  }
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  function handleFirstPageButtonClick(event) {
    onChangePage(event, 0);
  }

  function handleBackButtonClick(event) {
    onChangePage(event, page - 1);
  }

  function handleNextButtonClick(event) {
    onChangePage(event, page + 1);
  }

  function handleLastPageButtonClick(event) {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  }

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
};

class DeliveryPaginationTable extends Component {
  state = {
    emptyRows: 0,
    page: 1,
    rowsPerPage: 10
  };

  handleChangePage = (event, newPage) => {
    const { getActiveOrdersRequest, getQuerySearch, orders } = this.props;

    const tmpQuery = `&page=${newPage + 1}&perPage=${orders.perPage}`;

    getActiveOrdersRequest(getQuerySearch() + tmpQuery);
  };

  handleChangeRowsPerPage = event => {
    const rowsPerPage = parseInt(event.target.value);

    this.setState({ rowsPerPage });

    const { getActiveOrdersRequest, getQuerySearch } = this.props;

    getActiveOrdersRequest(getQuerySearch() + `&perPage=${rowsPerPage}`);
  };

  useStyles2 = () =>
    makeStyles(theme => ({
      root: {
        width: "100%",
        marginTop: theme.spacing(3)
      },
      table: {
        minWidth: 500
      },
      tableWrapper: {
        overflowX: "auto"
      }
    }));

  render() {
    const classes = this.useStyles2();
    const { orders } = this.props;

    const { emptyRows } = this.state;

    return (
      <>
        <Paper className={classes.root}>
          <div className={classes.tableWrapper}>
            <Table className={classes.table}>
              <TableHead className="tableheader">
                <TableRow align="left">
                  <TableCell
                    className=" th-delivery tablecell-header"
                    align="left"
                  >
                    <p
                      style={{ color: "#006660", fontSize: "18px", margin: 0 }}
                    >
                      data inicial
                    </p>
                  </TableCell>
                  <TableCell
                    className="tablecell-header th-delivery"
                    align="left"
                  >
                    <p
                      style={{ color: "#006660", fontSize: "18px", margin: 0 }}
                    >
                      tempo alugado
                    </p>
                  </TableCell>
                  <TableCell
                    className="th-delivery tablecell-header"
                    align="left"
                  >
                    <p
                      style={{ color: "#006660", fontSize: "18px", margin: 0 }}
                    >
                      locker
                    </p>
                  </TableCell>
                  <TableCell
                    className="th-delivery tablecell-header"
                    align="left"
                  >
                    <p
                      style={{ color: "#006660", fontSize: "18px", margin: 0 }}
                    >
                      porta
                    </p>
                  </TableCell>
                  <TableCell
                    className="th-delivery tablecell-header"
                    align="left"
                  >
                    <p
                      style={{ color: "#006660", fontSize: "18px", margin: 0 }}
                    >
                      usuário
                    </p>
                  </TableCell>
                  <TableCell
                    className="th-delivery tablecell-header"
                    align="left"
                  >
                    <p
                      style={{ color: "#006660", fontSize: "18px", margin: 0 }}
                    >
                      total
                    </p>
                  </TableCell>
                  <TableCell
                    className="th-delivery tablecell-header"
                    align="left"
                  >
                    <p
                      style={{ color: "#006660", fontSize: "18px", margin: 0 }}
                    >
                      bônus usado
                    </p>
                  </TableCell>
                  <TableCell
                    className="th-delivery tablecell-header"
                    align="left"
                  >
                    <p
                      style={{ color: "#006660", fontSize: "18px", margin: 0 }}
                    >
                      carteira usada
                    </p>
                  </TableCell>
                  <TableCell
                    className="th-delivery tablecell-header"
                    align="left"
                  >
                    <p
                      style={{ color: "#006660", fontSize: "18px", margin: 0 }}
                    >
                      cupom usado
                    </p>
                  </TableCell>
                  <TableCell
                    className="th-delivery tablecell-header"
                    align="left"
                  >
                    <p
                      style={{ color: "#006660", fontSize: "18px", margin: 0 }}
                    >
                      valor devedor pelo cliente
                    </p>
                  </TableCell>
                  <TableCell
                    className="th-delivery tablecell-header"
                    align="left"
                  >
                    <p
                      style={{ color: "#006660", fontSize: "18px", margin: 0 }}
                    >
                      taxa
                    </p>
                  </TableCell>
                  <TableCell className="tablecell-header" align="left">
                    <p
                      style={{ color: "#006660", fontSize: "18px", margin: 0 }}
                    >
                      receita
                    </p>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.data &&
                  orders.data.map(row => (
                    <TableRow key={row.id}>
                      <TableCell
                        className="td-max td-delivery tablecell"
                        align="left"
                      >
                        {formatDate(row.createdAt)}
                      </TableCell>

                      <TableCell className="tablecell td-delivery" align="left">
                        {row.boughtHours} hrs
                      </TableCell>
                      <TableCell
                        className="td-max td-delivery tablecell"
                        align="left"
                      >
                        {row.locker.address}
                      </TableCell>
                      <TableCell
                        className="td-max td-delivery tablecell"
                        align="left"
                      >
                        {row.compartment.compartmentNumber}
                      </TableCell>
                      <TableCell
                        className="td-max td-delivery tablecell"
                        align="left"
                      >
                        {`${
                          row.toUser.name !== ""
                            ? row.toUser.name.slice(0, 1) + "***"
                            : ""
                        }`}{" "}
                        {`${
                          row.toUser.lastName
                            ? row.toUser.lastName.slice(0, 1) + "***"
                            : ""
                        }`}
                        {/* {row.toUser.name} - {row.toUser.lastName} -{" "}
                        {row.toUser.cpf} */}
                      </TableCell>

                      <TableCell
                        className="td-max td-delivery tablecell"
                        align="left"
                      >
                        {formatPrice(row.totalCost)}
                      </TableCell>

                      <TableCell
                        className="td-max td-delivery tablecell"
                        align="left"
                      >
                        {row.fee ? formatPrice(row.fee.used_bonus) : ""}
                      </TableCell>

                      <TableCell
                        className="td-max td-delivery tablecell"
                        align="left"
                      >
                        {row.fee ? formatPrice(row.fee.used_wallet) : ""}
                      </TableCell>

                      <TableCell
                        className="td-max td-delivery tablecell"
                        align="left"
                      >
                        {row.userCreditTicket
                          ? formatPrice(row.userCreditTicket.creditTicket.value)
                          : "-"}
                      </TableCell>

                      <TableCell
                        className="td-max td-delivery tablecell"
                        align="left"
                      >
                        {row.fee ? formatPrice(row.fee.balance_due) : ""}
                      </TableCell>

                      <TableCell
                        className="td-max td-delivery tablecell"
                        align="left"
                      >
                        {row.fee ? formatPrice(row.fee.handover_fee) : ""}
                      </TableCell>
                      <TableCell
                        className="td-max td-delivery tablecell"
                        align="left"
                      >
                        {row.fee ? formatPrice(row.fee.licensed_revenue) : ""}
                      </TableCell>
                    </TableRow>
                  ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 48 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              count={orders.total}
              rowsPerPage={orders.perPage}
              page={orders.page - 1}
              SelectProps={{
                inputProps: { "aria-label": "rows per page" },
                native: true
              }}
              labelRowsPerPage="Linhas por página"
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to === -1 ? count : to} de ${count}`
              }
              rowsPerPageOptions={defaultConfig.rowsPerPageOptions}
              component="div"
            />
          </div>
        </Paper>
      </>
    );
  }
}
const mapStateToProps = state => ({
  orders: state.orders
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...OrderActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeliveryPaginationTable);
