import React, { Component } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import TableHead from "@material-ui/core/TableHead";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as LockerActions } from "../../store/ducks/locker";

import formatDate from "../../functions/formatDate";

const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5)
  }
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  function handleFirstPageButtonClick(event) {
    onChangePage(event, 0);
  }

  function handleBackButtonClick(event) {
    onChangePage(event, page - 1);
  }

  function handleNextButtonClick(event) {
    onChangePage(event, page + 1);
  }

  function handleLastPageButtonClick(event) {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  }

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
};

class LockerSetupPasswordsTable extends Component {
  state = {
    emptyRows: 0,
    page: 1,
    rowsPerPage: 5
  };

  handleChangePage = (event, newPage) => {
    const { lockersRequest } = this.props;
    const { perPage } = this.state;

    lockersRequest({
      page: newPage + 1,
      perPage
    });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: parseInt(event.target.value) });

    const { lockersRequest } = this.props;

    lockersRequest({
      perPage: parseInt(event.target.value)
    });
  };

  useStyles2 = () =>
    makeStyles(theme => ({
      root: {
        width: "100%",
        marginTop: theme.spacing(3)
      },
      table: {
        minWidth: 500
      },
      tableWrapper: {
        overflowX: "auto"
      }
    }));
  render() {
    const classes = this.useStyles2();
    const { lockers } = this.props;

    const { emptyRows } = this.state;

    return (
      <Paper className={classes.root} id="table-locker-sm">
        <div className={classes.tableWrapper}>
          <Table className={classes.table}>
            <TableHead className={classes.rowTitles}>
              <TableRow align="center">
                <TableCell
                  className="tablecell-header th-delivery"
                  align="center"
                >
                  <p style={{ color: "#006660", fontSize: 18, margin: 0 }}>
                    Senha
                  </p>
                </TableCell>
                <TableCell
                  className="tablecell-header th-delivery"
                  align="center"
                >
                  <p style={{ color: "#006660", fontSize: 18, margin: 0 }}>
                    Data expiração
                  </p>
                </TableCell>
                <TableCell
                  className="tablecell-header th-delivery"
                  align="center"
                >
                  <p style={{ color: "#006660", fontSize: 18, margin: 0 }}>
                    Data criação
                  </p>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {lockers.setupPasswords &&
                lockers.setupPasswords.map(row => (
                  <TableRow key={row.id} className={classes.tableContent}>
                    <TableCell
                      className="tablecell  td-delivery"
                      align="center"
                    >
                      {row.password}
                    </TableCell>
                    <TableCell className="tablecell td-delivery" align="center">
                      {row.endTime_at}
                    </TableCell>
                    <TableCell className="tablecell td-delivery" align="center">
                      {formatDate(row.created_at)}
                    </TableCell>
                  </TableRow>
                ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 48 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </Paper>
    );
  }
}
const mapStateToProps = state => ({
  lockers: state.locker
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...LockerActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LockerSetupPasswordsTable);
