import React, { Component } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import TableHead from "@material-ui/core/TableHead";
import SimpleActionButton from "../SimpleActionButton";
import queryString from "query-string";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as RentalActions } from "../../store/ducks/rental";

// services
import formatDate from "../../functions/formatDate";
import { constants } from "../../config";
import defaultConfig from "../../config/default";
import formatCPF from "../../utils/formatCPF";

const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5)
  }
}));
function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  function handleFirstPageButtonClick(event) {
    onChangePage(event, 0);
  }

  function handleBackButtonClick(event) {
    onChangePage(event, page - 1);
  }

  function handleNextButtonClick(event) {
    onChangePage(event, page + 1);
  }

  function handleLastPageButtonClick(event) {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  }

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}
TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
};
class RentalPaginationTable extends Component {
  state = {
    emptyRows: 0,
    page: 1,
    rowsPerPage: 10,
    parameters: null,
    dashboardParams: null,
    actionOptions: [
      constants.tableActionOptions.show,
      constants.tableActionOptions.edit,
      constants.tableActionOptions.cancel
    ],
    openDialogCancel: false
  };

  componentWillMount() {
    const parameters = JSON.parse(localStorage.getItem("auth")).currentTeam
      .parameters;

    const data = { parameters };

    if (parameters.dashboardParams && parameters.dashboardParams.length) {
      Object.assign(data, {
        dashboardParams: JSON.parse(parameters.dashboardParams)
      });
    }

    this.setState(data);
  }

  handleChangePage = (event, newPage) => {
    const { getRentalRequest, search, perPage } = this.props;

    getRentalRequest(`${search()}&page=${newPage + 1}&perPage=${perPage()}`);
  };

  handleChangeRowsPerPage = event => {
    const rowsPerPage = parseInt(event.target.value);
    const { onChangePerPage, search, getRentalRequest, history } = this.props;

    onChangePerPage(rowsPerPage);

    const parsed = queryString.parse(search());
    delete parsed.perPage;

    const _search = `${queryString.stringify(parsed)}&perPage=${rowsPerPage}`;

    this.setState({ rowsPerPage });

    getRentalRequest(_search);
    history.push(`/rental?${_search}`);
  };
  useStyles2 = () =>
    makeStyles(theme => ({
      root: {
        width: "100%",
        marginTop: theme.spacing(3)
      },
      table: {
        minWidth: 500
      },
      tableWrapper: {
        overflowX: "auto"
      }
    }));
  checkIfExistsSubTeam = () => {
    const { rental } = this.props;
    if (!rental || !rental.data || !rental.data.length) return false;
    for (let i = 0; i < rental.data.length; i++) {
      if (rental.data[i].subTeam) return true;
    }
    return false;
  };
  render() {
    const classes = this.useStyles2();
    const { rental, history } = this.props;
    const { emptyRows } = this.state;

    return (
      <Paper className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table className={classes.table}>
            <TableHead className="tableheader">
              <TableRow align="left">
                <TableCell align="left">
                  <p id="table_Cell">usuário</p>
                </TableCell>
                <TableCell align="left">
                  <p id="table_Cell">locker</p>
                </TableCell>
                <TableCell align="left">
                  <p id="table_Cell">porta</p>
                </TableCell>
                <TableCell align="left">
                  <p id="table_Cell">data início</p>
                </TableCell>
                <TableCell align="left">
                  <p id="table_Cell">finalizada em</p>
                </TableCell>
                <TableCell align="left">
                  <p id="table_Cell">status</p>
                </TableCell>
                <TableCell align="left">
                  <p id="table_Cell">AÇÕES</p>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rental.data &&
                rental.data.map(row => (
                  <TableRow key={row.id}>
                    <TableCell align="left">
                      {row.user && (
                        <p id="table_Cell_Col">
                          {row.user.name}
                          <br />
                          {row.user.email}
                          <br />
                          {formatCPF(row.user.cpf)}
                        </p>
                      )}
                    </TableCell>
                    <TableCell align="left">
                      <p id="table_Cell_Col">{row.locker.address}</p>
                    </TableCell>

                    <TableCell align="left">
                      <p id="table_Cell_Col">
                        {row.compartment.compartmentNumber}
                      </p>
                    </TableCell>

                    <TableCell align="left">
                      <p id="table_Cell_Col">{formatDate(row.created_at)}</p>
                    </TableCell>
                    <TableCell align="left">
                      <p id="table_Cell_Col">
                        {row.finishedAt ? formatDate(row.finishedAt) : "-"}
                      </p>
                    </TableCell>
                    <TableCell align="left">
                      <p id="table_Cell_Col">
                        {!row.finishedAt ? "Ativa" : "Finalizada"}
                      </p>
                    </TableCell>

                    <TableCell align="center" id="table-left">
                      <SimpleActionButton
                        dataFatherIsActive={
                          parseInt(row.status) !== 3 &&
                          parseInt(row.status) !== 6 &&
                          parseInt(row.status) !== 7
                        }
                        id={Math.random()}
                        actions={[() => history.push(`/rental/edit/${row.id}`)]}
                        options={["Visualizar"]}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 48 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            count={rental.total}
            rowsPerPage={rental.perPage}
            page={rental.page - 1}
            SelectProps={{
              inputProps: { "aria-label": "rows per page" },
              native: true
            }}
            labelRowsPerPage="Linhas por página"
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to === -1 ? count : to} de ${count}`
            }
            rowsPerPageOptions={defaultConfig.rowsPerPageOptions}
            component="div"
          />
        </div>
      </Paper>
    );
  }
}
const mapStateToProps = state => ({
  rental: state.rental
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...RentalActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RentalPaginationTable);
