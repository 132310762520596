import React, { Component } from "react";

// Global Components
import Container from "../../components/Container";
import { materialStyle } from "../../styles";

// Material
import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

//  Redux

// Services
import history from "../../routes/history";

class Administrator extends Component {
  render() {
    const { classes } = this.props;

    return (
      <Container title="Administrativo">
        <Button
          className={classes.button}
          variant="contained"
          onClick={() => history.push("/administrator/roles")}
        >
          Regras
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          onClick={() => history.push("/administrator/permissions")}
        >
          Permissões
        </Button>
      </Container>
    );
  }
}

export default withStyles(materialStyle)(Administrator);
