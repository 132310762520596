import React, { Component } from "react";

// Material
import { TextField, Button, Grid, MenuItem } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Spinner from "../../components/Spinner";
import {
  Cancel as CancelIcon,
  Edit as EditIcon,
  Autorenew,
  DeleteForever
} from "@material-ui/icons";
import TextMaskCustomMobile from "../../components/TextMaskCustomMobile";

// Global Components
import Container from "../../components/Container";

// Global Style
import { materialStyle } from "../../styles";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as SubTeamSubUnitsContactActions } from "../../store/ducks/subTeamSubUnitsContact";

import getOnlyNumbers from "../../services/getOnlyNumbers";

import AlertDialog from "../../components/AlertDialog";
import EditorCurrentRegisterContainer from "../../components/EditorCurrentRegisterContainer";
import ButtonEditorCurrentRegister from "../../components/ButtonEditorCurrentRegister";
import MultiEmailInput from "../../components/MultiEmailInput";

class subTeamSubUnitsContactEdit extends Component {
  state = {
    currentSubTeamSubUnitsContact: {
      name: "",
      email: "",
      phone: "",
      cpf: "",
      slackChannelID: "",
      status: 0
    },
    edit: false,
    modalVisible: false,
    dashboardParams: null,
    useSlack: false
  };

  componentWillMount() {
    const { showSubTeamSubUnitsContactRequest } = this.props;
    const { id } = this.props.match.params;

    showSubTeamSubUnitsContactRequest(id);

    try {
      const parameters = JSON.parse(localStorage.getItem("auth")).currentTeam
        .parameters;

      if (parameters.dashboardParams && parameters.dashboardParams.length) {
        this.setState({
          dashboardParams: JSON.parse(parameters.dashboardParams)
        });
      }

      this.setState({
        useSlack: Boolean(parameters.sendSlackToCollect)
      });
    } catch (err) {}
  }

  componentWillReceiveProps(nextProps) {
    const { subTeamSubUnitsContact } = nextProps;
    if (
      subTeamSubUnitsContact.currentSubTeamSubUnitsContact !==
      this.state.currentSubTeamSubUnitsContact
    ) {
      this.setState({
        currentSubTeamSubUnitsContact:
          subTeamSubUnitsContact.currentSubTeamSubUnitsContact,
        edit: subTeamSubUnitsContact.error ? true : false
      });
    } else {
      this.setState({ edit: false });
    }
  }

  handleChange = key => event => {
    let value = event.target.value;

    if (key === "phone") {
      value = getOnlyNumbers(event.target.value);
    }

    if (key === "cpf") {
      value = getOnlyNumbers(event.target.value);
    }

    this.setState({
      currentSubTeamSubUnitsContact: {
        ...this.state.currentSubTeamSubUnitsContact,
        [key]: value
      }
    });
  };

  handleSave = () => {
    const { updateSubTeamSubUnitsContactRequest } = this.props;
    const {
      currentSubTeamSubUnitsContact: {
        name,
        email,
        phone,
        subTeamSubUnits,
        id,
        cpf,
        slackChannelID
      }
    } = this.state;

    updateSubTeamSubUnitsContactRequest({
      id,
      name,
      email,
      phone,
      cpf,
      idSubTeamSubUnits: subTeamSubUnits.id,
      slackChannelID
    });
  };

  handleStatus = () => {
    const { changeStatusSubTeamSubUnitsContactRequest } = this.props;
    const { currentSubTeamSubUnitsContact } = this.state;
    changeStatusSubTeamSubUnitsContactRequest(
      currentSubTeamSubUnitsContact.id,
      !currentSubTeamSubUnitsContact.status
    );
  };

  render() {
    const { subTeamSubUnitsContact, classes } = this.props;

    const {
      currentSubTeamSubUnitsContact,
      modalVisible,
      dashboardParams
    } = this.state;

    return (
      <>
        {subTeamSubUnitsContact.loading ? (
          <Container title="">
            <Spinner />
          </Container>
        ) : (
          <Container title="">
            <h3>
              Editar{" "}
              {dashboardParams &&
              Object.keys(dashboardParams).includes(
                "subTeamSubUnitsContactLabel"
              )
                ? dashboardParams.subTeamSubUnitsContactLabel
                : "morador"}
            </h3>

            <EditorCurrentRegisterContainer
              disabled={this.state.edit}
              flexDirection="row-reverse"
            >
              <ButtonEditorCurrentRegister
                label="Editar"
                onClick={() => this.setState({ edit: true })}
              />
            </EditorCurrentRegisterContainer>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3} className="form-colaboradore-sm">
                <TextField
                  id="outlined-name"
                  label="Nome do morador"
                  className={classes.textField}
                  value={currentSubTeamSubUnitsContact.name}
                  onChange={this.handleChange("name")}
                  margin="normal"
                  variant="outlined"
                  disabled={subTeamSubUnitsContact.loading || !this.state.edit}
                />
              </Grid>
              {this.state.useSlack && (
                <Grid item xs={12} md={3} className="form-colaboradore-sm">
                  <TextField
                    id="outlined-name"
                    label="ID de membro do slack"
                    className={classes.textField}
                    value={currentSubTeamSubUnitsContact.slackChannelID}
                    onChange={this.handleChange("slackChannelID")}
                    margin="normal"
                    variant="outlined"
                    disabled={
                      subTeamSubUnitsContact.loading || !this.state.edit
                    }
                  />
                </Grid>
              )}
              <Grid item xs={12} md={3} className="form-colaboradore-sm">
                <TextField
                  id="outlined-name"
                  label="Telefone do morador"
                  className={classes.textField}
                  value={currentSubTeamSubUnitsContact.phone}
                  onChange={this.handleChange("phone")}
                  margin="normal"
                  variant="outlined"
                  disabled={subTeamSubUnitsContact.loading || !this.state.edit}
                  InputProps={{
                    inputComponent: TextMaskCustomMobile
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3} className="form-colaboradore-sm">
                <TextField
                  select
                  id="outlined-name"
                  label="Status"
                  className={classes.textField}
                  value={currentSubTeamSubUnitsContact.status}
                  margin="normal"
                  variant="outlined"
                  disabled={true}
                >
                  {[1, 0].map(item => (
                    <MenuItem key={item} value={item}>
                      {item ? "Ativo" : "Desativado"}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} md={10} className="form-colaboradore-sm">
                <MultiEmailInput
                  disabled={!this.state.edit}
                  defaultValue={currentSubTeamSubUnitsContact.email}
                  extractEmails={e => {
                    this.setState({
                      currentSubTeamSubUnitsContact: {
                        ...this.state.currentSubTeamSubUnitsContact,
                        email: e
                      }
                    });
                  }}
                />
              </Grid>
            </Grid>

            {this.state.edit && (
              <>
                <Button
                  variant="contained"
                  onClick={this.handleSave}
                  disabled={subTeamSubUnitsContact.loading}
                  className={classes.button}
                >
                  <EditIcon className={classes.leftIcon} />
                  Salvar
                </Button>
                <Button
                  variant="contained"
                  onClick={() => this.setState({ edit: false })}
                  disabled={subTeamSubUnitsContact.loading}
                  className={classes.button}
                >
                  <CancelIcon className={classes.leftIcon} />
                  Cancelar
                </Button>
                <Button
                  variant="contained"
                  onClick={() => this.setState({ modalVisible: true })}
                  disabled={subTeamSubUnitsContact.loading}
                  className={classes.button}
                >
                  {currentSubTeamSubUnitsContact.status ? (
                    <DeleteForever className={classes.leftIcon} />
                  ) : (
                    <Autorenew className={classes.leftIcon} />
                  )}
                  {currentSubTeamSubUnitsContact.status
                    ? "Desativar"
                    : "Ativar"}
                </Button>
              </>
            )}
            <AlertDialog
              open={modalVisible}
              className={classes.button}
              title="Atenção"
              cancelButtonText="Cancelar"
              confirmButtonText="Sim"
              message={`Deseja realmente ${
                currentSubTeamSubUnitsContact.status ? "desativar" : "ativar"
              } este morador?`}
              onConfirm={this.handleStatus}
              disabled={false}
              handleClose={() =>
                this.setState({
                  modalVisible: false
                })
              }
            />
          </Container>
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  subTeamSubUnitsContact: state.subTeamSubUnitsContact
});

const mapDispathToProps = dispatch =>
  bindActionCreators(
    {
      ...SubTeamSubUnitsContactActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispathToProps
)(withStyles(materialStyle)(subTeamSubUnitsContactEdit));
