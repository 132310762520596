import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// Material
import { TextField, Grid, Button, MenuItem } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Autorenew, DeleteForever } from "@material-ui/icons";

// Global Components
import Container from "../../components/Container";
import Spinner from "../../components/Spinner";
import SubTeamSubUnitsTable from "../../components/SubTeamSubUnitsTable";

// Global Style
import { materialStyle } from "../../styles";

// Redux
import { Actions as SubTeamSubUnitsActions } from "../../store/ducks/subTeamSubUnits";
import { Actions as SubTeamUnits } from "../../store/ducks/subTeamUnits";

// history
import history from "../../routes/history";

import EditorCurrentRegisterContainer from "../../components/EditorCurrentRegisterContainer";
import ButtonEditorCurrentRegister from "../../components/ButtonEditorCurrentRegister";
import ModalEdit from "../../components/ModalEdit";
import AlertDialog from "../../components/AlertDialog";

function SubTeamUnitsEdit({
  match: {
    params: { id }
  },
  classes
}) {
  const dispatch = useDispatch();
  const subTeamUnits = useSelector(state => state.subTeamUnits);
  const [editableField, setEditableField] = useState(false);
  const [dashboardParams, setDashboardParams] = useState(null);
  const [_currentSubTeamUnits, setCurrentSubTeamUnits] = useState();
  const [modalVisible, setMdalVisible] = useState(false);
  const subTeamUnitsLabel =
    dashboardParams &&
    Object.keys(dashboardParams).includes("subTeamUnitsLabel")
      ? String(dashboardParams.subTeamUnitsLabel)
      : "Bloco";

  useEffect(() => {
    try {
      const parameters = JSON.parse(localStorage.getItem("auth")).currentTeam
        .parameters;

      if (parameters.dashboardParams && parameters.dashboardParams.length) {
        setDashboardParams(JSON.parse(parameters.dashboardParams));
      }
    } catch (err) {}

    dispatch(SubTeamUnits.showSubTeamUnitsRequest(id));
    dispatch(
      SubTeamSubUnitsActions.getSubTeamSubUnitsRequest(
        `perPage=1000&idSubTeamUnits=${id}`
      )
    );
  }, []);

  useEffect(
    () => {
      const { currentSubTeamUnits } = subTeamUnits;

      if (currentSubTeamUnits) {
        setCurrentSubTeamUnits(currentSubTeamUnits);
      }
    },
    [subTeamUnits]
  );

  return (
    <Container title={`Editar ${subTeamUnitsLabel.toLowerCase()}`}>
      {subTeamUnits.loading ? (
        <Spinner />
      ) : (
        <>
          <EditorCurrentRegisterContainer
            disabled={editableField}
            flexDirection="row-reverse"
          >
            <ButtonEditorCurrentRegister
              label="Editar"
              onClick={() => setEditableField(true)}
            />
          </EditorCurrentRegisterContainer>
          <h3>Informações</h3>
          <ModalEdit
            open={editableField}
            onClose={() => {
              setEditableField(false);
              setCurrentSubTeamUnits(subTeamUnits.currentSubTeamUnits);
            }}
            onConfirm={() => {
              dispatch(
                SubTeamUnits.updateSubTeamUnitsRequest({
                  id: _currentSubTeamUnits.id,
                  name: _currentSubTeamUnits.name,
                  idSubTeam: _currentSubTeamUnits.idSubTeam
                })
              );
              setEditableField(false);
            }}
            classes={classes}
            title={`Editar ${subTeamUnitsLabel.toLowerCase()}`}
            subTitle={"Informe abaixo os dados atualizados"}
            loading={subTeamUnits.loading}
            disableConfirmButton={
              _currentSubTeamUnits && !parseInt(_currentSubTeamUnits.status)
            }
          >
            <TextField
              label="Nome"
              id="outlined-name"
              className={classes.textField}
              value={_currentSubTeamUnits && _currentSubTeamUnits.name}
              margin="normal"
              variant="outlined"
              disabled={
                subTeamUnits.loading ||
                (_currentSubTeamUnits && !parseInt(_currentSubTeamUnits.status))
              }
              onChange={e => {
                setCurrentSubTeamUnits({
                  ..._currentSubTeamUnits,
                  name: e.target.value
                });
              }}
            />
            <TextField
              select
              label="Status"
              id="outlined-name"
              className={classes.selectType}
              value={_currentSubTeamUnits && _currentSubTeamUnits.status}
              margin="normal"
              variant="outlined"
              disabled={true}
              SelectProps={{
                MenuProps: {
                  className: classes.menu
                }
              }}
              onChange={e => {
                setCurrentSubTeamUnits({
                  ..._currentSubTeamUnits,
                  status: e.target.value
                });
              }}
            >
              {["1", "0"].map(item => (
                <MenuItem key={item} value={item}>
                  {parseInt(item) ? "Ativado" : "Desativado"}
                </MenuItem>
              ))}
            </TextField>
            {_currentSubTeamUnits && (
              <>
                <Button
                  variant="contained"
                  onClick={() => {
                    setEditableField(false);
                    setMdalVisible(true);
                  }}
                  disabled={subTeamUnits.loading}
                  className={classes.button}
                >
                  {parseInt(_currentSubTeamUnits.status) ? (
                    <DeleteForever className={classes.leftIcon} />
                  ) : (
                    <Autorenew className={classes.leftIcon} />
                  )}
                  {parseInt(_currentSubTeamUnits.status)
                    ? "Desativar"
                    : "Ativar"}
                </Button>
              </>
            )}
          </ModalEdit>
          {_currentSubTeamUnits && (
            <AlertDialog
              open={modalVisible}
              className={classes.button}
              title="Atenção"
              cancelButtonText="Cancelar"
              confirmButtonText="Sim"
              message={`Deseja realmente ${
                parseInt(_currentSubTeamUnits.status) ? "desativar" : "ativar"
              } ${subTeamUnitsLabel.toLowerCase()} com nome: ${
                _currentSubTeamUnits.name
              } ?`}
              onConfirm={() => {
                dispatch(
                  SubTeamUnits.changeStatusSubTeamUnitsRequest(
                    id,
                    parseInt(_currentSubTeamUnits.status) === 1 ? 0 : 1
                  )
                );
              }}
              disabled={false}
              handleClose={() => setMdalVisible(false)}
            />
          )}

          <Grid container spacing={2}>
            <Grid item xs={12} md={3} className="form-colaboradore-sm">
              <TextField
                id="outlined-name"
                className={classes.textField}
                value={
                  subTeamUnits.currentSubTeamUnits &&
                  subTeamUnits.currentSubTeamUnits.name
                }
                margin="normal"
                variant="outlined"
                disabled={true}
                label="Nome"
              />
            </Grid>
            <Grid item xs={12} md={3} className="form-colaboradore-sm">
              <TextField
                id="outlined-name"
                className={classes.textField}
                label="Status"
                value={
                  subTeamUnits.currentSubTeamUnits &&
                  parseInt(subTeamUnits.currentSubTeamUnits.status)
                    ? "Ativo"
                    : "Desativado"
                }
                margin="normal"
                variant="outlined"
                disabled={true}
              />
            </Grid>
          </Grid>

          <SubTeamSubUnitsTable
            history={history}
            dashboardParams={dashboardParams}
          />
        </>
      )}
    </Container>
  );
}

export default withStyles(materialStyle)(SubTeamUnitsEdit);
