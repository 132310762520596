import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useSelector, useDispatch } from "react-redux";
import { Actions } from "../../../../store/ducks/subTeamUnits";
import LinearProgess from "../../../../components/LinearProgess";
import { constants } from "../../../../config";

export default function FormDialog({ handleClose, open }) {
  const [name, setName] = useState();
  const subTeamUnits = useSelector(state => state.subTeamUnits);
  const subTeams = useSelector(state => state.subTeams);
  const dispatch = useDispatch();
  const [dashboardParams, setDashboardParams] = useState(null);

  useEffect(() => {
    const storedParameters = JSON.parse(localStorage.getItem("auth"))
      .currentTeam.parameters;

    const data = { parameters: storedParameters };

    if (
      storedParameters.dashboardParams &&
      storedParameters.dashboardParams.length
    ) {
      Object.assign(data, {
        dashboardParams: JSON.parse(storedParameters.dashboardParams)
      });
    }

    setDashboardParams(data.dashboardParams);
  }, []);

  return (
    <div>
      <Dialog
        open={subTeamUnits.dialogCreateVisible}
        onClose={() => subTeamUnits.setDialogCreateVisible(false)}
        aria-labelledby="form-dialog-title"
      >
        {subTeamUnits.loading && <LinearProgess />}
        <DialogTitle id="form-dialog-title">
          Criar um{" "}
          {dashboardParams &&
          Object.keys(dashboardParams).includes("subTeamUnitsLabel")
            ? dashboardParams.subTeamUnitsLabel
            : constants.deliveryTableHeaders.subTeamUnitsCondominium}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Informe abaixo as informações para criar apenas um{" "}
            {dashboardParams &&
            Object.keys(dashboardParams).includes("subTeamUnitsLabel")
              ? dashboardParams.subTeamUnitsLabel
              : constants.deliveryTableHeaders.subTeamUnitsCondominium}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="block_name"
            label={`Nome ou identificador do ${
              dashboardParams &&
              Object.keys(dashboardParams).includes("subTeamUnitsLabel")
                ? dashboardParams.subTeamUnitsLabel
                : constants.deliveryTableHeaders.subTeamUnitsCondominium
            }`}
            fullWidth
            onChange={e => setName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => dispatch(Actions.setDialogCreateVisible(false))}
            color="danger"
            disabled={subTeamUnits.loading}
          >
            Cancelar
          </Button>
          <Button
            onClick={() => {
              if (!name) return;

              dispatch(
                Actions.createSubTeamUnitsRequest(
                  [
                    {
                      name,
                      idSubTeam: subTeams.currentSubTeam.id,
                      idTeam: subTeams.currentSubTeam.idTeam
                    }
                  ],
                  true
                )
              );
            }}
            color="primary"
            disabled={subTeamUnits.loading}
          >
            Criar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
