import React, { Component } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import TableHead from "@material-ui/core/TableHead";
import queryString from "query-string";

// Redux
import { connect } from "react-redux";

// services
import formatDate from "../../functions/formatDate";

const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5)
  }
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  function handleFirstPageButtonClick(event) {
    onChangePage(event, 0);
  }

  function handleBackButtonClick(event) {
    onChangePage(event, page - 1);
  }

  function handleNextButtonClick(event) {
    onChangePage(event, page + 1);
  }

  function handleLastPageButtonClick(event) {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  }

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
};

class CustomerLogsTable extends Component {
  state = {
    emptyRows: 0,
    page: 1,
    rowsPerPage: 10,
    parameters: null
  };

  static defaultProps = {
    noLocker: false
  };

  componentWillMount() {
    const parameters = JSON.parse(localStorage.getItem("auth")).currentTeam
      .parameters;

    this.setState({
      parameters
    });
  }

  handleChangePage = (event, newPage) => {
    const { customerLogsRequest, customerLogs } = this.props;

    const parsed = queryString.parse(customerLogs.queryString);
    Object.assign(parsed, {
      page: newPage + 1
    });

    customerLogsRequest(queryString.stringify(parsed));
  };

  handleChangeRowsPerPage = event => {
    const rowsPerPage = parseInt(event.target.value);
    this.setState({ rowsPerPage });
    const { customerLogsRequest, customerLogs } = this.props;

    const parsed = queryString.parse(customerLogs.queryString);
    Object.assign(parsed, {
      perPage: rowsPerPage
    });

    customerLogsRequest(queryString.stringify(parsed));
  };

  useStyles2 = () =>
    makeStyles(theme => ({
      root: {
        width: "100%",
        marginTop: theme.spacing(3)
      },
      table: {
        minWidth: 500
      },
      tableWrapper: {
        overflowX: "auto"
      }
    }));

  checkIfExistsSubTeam = () => {
    const { delivery } = this.props;

    if (!delivery || !delivery.data || !delivery.data.length) return false;

    for (let i = 0; i < delivery.data.length; i++) {
      if (delivery.data[i].subTeam) return true;
    }

    return false;
  };

  render() {
    const classes = this.useStyles2();
    const { deliveryEmails } = this.props;

    const { emptyRows } = this.state;

    return (
      <Paper className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table className={classes.table}>
            <TableHead className="tableheader">
              <TableRow align="center">
                <TableCell className="tablecell-header" align="center">
                  usuário
                </TableCell>
                <TableCell className="tablecell-header" align="center">
                  tipo do email
                </TableCell>
                <TableCell className="tablecell-header" align="center">
                  STATUS
                </TableCell>
                <TableCell className="tablecell-header" align="center">
                  DATA
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {deliveryEmails.data &&
                deliveryEmails.data.map(row => (
                  <TableRow key={row.id}>
                    <TableCell className="tablecell" align="center">
                      {row.business ? row.business.name : "-"}
                    </TableCell>
                    <TableCell className="tablecell" align="center">
                      {row.type ? row.type.description : "-"}
                    </TableCell>
                    <TableCell className="tablecell" align="center">
                      {row.status ? "Enviado" : "Não enviado"}
                    </TableCell>

                    <TableCell className="tablecell" align="center">
                      {formatDate(row.created_at)}
                    </TableCell>
                  </TableRow>
                ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 48 * emptyRows }}>
                  <TableCell className="tablecell" colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          {/* <TablePagination
            count={deliveryEmails.total}
            rowsPerPage={deliveryEmails.perPage}
            page={deliveryEmails.page - 1}
            SelectProps={{
              inputProps: { "aria-label": "rows per page" },
              native: true
            }}
            labelRowsPerPage="Linhas por página"
            // onChangePage={this.handleChangePage}
            // onChangeRowsPerPage={this.handleChangeRowsPerPage}
            // ActionsComponent={TablePaginationActions}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to === -1 ? count : to} de ${count}`
            }
            rowsPerPageOptions={defaultConfig.rowsPerPageOptions}
            component="div"
          /> */}
        </div>
      </Paper>
    );
  }
}
const mapStateToProps = state => ({
  deliveryEmails: state.deliveryEmails
});

export default connect(mapStateToProps)(CustomerLogsTable);
