import React, { Component } from "react";

// Global Components
import Container from "../../components/Container";
import Snackbar from "../../components/Snackbar";
import TextMaskCustomCPF from "../../components/TextMaskCustomCPF";
import TextMaskCustomMobile from "../../components/TextMaskCustomMobile";

// Material
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  TextField,
  IconButton,
  InputAdornment,
  MenuItem,
  Grid
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Cancel as CancelIcon, Save as SaveIcon } from "@material-ui/icons";
import { materialStyle } from "../../styles";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as BusinessActions } from "../../store/ducks/business";
import { Actions as RoleActions } from "../../store/ducks/roles";
import { Actions as TeamActions } from "../../store/ducks/teams";
import { Actions as SubTeamActions } from "../../store/ducks/subTeam";

//configs
import { constants } from "../../config";
import checkHandoverEmail from "../../services/checkHandoverEmail";
import ThemeTextFildGreen from "../../styles/ThemeTextFildGreen";
import getOnlyNumbers from "../../services/getOnlyNumbers";

class BusinessCreate extends Component {
  state = {
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
    status: "",
    type: 4,
    showPassword: false,
    showMessage: false,
    messageText: null,
    messageVariant: null,
    emailValidation: false,
    idSubTeam: "all",
    teams: [],
    roles: [],
    option: "email",
    cpf: "",
    mobile: ""
  };

  // Load Roles and teams
  componentWillMount() {
    const { getSubTeamsRequest } = this.props;
    getSubTeamsRequest("perPage=20");
    this.setState({
      teams: [`${JSON.parse(localStorage.getItem("auth")).currentTeam.id}`]
    });
  }

  // Handle Change global
  handleChange = name => event => {
    let value = event.target.value;

    // validate email
    if (name === "email") {
      if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(event.target.value)
      ) {
        this.setState({ emailValidation: true });
      } else {
        this.setState({ emailValidation: false });
      }
      value = event.target.value;
    }

    if (name === "mobile") {
      value = getOnlyNumbers(value);
    }

    this.setState({ [name]: value });
  };

  // show/hide password
  handleClickShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword
    });
  };

  handleMouseDownPassword = event => {
    event.preventDefault();
  };

  // create new team
  handleCreate = () => {
    const { createBusinessRequest } = this.props;

    const {
      email,
      name,
      password,
      password_confirmation,
      status,
      type,
      teams,
      option,
      cpf,
      idSubTeam,
      mobile
    } = this.state;

    // verifica se existe valores
    if (!name || !password || !password_confirmation || !type || !teams) {
      this.setState({ showMessage: true });
      return;
    } else {
      this.setState({ showMessage: false });
    }

    if (option === "email") {
      this.setState({ cpf: "" });
      if (!email) {
        this.setState({ showMessage: true });
      } else {
        this.setState({ showMessage: false });
        // valida email
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
          return;
        }
      }
    } else {
      this.setState({ email: "" });
      if (!cpf || cpf.length !== 11) {
        this.setState({ showMessage: true });
      } else {
        this.setState({ showMessage: true });
      }
    }

    // verifica se status é 0 ou 1
    if (["0", "1"].indexOf(status) < 0) {
      this.setState({ showMessage: true });
      return;
    } else {
      this.setState({ showMessage: false });
    }

    // verifica se senhas conferem
    if (password !== password_confirmation) {
      this.setState({
        showMessage: true,
        messageText: constants.snackbarMessages.invalidPasswordConfirmation,
        messageVariant: constants.snackbarVariants.WARNING
      });
      return;
    }

    if (mobile && mobile.length !== 11) {
      this.setState({
        showMessage: true,
        messageText: constants.snackbarMessages.invalidMobileNumber,
        messageVariant: constants.snackbarVariants.WARNING
      });
      return;
    }

    createBusinessRequest({
      email,
      name,
      mobile,
      password,
      password_confirmation,
      type,
      status,
      teams,
      option,
      idSubTeam: idSubTeam === "all" ? null : idSubTeam,
      cpf: cpf
        .replace(".", "")
        .replace("-", "")
        .replace(".", "")
    });
  };

  handleSelectValueLabel = id => {
    const { data } = this.props.teams;

    const check = data.filter(item => item.id === id);

    if (!check) return "NÃO DEFINIDO";

    return check[0].name;
  };

  checkUserSubTeam = () => {
    const auth = localStorage.getItem("auth");

    if (JSON.parse(auth).user.idSubTeam) return true;

    return false;
  };

  render() {
    const {
      classes,
      business,
      setSnackbarErrorVisible,
      subTeams,
      subTeamsEdit
    } = this.props;

    const {
      name,
      email,
      password,
      password_confirmation,
      status,
      type,
      showPassword,
      showMessage,
      emailValidation,
      option,
      cpf,
      idSubTeam,
      mobile,
      messageText,
      messageVariant
    } = this.state;

    return (
      <Container title="Criar Usuário">
        <ThemeTextFildGreen>
          <Snackbar
            open={showMessage}
            onClose={() => this.setState({ showMessage: false })}
            message={messageText || "Campos inválidos ou vazios!"}
            variant={messageVariant || constants.snackbarVariants.WARNING}
          />
          <Snackbar
            open={business.snackbarErrorVisible}
            onClose={() => setSnackbarErrorVisible(false)}
            message="Erro"
            variant="error"
          />
          <h3>Campo utilizado para fazer login</h3>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3} className="form-colaboradore-sm">
              <TextField
                id="custom-css-outlined-input"
                select
                label="Tipo"
                className={classes.textField}
                value={option}
                onChange={this.handleChange("option")}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu
                  }
                }}
                margin="normal"
                variant="outlined"
              >
                {["email", "cpf"].map(item => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <h3 style={{ marginTop: 20 }}>Informações de login</h3>
          <Grid className="first-grid" container spacing={2}>
            <Grid
              item
              xs={12}
              sm={3}
              className="form-colaboradore-sm"
              alignItems="flex-start"
            >
              <TextField
                required
                id="outlined-name"
                label="Nome"
                className={classes.textField}
                value={name}
                onChange={this.handleChange("name")}
                margin="normal"
                variant="outlined"
                disabled={business.loading}
              />
            </Grid>
            <Grid item xs={12} sm={3} className="form-colaboradore-sm">
              {option === "email" ? (
                <TextField
                  required={option === "email"}
                  error={emailValidation}
                  id="outlined-name"
                  label="Email"
                  className={classes.textField}
                  value={email}
                  onChange={this.handleChange("email")}
                  margin="normal"
                  variant="outlined"
                  disabled={business.loading}
                />
              ) : (
                <TextField
                  required={option === "cpf"}
                  error={false}
                  id="outlined-name"
                  label="CPF"
                  className={classes.textField}
                  value={cpf}
                  onChange={this.handleChange("cpf")}
                  margin="normal"
                  variant="outlined"
                  disabled={business.loading}
                  InputProps={{
                    inputComponent: TextMaskCustomCPF
                  }}
                />
              )}
            </Grid>

            <Grid
              item
              xs={12}
              sm={3}
              className="form-colaboradore-sm"
              alignItems="flex-start"
            >
              <TextField
                id="outlined-name"
                label="Contato de celular"
                className={classes.textField}
                value={mobile}
                onChange={this.handleChange("mobile")}
                margin="normal"
                variant="outlined"
                disabled={business.loading}
                InputProps={{
                  inputComponent: TextMaskCustomMobile
                }}
                error={mobile && mobile.length !== 11}
              />
            </Grid>

            <Grid item xs={12} sm={3} className="form-colaboradore-sm">
              <TextField
                required
                disabled={business.loading}
                id="outlined-adornment-password"
                className={classes.textField}
                variant="outlined"
                margin="normal"
                type={showPassword ? "text" : "password"}
                label="Password"
                value={password}
                onChange={this.handleChange("password")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" style={{ padding: 0 }}>
                      <IconButton
                        edge="end"
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowPassword}
                        onMouseDown={this.handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3} className="form-colaboradore-sm">
              <TextField
                required
                error={password !== password_confirmation}
                disabled={business.loading}
                id="outlined-adornment-password"
                className={classes.textField}
                variant="outlined"
                type={showPassword ? "text" : "password"}
                label="Confirme a senha"
                value={password_confirmation}
                onChange={this.handleChange("password_confirmation")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowPassword}
                        onMouseDown={this.handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={3}
              className="form-colaboradore-sm"
              alignItems="flex-start"
            >
              <TextField
                disabled={business.loading}
                id="custom-css-outlined-input"
                select
                label="Função no aplicativo"
                className={classes.selectType}
                value={type}
                onChange={this.handleChange("type")}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu
                  }
                }}
                margin="normal"
                variant="outlined"
              >
                {constants.appFunctions.map(item => {
                  let handoverEmail = checkHandoverEmail();

                  if (item.permissions !== "*" && !handoverEmail) return;

                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.text}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={3} className="form-colaboradore-sm">
              <TextField
                required
                disabled={business.loading}
                id="custom-css-outlined-input"
                select
                label="Status"
                className={classes.selectType}
                value={status}
                onChange={this.handleChange("status")}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu
                  }
                }}
                margin="normal"
                variant="outlined"
              >
                <MenuItem key={"1"} value={"1"}>
                  Ativo
                </MenuItem>
                <MenuItem key={"0"} value={"0"}>
                  Inativo
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={3} className="form-colaboradore-sm">
              <TextField
                disabled={business.loading}
                id="custom-css-outlined-input"
                select
                label="Estabelecimento de acesso"
                className={classes.selectType}
                value={idSubTeam}
                onChange={this.handleChange("idSubTeam")}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu
                  }
                }}
                margin="normal"
                variant="outlined"
              >
                {subTeamsEdit.map(item => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          {this.checkUserSubTeam() && (
            <>
              <h3>Loja</h3>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3} className="form-colaboradore-sm">
                  <TextField
                    id="custom-css-outlined-input"
                    select
                    label="Tipo"
                    className={classes.textField}
                    value={idSubTeam}
                    onChange={this.handleChange("idSubTeam")}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu
                      }
                    }}
                    margin="normal"
                    variant="outlined"
                  >
                    {subTeams.data.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.identifierCode} - {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </>
          )}

          <br />
          <Button
            disabled={
              business.loading ||
              !name ||
              !password ||
              !password_confirmation ||
              !type ||
              status !== ("1" || "0") ||
              (option === "email" && !email) ||
              (option === "email" && emailValidation) ||
              (option === "cpf" && !cpf)
            }
            onClick={this.handleCreate}
            variant="contained"
          >
            <SaveIcon className={classes.leftIcon} />
            Criar
          </Button>
          <Button
            variant="contained"
            onClick={() => this.props.history.push("/contributors")}
            disabled={subTeams.loading}
            className={classes.button}
          >
            <CancelIcon className={classes.leftIcon} />
            Cancelar
          </Button>
        </ThemeTextFildGreen>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  ...state,
  subTeamsEdit: [{ id: "all", name: "Todos" }, ...state.subTeams.data]
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...BusinessActions,
      ...RoleActions,
      ...TeamActions,
      ...SubTeamActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(materialStyle)(BusinessCreate));
