import React, { Component } from "react";

// Material
import {
  Checkbox,
  ListItemText,
  TextField,
  MenuItem,
  Button,
  Input,
  InputLabel,
  FormControl,
  Select,
  Chip,
  Grid
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Cancel as CancelIcon, Save as SaveIcon } from "@material-ui/icons";

// Global Components
import Container from "../../components/Container";
import Snackbar from "../../components/Snackbar";
import Spinner from "../../components/Spinner";
import TextMaskCustomCPF from "../../components/TextMaskCustomCPF";
import EditorCurrentRegisterContainer from "../../components/EditorCurrentRegisterContainer";
import ButtonEditorCurrentRegister from "../../components/ButtonEditorCurrentRegister";
import DialogChangeBusinessPassword from "../../components/DialogChangeBusinessPassword";

// Global services
import checkObject from "../../services/checkObject";

// Global Style
import { materialStyle } from "../../styles";

// Components
import TeamsTable from "./components/TeamsTable";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as BusinessActions } from "../../store/ducks/business";
import { Actions as RoleActions } from "../../store/ducks/roles";
import { Actions as TeamActions } from "../../store/ducks/teams";
import { Actions as SubTeamActions } from "../../store/ducks/subTeam";

// Roles
import featuresRoles from "./roles";
import { checkUserRoles } from "../../services/permissions";

//configs
import { constants } from "../../config";
import ThemeTextFildGreen from "../../styles/ThemeTextFildGreen";
import TextMaskCustomMobile from "../../components/TextMaskCustomMobile";
import getOnlyNumbers from "../../services/getOnlyNumbers";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

class BusinessEdit extends Component {
  state = {
    name: "",
    email: "",
    currentBusiness: null,
    notChange: true,
    roles: [],
    teams: [],
    showMessage: null,
    messageText: null,
    messageVariant: null
  };

  componentWillMount() {
    const {
      showBusinessRequest,
      getAllRolesRequest,
      teamsRequest
    } = this.props;
    const { id } = this.props.match.params;

    showBusinessRequest(id);
    getAllRolesRequest();
    teamsRequest("perPage=1000");
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.currentBusiness !== this.state.currentBusiness) {
      this.setState({
        currentBusiness: nextProps.currentBusiness
      });
    }
  }

  componentWillUnmount() {
    const { setSnackbarErrorVisible, setSnackbarVisibleBusiness } = this.props;
    setSnackbarErrorVisible(false);
    setSnackbarVisibleBusiness(false);
  }

  handleChange = key => event => {
    let value = event.target.value;

    if (key === "mobile") {
      value = getOnlyNumbers(value);
    }

    this.setState({
      currentBusiness: {
        ...this.state.currentBusiness,
        [key]: value
      },
      notChange: false
    });
  };

  handleChangeRoles = key => event => {
    this.setState({ [key]: event.target.value });
  };

  handleChangeUserTeamRoles = (id, value) => {
    const { teamJoins } = this.state.currentBusiness;
    const data = teamJoins;

    for (let i = 0; i < teamJoins.length; i++) {
      if (teamJoins[i].id === id) {
        data[i] = {
          ...data[i],
          roles: value ? value : []
        };
        this.setState({
          ...this.state,
          notChange: false,
          currentBusiness: {
            ...this.state.currentBusiness,
            teamJoins: data
          }
        });
        break;
      }
    }
  };

  handleChangeStatusTeam = (id, status) => {
    const { teamJoins } = this.state.currentBusiness;
    const data = teamJoins;

    for (let i = 0; i < teamJoins.length; i++) {
      if (teamJoins[i].id === id) {
        data[i] = {
          ...data[i],
          status
        };
        this.setState({
          ...this.state,
          notChange: false,
          currentBusiness: {
            ...this.state.currentBusiness,
            teamJoins: data
          }
        });
        break;
      }
    }
  };

  handleSave = () => {
    const { updateBusinessRequest } = this.props;
    let data;

    if (this.state.currentBusiness.teams !== this.props.currentBusiness.teams) {
      data = {
        id: this.state.currentBusiness.id,
        ...checkObject(this.state.currentBusiness, this.props.currentBusiness)
      };
    } else {
      const { teams, ...rest } = this.state.currentBusiness;
      data = rest;
    }

    if (data.mobile && data.mobile.length !== 11) {
      this.setState({
        showMessage: true,
        messageText: constants.snackbarMessages.invalidMobileNumber,
        messageVariant: constants.snackbarVariants.WARNING
      });
      return;
    }

    updateBusinessRequest(
      {
        ...data,
        idSubTeam:
          this.state.currentBusiness.idSubTeam === "all"
            ? null
            : this.state.currentBusiness.idSubTeam
      },
      {}
    );
    this.setState({ notChange: true });
  };

  handleSelectValueLabel = (id, data) => {
    const check = data.find(item => item.id === id);

    if (!check) return "?";

    return check.name;
  };

  render() {
    const {
      classes,
      setSnackbarVisibleBusiness,
      setSnackbarErrorVisible,
      teams,
      business,
      setEditableBusiness,
      subTeamsEdit
    } = this.props;
    const { showMessage, messageText, messageVariant } = this.state;
    const { loading } = this.props.business;
    const { currentBusiness } = this.state;

    return (
      <Container title="">
        <ThemeTextFildGreen>
          <Snackbar
            open={business.error}
            onClose={() => setSnackbarErrorVisible(false)}
            variant="error"
            message={
              business.error && business.error.message
                ? business.error.message
                : "Erro geral"
            }
          />
          <Snackbar
            open={business.snackbarVisible}
            onClose={() => setSnackbarVisibleBusiness(false)}
            variant="success"
            message="Atualizado"
          />
          <Snackbar
            open={showMessage}
            onClose={() => this.setState({ showMessage: false })}
            message={messageText || "Campos inválidos ou vazios!"}
            variant={messageVariant || constants.snackbarVariants.WARNING}
          />
          {loading && <Spinner />}
          {currentBusiness && (
            <>
              <h3 style={{ fontFamily: "Livvic, sans-serif" }}>
                editar cadastro
              </h3>
              <EditorCurrentRegisterContainer
                disabled={loading || business.setEditable}
                flexDirection="row-reverse"
              >
                <Grid
                  container
                  justify="flex-end"
                  alignItems="center"
                  spacing={2}
                >
                  <ButtonEditorCurrentRegister
                    label="Editar"
                    onClick={() => setEditableBusiness(true)}
                  />
                </Grid>
              </EditorCurrentRegisterContainer>

              <br />
              <Grid container spacing={2}>
                <Grid item xs={12} md={3} className="form-colaboradore-sm">
                  <TextField
                    id="outlined-name"
                    label="Nome"
                    className={classes.textField}
                    value={currentBusiness.name}
                    onChange={this.handleChange("name")}
                    margin="normal"
                    variant="outlined"
                    disabled={loading || !business.setEditable}
                  />
                </Grid>
                <Grid item xs={12} md={3} className="form-colaboradore-sm">
                  <TextField
                    id="outlined-name"
                    label="Email"
                    className={classes.textField}
                    value={currentBusiness.email}
                    margin="normal"
                    variant="outlined"
                    disabled={true}
                    defaultValue={currentBusiness.email}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  className="form-colaboradore-sm"
                  alignItems="flex-start"
                >
                  <TextField
                    id="outlined-name"
                    label="Contato de celular"
                    className={classes.textField}
                    value={currentBusiness.mobile}
                    onChange={this.handleChange("mobile")}
                    margin="normal"
                    variant="outlined"
                    disabled={loading || !business.setEditable}
                    InputProps={{
                      inputComponent: TextMaskCustomMobile
                    }}
                    error={
                      currentBusiness.mobile &&
                      currentBusiness.mobile.length !== 11
                    }
                  />
                </Grid>
                <Grid item xs={12} md={3} className="form-colaboradore-sm">
                  <TextField
                    id="outlined-name"
                    label="CPF"
                    className={classes.textField}
                    value={currentBusiness.cpf}
                    margin="normal"
                    variant="outlined"
                    disabled={true}
                    defaultValue={currentBusiness.cpf}
                    InputProps={{
                      inputComponent: TextMaskCustomCPF
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3} className="form-colaboradore-sm">
                  <TextField
                    disabled={loading || !business.setEditable}
                    id="custom-css-outlined-input"
                    select
                    label="Status"
                    className={classes.selectType}
                    value={currentBusiness.status}
                    onChange={this.handleChange("status")}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu
                      }
                    }}
                    margin="normal"
                    variant="outlined"
                  >
                    <MenuItem key={1} value={1}>
                      Ativo
                    </MenuItem>
                    <MenuItem key={0} value={0}>
                      Inativo
                    </MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} md={3} className="form-colaboradore-sm">
                  <TextField
                    disabled={loading || !business.setEditable}
                    id="custom-css-outlined-input"
                    select
                    label="Função"
                    className={classes.selectType}
                    value={currentBusiness.type}
                    onChange={this.handleChange("type")}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu
                      }
                    }}
                    margin="normal"
                    variant="outlined"
                  >
                    {constants.appFunctions.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.text}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={12} sm={3} className="form-colaboradore-sm">
                  <TextField
                    disabled={loading || !business.setEditable}
                    id="custom-css-outlined-input"
                    select
                    label="Estabelecimento"
                    className={classes.selectType}
                    value={currentBusiness.idSubTeam}
                    onChange={this.handleChange("idSubTeam")}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu
                      }
                    }}
                    margin="normal"
                    variant="outlined"
                  >
                    {subTeamsEdit.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>

              <br />
              <h5 className={classes.subTitle}>regras de uso no painel</h5>

              <TeamsTable
                onChange={(id, value) =>
                  this.handleChangeUserTeamRoles(id, value)
                }
                onChangeStatus={(id, status) =>
                  this.handleChangeStatusTeam(id, status)
                }
                isDisabled={loading || !business.setEditable}
              />
              <br />

              {checkUserRoles(featuresRoles.selectTeamFeatureRole) && (
                <>
                  <h5 className={classes.subTitle}>empresas</h5>
                  <FormControl
                    className={classes.formControl}
                    id="business-edit-sm__select"
                  >
                    <InputLabel htmlFor="select-multiple-checkbox">
                      escolha
                    </InputLabel>
                    <Select
                      multiple
                      className={classes.selectType}
                      value={currentBusiness.teams}
                      onChange={this.handleChange("teams")}
                      input={<Input id="select-multiple-checkbox" />}
                      renderValue={selected => (
                        <div className={classes.chips}>
                          {selected.map(value => (
                            <Chip
                              key={value}
                              label={this.handleSelectValueLabel(
                                value,
                                this.props.teams.data
                              )}
                              className={classes.chip}
                            />
                          ))}
                        </div>
                      )}
                      MenuProps={MenuProps}
                      disabled={loading || !business.setEditable}
                    >
                      {teams.data.length &&
                        teams.data.map(team => (
                          <MenuItem key={team.id} value={team.id}>
                            <Checkbox
                              checked={
                                currentBusiness.teams.indexOf(team.id) > -1
                              }
                            />
                            <ListItemText primary={team.name} />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </>
              )}
              <br />
              {business.setEditable && (
                <>
                  <Button
                    variant="contained"
                    onClick={this.handleSave}
                    disabled={loading}
                    className={classes.button}
                  >
                    <SaveIcon className={classes.leftIcon} />
                    Salvar
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => setEditableBusiness(false)}
                    disabled={loading}
                    className={classes.button}
                  >
                    <CancelIcon className={classes.leftIcon} />
                    Cancelar
                  </Button>
                  <DialogChangeBusinessPassword
                    classes={classes}
                    disabled={parseInt(currentBusiness.status) !== 1}
                  />
                </>
              )}
            </>
          )}
        </ThemeTextFildGreen>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  currentBusiness: state.business.currentBusiness,
  subTeamsEdit: [...state.subTeams.data, { id: "all", name: "Todos" }],
  roles: state.roles,
  teams: state.teams,
  business: state.business
});

const mapDispathToProps = dispatch =>
  bindActionCreators(
    {
      ...BusinessActions,
      ...RoleActions,
      ...TeamActions,
      ...SubTeamActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispathToProps
)(withStyles(materialStyle)(BusinessEdit));
