import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";

import { Container2 } from "./styles";
import { materialStyle } from "../../styles";

import Logo from "../../assets/Lockup_Handover.svg";

import Container from "../../components/Container";


const NotFound = ({ classes }) => (
  <Container title=" " id="erro-content-sm">
    <Container2>
      <div>
        <h1>PÁGINA NÃO<br /> ENCONTRADA</h1>
        <Link id="logo_not_found" to="/home">
          <img src={Logo} alt="logo" />
        </Link>
      </div>
        <Link
          id="button_not_found"
          style={{
            textDecoration: "none",
            marginTop: '10vw',
            marginLeft: '15%',
            height: '80px',
            width: '230px',
            backgroundColor: "#006660ff",
            // borderRadius: 10,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            fontSize: 20
          }}
          to="/"
          >
          voltar para home
        </Link>
    </Container2>
  </Container>
);

export default withStyles(materialStyle)(NotFound);
