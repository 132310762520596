import React, { useCallback, useEffect, useState } from "react";
import ButtonClick from "../../components/ButtonClick";
import { Grid, TextField, withStyles } from "@material-ui/core";
import { materialStyle } from "../../styles";
import Container from "../../components/Container";
import Spinner from "../../components/Spinner";
import ThemeTextFildGreen from "../../styles/ThemeTextFildGreen";
import api from "../../services/api";
import DeliveryUsageTable from "../../components/DeliveryUsageTable";

function deliveryUsage({ classes }) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1);

  const fetch = useCallback(
    async () => {
      try {
        setLoading(true);
        const response = await api.get(
          `/v1/adm/master/zap/delivery?year=${year}&month=${month}`
        );

        setData(response.data);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    },
    [year, month]
  );

  useEffect(() => {
    fetch();
  }, []);

  return (
    <Container title="Consumo de entregas" id="title-sm__h3">
      {loading ? (
        <Spinner />
      ) : (
        <ThemeTextFildGreen>
          <h3
            style={{
              marginLeft: 5,
              fontFamily: "'Livvic', sans-serif",
              color: "#1b301b"
            }}
          >
            pesquisar
          </h3>
          <h6
            style={{
              marginLeft: 5,
              fontFamily: "'Livvic', sans-serif",
              color: "#1b301b"
            }}
          >
            utilize uma das opções abaixo para realizar a pesquisa:
          </h6>
          <Grid container spacing={2} className="first-grid">
            <Grid item xs={3} md={3}>
              <TextField
                inputProps={{ type: "number" }}
                id="outlined-field"
                label={"Ano"}
                className={classes.textField1}
                value={year}
                onChange={e => setYear(e.target.value)}
                margin="normal"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={3} md={3}>
              <TextField
                inputProps={{ type: "number" }}
                id="outlined-field"
                label={"Mês"}
                className={classes.textField1}
                value={month}
                onChange={e => setMonth(e.target.value)}
                margin="normal"
                variant="outlined"
              />
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <ButtonClick onClick={() => fetch()} disabled={loading}>
              Buscar
            </ButtonClick>
          </Grid>
          <br />

          <DeliveryUsageTable data={data} date={`${month}/${year}`} />
        </ThemeTextFildGreen>
      )}
    </Container>
  );
}

export default withStyles(materialStyle)(deliveryUsage);
