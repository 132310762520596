import React, { useEffect, useState, useCallback } from "react";
import AWS from "aws-sdk";
import ReactPlayer from "react-player";
import queryString from "query-string";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as LockerActions } from "../../store/ducks/locker";

// Material
import { withStyles } from "@material-ui/core/styles";

// Global
import Container from "../../components/Container";
import Spinner from "../../components/Spinner";

// Styles
import { materialStyle } from "../../styles";
import { ContainerImage, ImageCamera } from "../camera/styles";

// assets
import CameraVisor from "../../assets/images/camera_visor.jpg";

function CameraView({ location, locker: { loading } }) {
  const [hlsUrl, setHlsUrl] = useState(null);
  const [address, setAddress] = useState(null);
  const { camera_token } = queryString.parse(location.search);
  useEffect(() => {
    const { address } = queryString.parse(location.search);
    setAddress(address);
  }, []);
  useEffect(
    () => {
      setUrl();
    },
    [hlsUrl]
  );

  const setUrl = useCallback(
    async () => {
      const streamName = camera_token;
      const options = {
        accessKeyId: process.env.REACT_APP_KINESIS_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_KINESIS_SECRET_ACCESS_KEY,
        region: process.env.REACT_APP_KINESIS_REGION
      };
      const kinesisVideo = new AWS.KinesisVideo(options);
      const kinesisVideoArchivedContent = new AWS.KinesisVideoArchivedMedia(
        options
      );
      kinesisVideo.getDataEndpoint(
        {
          StreamName: streamName,
          APIName: "GET_HLS_STREAMING_SESSION_URL"
        },
        async function(err, response) {
          if (err) return;
          kinesisVideoArchivedContent.endpoint = new AWS.Endpoint(
            response.DataEndpoint
          );
          const playbackMode = "LIVE"; // 'LIVE' or 'ON_DEMAND'
          const startTimestamp = new Date("START_TIMESTAMP"); // For ON_DEMAND only
          const endTimestamp = new Date("END_TIMESTAMP"); // For ON_DEMAND only
          const fragmentSelectorType = "SERVER_TIMESTAMP"; // 'SERVER_TIMESTAMP' or 'PRODUCER_TIMESTAMP'
          const SESSION_EXPIRATION_SECONDS = 60 * 60;
          kinesisVideoArchivedContent.getHLSStreamingSessionURL(
            {
              StreamName: streamName,
              PlaybackMode: playbackMode,
              HLSFragmentSelector: {
                FragmentSelectorType: fragmentSelectorType,
                TimestampRange:
                  playbackMode === "LIVE"
                    ? undefined
                    : {
                        StartTimestamp: startTimestamp,
                        EndTimestamp: endTimestamp
                      }
              },
              Expires: parseInt(SESSION_EXPIRATION_SECONDS)
            },
            function(err, response) {
              if (err) return;
              setHlsUrl(response.HLSStreamingSessionURL);
            }
          );
        }
      );
    },
    [hlsUrl]
  );

  return (
    <Container
      title={address ? `Visualizar câmera - ${address}` : "Visualizar câmera"}
    >
      {loading ? (
        <Spinner />
      ) : (
        <ContainerImage>
          {!hlsUrl ? (
            <ImageCamera id="camera" alt="" src={CameraVisor} />
          ) : (
            <ReactPlayer url={hlsUrl} playing={true} height="70%" width="70%" />
          )}
        </ContainerImage>
      )}
    </Container>
  );
}

const mapStateToProps = state => ({
  locker: state.locker
});

const mapDispathToProps = dispatch =>
  bindActionCreators(LockerActions, dispatch);

export default withStyles(materialStyle)(
  connect(
    mapStateToProps,
    mapDispathToProps
  )(CameraView)
);
