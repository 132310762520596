import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import LinearProgess from "../LinearProgess";

export default function ModalEdit({
  title,
  subTitle,
  open,
  loading,
  onClose,
  children,
  onConfirm,
  disableConfirmButton,
  labelConfirmButton
}) {
  return (
    <div>
      <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
        {loading && <LinearProgess />}
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{subTitle}</DialogContentText>
          {children}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="danger" disabled={loading}>
            Cancelar
          </Button>
          <Button
            onClick={onConfirm}
            color="primary"
            disabled={loading || disableConfirmButton}
          >
            {labelConfirmButton}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

ModalEdit.defaultProps = {
  labelConfirmButton: "Atualizar",
  disableConfirmButton: false
};
