import React, { Component } from "react";

// Global Components
import Container from "../../../components/Container";
import Snackbar from "../../../components/Snackbar";

// Material
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  TextField,
  MenuItem,
  Grid,
  InputAdornment,
  IconButton
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Cancel as CancelIcon, Save as SaveIcon } from "@material-ui/icons";
import { materialStyle } from "../../../styles";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as AzureActiveDirectoryActions } from "../../../store/ducks/azureActiveDirectory";

//configs
import { constants } from "../../../config";
import ThemeTextFildGreen from "../../../styles/ThemeTextFildGreen";
import Spinner from "../../../components/Spinner";

class AzureActiveDirectory extends Component {
  state = {
    data: {
      tenant_id: "",
      client_secret: "",
      client_id: "",
      is_active: 0,
      group_id: ""
    },
    tenant_id_visible: false,
    client_secret_visible: false,
    client_id_visible: false
  };

  // Load Roles and teams
  componentWillMount() {
    const { azureActiveDirectoryRequest } = this.props;
    azureActiveDirectoryRequest();
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.azureActiveDirectory.data &&
      !nextProps.azureActiveDirectory.error
    ) {
      this.setState({ ...nextProps.azureActiveDirectory });
    }
  }

  // Handle Change global
  handleChange = name => event => {
    let value = event.target.value;

    this.setState({
      data: {
        ...this.state.data,
        [name]: value
      }
    });
  };

  // show/hide password
  handleClickShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword
    });
  };

  handleMouseDownPassword = event => {
    event.preventDefault();
  };

  handleCreate = () => {
    const {
      azureActiveDirectory,
      createAzureActiveDirectoryRequest,
      updateAzureActiveDirectoryRequest
    } = this.props;

    const handle = azureActiveDirectory.data
      ? updateAzureActiveDirectoryRequest
      : createAzureActiveDirectoryRequest;

    handle(this.state.data);
  };

  render() {
    const { classes } = this.props;

    const { showMessage, messageText, messageVariant } = this.state;
    const {
      data,
      tenant_id_visible,
      client_secret_visible,
      client_id_visible
    } = this.state;
    const { azureActiveDirectory } = this.props;

    return (
      <Container title="Criar Usuário">
        <ThemeTextFildGreen>
          <Snackbar
            open={showMessage}
            onClose={() => this.setState({ showMessage: false })}
            message={messageText || "Campos inválidos ou vazios!"}
            variant={messageVariant || constants.snackbarVariants.WARNING}
          />
          {azureActiveDirectory.loading ? (
            <Spinner />
          ) : (
            <>
              <h3>Dados da integração</h3>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} className="form-colaboradore-sm">
                  <TextField
                    id="custom-css-outlined-input"
                    label="Client id"
                    className={classes.textField}
                    value={data.client_id}
                    onChange={this.handleChange("client_id")}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu
                      }
                    }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6} className="form-colaboradore-sm">
                  <TextField
                    id="custom-css-outlined-input"
                    label="Client Secret"
                    className={classes.textField}
                    value={data.client_secret}
                    onChange={this.handleChange("client_secret")}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu
                      }
                    }}
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    type={client_secret_visible ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            aria-label="toggle password visibility"
                            onClick={() =>
                              this.setState({
                                client_secret_visible: !client_secret_visible
                              })
                            }
                            onMouseDown={() =>
                              this.setState({
                                client_secret_visible: !client_secret_visible
                              })
                            }
                          >
                            {client_secret_visible ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} className="form-colaboradore-sm">
                  <TextField
                    id="custom-css-outlined-input"
                    label="Tenant id"
                    className={classes.textField}
                    value={data.tenant_id}
                    onChange={this.handleChange("tenant_id")}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu
                      }
                    }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    margin="normal"
                    variant="outlined"
                    type={tenant_id_visible ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            aria-label="toggle password visibility"
                            onClick={() =>
                              this.setState({
                                tenant_id_visible: !tenant_id_visible
                              })
                            }
                            onMouseDown={() =>
                              this.setState({
                                tenant_id_visible: !tenant_id_visible
                              })
                            }
                          >
                            {tenant_id_visible ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3} className="form-colaboradore-sm">
                  <TextField
                    id="custom-css-outlined-input"
                    select
                    label="Status de ativação"
                    className={classes.textField}
                    value={data.is_active}
                    onChange={this.handleChange("is_active")}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu
                      }
                    }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    margin="normal"
                    variant="outlined"
                  >
                    {[1, 0].map(item => (
                      <MenuItem key={item} value={item}>
                        {item ? "Ativo" : "Inativo"}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
              <h3>Dados do grupo AD</h3>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} className="form-colaboradore-sm">
                  <TextField
                    id="custom-css-outlined-input"
                    label="ID do grupo"
                    className={classes.textField}
                    value={data.group_id}
                    onChange={this.handleChange("group_id")}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu
                      }
                    }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
              </Grid>

              <Button
                disabled={azureActiveDirectory.loading}
                onClick={this.handleCreate}
                variant="contained"
              >
                <SaveIcon className={classes.leftIcon} />
                {azureActiveDirectory.data ? "Atualizar" : "Criar"}
              </Button>
              <Button
                variant="contained"
                onClick={() => this.props.history.push("/contributors")}
                disabled={azureActiveDirectory.loading}
                className={classes.button}
              >
                <CancelIcon className={classes.leftIcon} />
                Cancelar
              </Button>
            </>
          )}
        </ThemeTextFildGreen>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  azureActiveDirectory: state.azureActiveDirectory
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...AzureActiveDirectoryActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(materialStyle)(AzureActiveDirectory));
