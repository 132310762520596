import React from "react";
import XLSX from "xlsx";
import { CloudUpload } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { materialStyle } from "../../styles";

function ImportExcelFileButton({ setData, classes }) {
  function importFile(e) {
    try {
      const file = e.target.files[0];

      const reader = new FileReader();

      reader.onload = event => {
        //parse data
        const bstr = event.target.result;
        const workBook = XLSX.read(bstr, { type: "binary" });

        // get sheet
        const workSheetName = workBook.SheetNames[0];
        const workSheet = workBook.Sheets[workSheetName];

        const fileData = XLSX.utils.sheet_to_json(workSheet, { header: 1 });

        // const headers = fileData[0];

        // const heads = headers.map(head => ({
        //   title: head,
        //   field: head
        // }));
        fileData.splice(0, 1);

        setData(fileData);
      };

      reader.readAsBinaryString(file);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      <input
        id="contained-button-file"
        type="file"
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        onChange={importFile}
        style={{
          display: "none"
        }}
      />
      <label htmlFor="contained-button-file">
        <Button
          variant="contained"
          component="span"
          className={classes.button}
          endIcon={<CloudUpload />}
        >
          Carregar arquivo XLSX
        </Button>
      </label>
    </>
  );
}

ImportExcelFileButton.defaultProps = {
  setData: data => {}
};

export default withStyles(materialStyle)(ImportExcelFileButton);
