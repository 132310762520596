import React, { Component } from "react";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as BusinessActions } from "../../store/ducks/business";

// Material
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";

// Global
import Container from "../../components/Container";
import Spinner from "../../components/Spinner";
import { materialStyle } from "../../styles";
import { Button } from "@material-ui/core";
import history from "../../routes/history";

class Strong extends Component {
  render() {
    return (
      <strong
        style={{
          fontSize: 18,
          fontWeight: 550
        }}
      >
        {this.props.children}
      </strong>
    );
  }
}

class Small extends Component {
  render() {
    return (
      <small
        style={{
          fontSize: 18
        }}
      >
        {this.props.children}
      </small>
    );
  }
}

class Configuration extends Component {
  state = {
    user: {},
    team: ""
  };

  componentWillMount() {
    try {
      const { businessMeRequest } = this.props;

      businessMeRequest();

      const { user, currentTeam } = JSON.parse(localStorage.getItem("auth"));
      this.setState({
        user,
        team: currentTeam
      });
    } catch (err) {}
  }

  render() {
    const { loading, me } = this.props;

    const { team } = this.state;

    return (
      <Container title="empresa">
        {loading || !me ? (
          <Spinner />
        ) : (
          <>
            <Grid container direction="column">
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between"
                }}
              >
                {this.state.team && (
                  <strong
                    style={{
                      fontFamily: "'Livvic', sans-serif",
                      fontSize: 30
                    }}
                  >
                    {this.state.team.businessName}
                  </strong>
                )}

                {/* <Button
                  variant="contained"
                  onClick={() => history.push(`/teams/edit/${team.id}`)}
                  style={{
                    backgroundColor: "#006660",
                    fontSize: 14,
                    color: "#fff",
                    textTransform: "lowercase",
                    marginLeft: 30,
                    height: 35
                  }}
                >
                  Editar
                </Button> */}
              </div>

              <Grid item className="configuration-content">
                {this.state.team && (
                  <p
                    style={{
                      fontFamily: "'Livvic', sans-serif",
                      fontSize: 16,
                      fontWeight: 500
                    }}
                  >
                    {this.state.team.cnpj}
                  </p>
                )}
              </Grid>

              <Paper className="configuration-content__information">
                <Grid
                  container
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    padding: "5px 0"
                  }}
                >
                  <Strong>Informações da Empresa</Strong>
                </Grid>

                <Grid
                  container
                  className="configuration-content__information-div"
                >
                  <div>
                    <Strong>Endereço</Strong>
                    <Small>{`${team.streetName}, ${team.streetNumber}`}</Small>
                    <Small>{`${team.city.cityName} - ${team.state.uf}`}</Small>
                    <Small>{team.zipCode}</Small>
                  </div>

                  <div>
                    <Strong>Dados do responsável</Strong>
                    <Small>{team.contactName || "Não informado"}</Small>
                    <Small>{team.contactNumber || "Não informado"}</Small>
                    <Small>{team.contactEmail || "Não informado"}</Small>
                  </div>
                </Grid>
              </Paper>
            </Grid>
          </>
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  me: state.business.me,
  loading: state.business.loading,
  error: state.business.error
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(BusinessActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(materialStyle)(Configuration));
