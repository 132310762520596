import React, { Component } from "react";

// Material
import { TextField, Button, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as RolesActions } from "../../../../../store/ducks/roles";

// Global Components
import Container from "../../../../../components/Container";
import Snackbar from "../../../../../components/Snackbar";
import TableWithCreate from "../../../../../components/TableRolesWithCreatePermissions";

// Global Styles
import { materialStyle } from "../../../../../styles";

// Functions
import checkObject from "../../../../../services/checkObject";
import ThemeTextFildGreen from "../../../../../styles/ThemeTextFildGreen";

class EditRole extends Component {
  state = {
    currentRole: null,
    isEmpty: false,
    isModified: true
  };

  componentWillMount() {
    const {
      showRoleRequest,
      match: {
        params: { id }
      }
    } = this.props;

    showRoleRequest(id);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.roles.currentRole !== this.state.currentRole) {
      this.setState({
        currentRole: nextProps.roles.currentRole
      });
    }
  }

  // handle change global
  handleChange = key => event => {
    this.setState({
      ...this.state,
      currentRole: {
        ...this.state.currentRole,
        [key]: event.target.value
      },
      isModified: !checkObject(
        this.state.currentRole,
        this.props.roles.currentRole
      )
    });
  };

  // handle save current role
  handleSave = () => {
    const { currentRole } = this.state;
    this.setState({ isEmpty: false });

    if (!currentRole.name || !currentRole.slug || !currentRole.description) {
      this.setState({ isEmpty: true });
    }

    if (!checkObject(currentRole, this.props.roles.currentRole)) {
      return;
    }
  };

  render() {
    const { classes } = this.props;
    const { currentRole, isEmpty } = this.state;

    return (
      <Container title="Editar regra de perfil">
        <ThemeTextFildGreen>
          <Snackbar
            open={isEmpty}
            onClose={() => this.setState({ isEmpty: false })}
            variant="warning"
            message={"Preencha todos os campos"}
          />
          {/**
           * <Snackbar
          open={roles.snackbarVisible}
          onClose={() => setSnackbarVisible(false)}
          variant={roles.snackbarType}
          message={roles.snackbarMessage}
        />
           */}
          {currentRole && (
            <Grid container spacing={2}>
              <Grid item xs={12} md={3} className="form-colaboradore-sm">
                <TextField
                  required
                  variant="outlined"
                  label="Nome da regra"
                  className={classes.textField}
                  margin="normal"
                  value={currentRole.name}
                  onChange={this.handleChange("name")}
                />
              </Grid>
              <Grid item xs={12} md={3} className="form-colaboradore-sm">
                <TextField
                  required
                  variant="outlined"
                  label="Slug para sistema"
                  className={classes.textField}
                  margin="normal"
                  value={currentRole.slug}
                  onChange={this.handleChange("slug")}
                />
              </Grid>
              <Grid item xs={12} md={4} className="form-colaboradore-sm">
                <TextField
                  required
                  variant="outlined"
                  label="Descrição"
                  className={classes.textField}
                  margin="normal"
                  value={currentRole.description}
                  onChange={this.handleChange("description")}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TableWithCreate />
              </Grid>
            </Grid>
          )}
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <Button
                variant="contained"
                onClick={this.handleSave}
                disabled={this.state.isModified}
              >
                Salvar
              </Button>
            </Grid>
          </Grid>
        </ThemeTextFildGreen>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  roles: state.roles
});

const mapDipatchToProps = dispatch =>
  bindActionCreators(RolesActions, dispatch);

export default connect(
  mapStateToProps,
  mapDipatchToProps
)(withStyles(materialStyle)(EditRole));
